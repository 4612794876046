export const buttonStyles = {
  components: {
    Button: {
      variants: {
        "no-hover": {
          _hover: {
            boxShadow: "none",
          },
        },
        "transparent-with-icon": {
          bg: "transparent",
          fontWeight: "bold",
          borderRadius: "inherit",
          cursor: "pointer",
          _hover: "none",
          _active: {
            bg: "transparent",
            transform: "none",
            borderColor: "transparent",
          },
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            boxShadow: "none",
          },
        },
        active: {
          bg: "green.400",
          _hover: {
            bg: "green.300"
          }
        },
        pause: {
          bg: "yellow.400",
          _hover: {
            bg: "yellow.300"
          }
        },
        primaryBlue: {
          bg: "blueDark",
          color: "gray.200",
          boxShadow: "0 7px 0 0 #BEE3F8",
          _hover: {
            color: "white",
            _disabled: {
              bg: "blue.300"
            }
          },
          _focus: {
            boxShadow: "0 5px 0 0 #BEE3F8",
          },
          _active: {
            transform: 'translateY(7px)',
            boxShadow: "0 5px 0 0 #BEE3F8"
          },
          _disabled: {
            _hover: "none"
          }
        },
        primaryRed: {
          bg: "red.400",
          color: "white",
          boxShadow: "0 7px 0 0 #ebabab",
          _hover: {
            bg: "red.300",
            _disabled: {
              bg: "blue.300"
            }
          },
          _disabled: {
            _hover: "none"
          }
        },
        alertPrimary: {
          bg: "gray.800",
          color: "gray.200",
          boxShadow: "0 7px 0 0 #6382D1",
          borderRadius: "10px",
          _hover: {
            color: "white"
          },
          _focus: {
            boxShadow: "0 10px 0 0 #6382D1",
          },
          _active: {
            transform: 'translateY(7px)',
            boxShadow: "0 5px 0 0 #6382D1"
          }
        }
      },
      baseStyle: {
        borderRadius: "15px",
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
