import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { REWARD_CARD_DETAIL_URL } from "@/utils/endpoints";
import { getTokenKey } from "@/utils/helpers";


const useRetrieveRewardCard = (enabled: boolean = true, id: string, extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const queryRes = useQuery({queryKey: ["rewardCard"], queryFn: async () => {
        const res = await axios(REWARD_CARD_DETAIL_URL(id), {
            withCredentials: true,
            headers: {
                "Authorization": `Token ${getTokenKey()}`
            }
        });
        return res.data;
    }, 
    enabled: enabled, 
    refetchOnWindowFocus: true,
    ...extraArgs
})

    // navigate to signin page when 401 status is recieved in status
    if (queryRes.error && queryRes.error.request.status === 401) {
        navigate("/");
    }
    return queryRes;
}

export default useRetrieveRewardCard;