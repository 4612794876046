import { Link } from "react-router-dom";
import { PhoneIcon } from "@chakra-ui/icons";
import { 
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Button, 
    Checkbox, 
    FormControl, 
    FormLabel, 
    Input, 
    InputGroup, 
    InputLeftElement, 
    Tooltip, 
    Icon, 
    Box, 
    Switch,
    Skeleton,
    Badge,
    Flex,
    Text, 
    TableContainer,
    Table,
    Tbody,
    Tr,
    Td
 } from "@chakra-ui/react";
import { FaCircleInfo, FaCircleExclamation } from "react-icons/fa6";

import { pageRoutes } from "@/routes";
import Card from "@/components/Card/Card"
import { CURRENCY_HTML_SYMBOL, DEFAULT_COUNTRY_CODE } from "@/utils/globalConstants";
import ApplyCouponSection from "./ApplyCouponSection";


const PurchaseEntry = (props) => {
    
    const {
        uiState,
        fetchProgramData,
        purchaseState,
        verifyRewardCardQuery,
        handleAmountChange,
        handlePhoneChange,
        handleVerifCodeChange,
        handleDirectCardCheckboxChange,
        handlePurchaseEntrySubmitButtonClick,
        handleOpenPreviewModal,
        handleProgramStatusOnChange,
        handlePurchaseResetOnClick,
    } = props;

    const {
        isApplyVerifCode,
        amountError,
        phoneError,
        verifCodeError,
        generalError
    } = uiState;

    const {
        phone,
        amount,
        verifCode,
        direct_reward_cards
    } = purchaseState;

    const loyaltyCheckToolTipLabel = "If this is checked then Customers will be added to you're activated loyalty program using their phone numbers";
    const directRewardCheckTooltipLabel = "If this is checked, and you've selected a reward card the Direct reward card section on this page, then customer will get that coupon directly via their phone numbers"

    const isCheckboxDisabled = () => {
        if (!uiState.isLoyaltyProgramCreated) {
            return true;
        }

        if (uiState.loyaltyProgramStatus !== 1) {
            return true;
        }

        return false;
    }
    
    const getToolTip = (label: string) => {
        return <Tooltip 
                hasArrow 
                label={label} 
                fontSize='sm'
                bg='gray.300' 
                color='black'
                placement="top" >
                <span>
                    <Icon 
                        as={FaCircleInfo}
                        fontSize="1rem"
                        color="blue.dark"
                        verticalAlign={{"base": "middle"}} />
                </span>
            </Tooltip>
    }

    const getLoyaltyAlertMessage = () => {
        if (!uiState.isLoyaltyProgramCreated) {
            return (
                <Text 
                    fontSize="sm"
                    mb="0.5rem">
                    <Icon 
                        as={FaCircleExclamation}
                        fontSize="1rem"
                        color="orange.400"
                        verticalAlign={{"base": "middle"}} />
                    <b> No Loyalty program!. </b> Create <Link to={"/admin/" + pageRoutes.rewardProgram}>here</Link> now.
                </Text>
            );
        }

        // if (uiState.loyaltyProgramStatus === 0) {
        //     return (
        //         <Text 
        //             fontSize="sm"
        //             mb="0.5rem">
        //             <Icon 
        //                 as={FaCircleExclamation}
        //                 fontSize="1rem"
        //                 color="orange.400"
        //                 verticalAlign={{"base": "middle"}} />
        //             <b> Loyalty program is paused!. </b> Activate <Link to={"/admin/" + pageRoutes.rewardProgram}>here</Link>.
        //         </Text>
        //     );
        // }

        return null;
    }

    // const getActiveOrPauseBadge = () => {
    //     if (isFetching) {
    //         return null;
    //     }
	// 	return uiState.loyaltyProgramStatus == 1 ? (
	// 		<Badge fontSize="md" colorScheme="green" mr="10px">
	// 			ACTIVE
	// 		</Badge>
	// 	) : (
	// 		<Badge fontSize="md" colorScheme="yellow" mr="10px">
	// 			PAUSED
	// 		</Badge>
	// 	);
	// };

    return (
        <Box>
            {/* Loyalty program activation toggle */}
            <Skeleton
                isLoaded={!fetchProgramData.isLoading}>
                <Card
                    p={{"base": "1rem"}}
                    mb={{"base": "1rem"}}>
                    {getLoyaltyAlertMessage()}

                    {/* Disabling the Add customer to loyalty toggle for now
                    *   TODO: Figure out if we need it in the future, currently it
                    *         to be confusing
                    */}
                    {/* <Flex
                        flexDirection={{"base": "row"}}>
                        <Switch 
                            size='md'
                            mr={{"base": "0.5rem"}}
                            isChecked={uiState.applyLoyaltyProgram}
                            isDisabled={isCheckboxDisabled()}
                            onChange={handleProgramStatusOnChange}
                            
                            />
                        <Text
                            as="b"
                            color={isCheckboxDisabled() ? "gray.300" : "gray.500"}
                            mr={{"base": "0.5rem"}}>Add customer to Loyalty program</Text>
                    </Flex> */}

                    {/* Loyalty program ON/OFF status */}
                    {uiState.isLoyaltyProgramCreated && 
                        <Flex>
                            <Text as="b" color="gray.textPrimary">Loyalty program is currently </Text>
                            <Badge
                                ms="0.5rem"
                                fontSize="md"
                                colorScheme={uiState.loyaltyProgramStatus == 1 ? "green" : "yellow"}>
                                {uiState.loyaltyProgramStatus == 1 ? "ON" : "OFF"}
                            </Badge>
                        </Flex>
                    }
                </Card>
            </Skeleton>

            <Card
                p={{"base": "1rem"}}>
                
                {/* Disabling the checkbox for now */}
                {/* <FormControl
                    mb={{"base": "1rem"}}>
                    <Checkbox 
                        size="lg"
                        onChange={handleDirectCardCheckboxChange}>
                        <Text size="md" color="gray.textPrimary">Send <b>Direct coupons</b> {getToolTip(directRewardCheckTooltipLabel)}</Text>
                    </Checkbox>
                </FormControl> */}

                <FormControl
                    mb={{"base": "1rem"}}>
                    <FormLabel htmlFor='first-name'>Phone number</FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            children={DEFAULT_COUNTRY_CODE} />
                        <Input
                            placeholder="Customer phone number"
                            type="number"
                            value={phone}
                            isInvalid={phoneError.length > 0}
                            onChange={handlePhoneChange}
                        />
                    </InputGroup>
                    {phoneError.length > 0 && 
                        <Text
                            as="small"
                            mt="0.5rem"
                            color="red.300">
                            *{phoneError}
                        </Text>}
                </FormControl>

                <FormControl
                    mb={{"base": "1rem"}}>
                    <FormLabel htmlFor='first-name'>Amount</FormLabel>
                    <InputGroup>
                        <InputLeftElement
                            children={CURRENCY_HTML_SYMBOL} />
                        <Input
                            placeholder="Purchase amount"
                            type="number"
                            value={amount}
                            isInvalid={ amountError.length > 0 }
                            onChange={handleAmountChange}
                        />
                    </InputGroup>
                    {amountError.length > 0 && 
                        <Text
                            as="small"
                            mt="0.5rem"
                            color="red.300">
                            *{amountError}
                        </Text>}
                </FormControl>
                
                <ApplyCouponSection 
                    amount={amount}
                    verifCode={verifCode}
                    verifCodeError={uiState.verifCodeError}
                    verifCodeSuccess={uiState.verifCodeSuccess}
                    verifRewardCardData={uiState.verifRewardCardData}
                    discountedAmount={uiState.discountedAmount}
                    verifyRewardCardQuery={verifyRewardCardQuery}
                    handleVerifCodeChange={handleVerifCodeChange}
                    handleOpenPreviewModal={handleOpenPreviewModal} />

                <Flex
                    flexDir="column">
                    <Button
                        variant="primaryBlue"
                        mb="0.5rem"
                        onClick={handlePurchaseEntrySubmitButtonClick}>
                        Submit
                    </Button>

                    {/* Reset purchase form text */}
                    <Text 
                        fontSize="sm" 
                        color="gray.textPrimary"
                        // textDecoration="underline"
                        cursor="pointer"
                        textAlign="right"
                        p="0.5rem"
                        onClick={handlePurchaseResetOnClick}
                        ><b>Reset</b></Text>
                </Flex>

            </Card>
        </Box>
    )
}

export default PurchaseEntry;