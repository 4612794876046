import { useState, useContext, useEffect } from "react";

import {
    Badge,
    Flex,
    Icon,
    Text,
    Spinner,
} from "@chakra-ui/react";

import useFetchCurrentCredit from "@/hooks/useFetchCurrentCredit";

import { GlobalContext } from "@/utils/contexts";

interface CreditsBalanceProps {
    creditsBalance: number
}

const CreditsBalance = (props: CreditsBalanceProps) => {
    const {refetchCredits, setRefetchCredits} = useContext(GlobalContext);
    const [credits, setCredits] = useState<number | null>();

    const query = useFetchCurrentCredit(true, {
      onSuccess: (data: any) => {
        setCredits(data.balance);
      },
    });

    useEffect(() => {
      if (refetchCredits) {
        query.refetch();
        setRefetchCredits(false);
      }
    }, [refetchCredits]);
    
    return (
        <Flex
        className="creditsbalance"
        flexDir="column"
        me="16px">
          <Flex
            className="creditsbalance_value"
            border="1px solid"
            borderColor="blue.dark"
            borderRadius="md">
              <Badge
                // variant="solid"
                me="0.5rem"
                color="white"
                backgroundColor="blue.dark">CREDITS</Badge>
              <Text color="gray.textPrimary" me="0.5rem">
                {query.isLoading ? <Spinner size="sm" color='blue.dark' /> :<Text as="b">{credits}</Text>}
              </Text>
          </Flex>
      </Flex>
    )
}

export default CreditsBalance;