import { Box, Skeleton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const SkeletonTable = () => {
  const numRows = 10;
  const numCols = 5;

  return (
    <Box padding="6" boxShadow="lg" bg="white">
      <Table>
        <Thead>
          <Tr>
            {Array.from({ length: numCols }, (_, index) => (
              <Th key={`header-${index}`}><Skeleton height="20px" /></Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from({ length: numRows }, (_, rowIndex) => (
            <Tr key={`row-${rowIndex}`}>
              {Array.from({ length: numCols }, (_, colIndex) => (
                <Td key={`cell-${rowIndex}-${colIndex}`}>
                  <Skeleton height="20px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default SkeletonTable;