import { useNavigate } from "react-router-dom";

import { 
    Flex,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    Box,
    Button,
} from "@chakra-ui/react";
import {
    FaArrowRightLong
} from "react-icons/fa6";

import ParentOverlay from "@/components/Overlays/ParentOverlay";

const AlertNoRewardCard = () => {
    const navigate = useNavigate();
	return (
		<ParentOverlay>
			<Alert
				status="warning"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				borderRadius="10px"
				w={{ base: "30vw" }}
				h={{ base: "max-content" }}
				alignSelf="center"
			>
				<AlertIcon boxSize={{ base: "40px" }} />
				<Box>
					<AlertTitle>
						No coupon card found!
					</AlertTitle>
					<AlertDescription>
						In order to setup a <b>Loyalty program</b> you need to create a Coupon first, as 
                        a loyalty program cannot exist without a coupon. Please click on the 
                        button below to create your first Coupon.
						<Flex justify={{ base: "center" }} mt="1rem" mb="1rem">
							<Button
								variant="alertPrimary"
								rightIcon={<FaArrowRightLong />}
								onClick={(e) => {
									return navigate("/admin/reward/coupons");
								}}
							>
								Create Coupon
							</Button>
						</Flex>
					</AlertDescription>
				</Box>
			</Alert>
        </ParentOverlay>
	);
};

export default AlertNoRewardCard;
