import React, { MouseEventHandler } from "react";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    Text,
  } from '@chakra-ui/react';

import { CautionIcon } from '@components/Icons/Icons';

interface ConfirmRewardCardDeleteProps {
    isOpen: boolean,
    onOpen: MouseEventHandler<Event> | null,
    onClose: MouseEventHandler<HTMLButtonElement>,
    onContinue: MouseEventHandler<HTMLButtonElement>,
}

const ConfirmRewardCardDelete = (props: ConfirmRewardCardDeleteProps) => {
    const { 
        isOpen,
        onOpen,
        onClose,
        onContinue 
    } = props;

    const cancelRef = React.useRef()
  
    return (
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent>
            <AlertDialogHeader>
                <CautionIcon h={"40px"} w={"40px"} color={"yellow.400"} /> You're trying to delete this coupon
            </AlertDialogHeader>

            <AlertDialogCloseButton />

            <AlertDialogBody>
                <Text as="b" fontSize="lg">Do you wish to continue?</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
                <Button bg="blue.dark" color="white" mr="1rem" onClick={onContinue}>
                    Yes!
                </Button>
                <Button variant="primaryRed" ref={cancelRef} onClick={onClose}>
                    Cancel
                </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    )
  }

  export default ConfirmRewardCardDelete;