const API_PREFIX = import.meta.env.VITE_BE_DOMAIN + '/api/v1/'

export const LOGIN_URL = API_PREFIX + "businesses/session_login/";
export const LOGOUT_URL = API_PREFIX + "businesses/session_logout/";
export const REGISTER_SEND_OTP_URL = API_PREFIX + "businesses/reg_send_otp/";
export const REGISTER_VERIFY_OTP_URL = API_PREFIX + "businesses/reg_verify_otp/";
export const PASSWORD_RESET_URL = API_PREFIX + "businesses/password_reset/";
export const PASSWORD_RESET_CONFIRM_URL = API_PREFIX + "businesses/password_reset_confirm/";
export const CHANGE_PASSWORD_URL = API_PREFIX + "businesses/change_password/";

export const GENERIC_SEND_OTP_URL = API_PREFIX + "base/generic_send_otp/";
export const GENERIC_VERIFY_OTP_URL = API_PREFIX + "base/generic_verify_otp/";


export const REWARD_PROGRAM_URL = API_PREFIX + 'rewards/programs/';
export const PRE_AMOUNT_UPDATE_REWARD_PROGRAM_URL = API_PREFIX + 'rewards/programs/pre_reward_program_amount_update/';

export const REWARD_CARD_URL = API_PREFIX + 'rewards/cards/';
export const REWARD_CARD_DETAIL_URL = (cardId: string) => API_PREFIX + 'rewards/cards/' + cardId + '/';

export const BUSINESS_PROFILE_URL = API_PREFIX + 'businesses/profile/';
export const BUSINESS_METRICS_URL = API_PREFIX + 'businesses/metrics/';

export const CUSTOMER_PURCHASE_ENTRY_URL = API_PREFIX + 'customers/purchase_entry/'
export const CUSTOMER_PURCHASE_ENTRY_LIST_URL = (phone: string) => API_PREFIX + 'customers/purchase_entry/' + `?phone=${encodeURIComponent(phone)}`;
export const CUSTOMER_VERIFY_REWARD_CARD_URL = (amount: number, verfication_code: string) => API_PREFIX + 'customers/purchase_entry/verify_reward_card' + `?verification_code=${verfication_code}&amount=${amount}`;
export const CUSTOMER_REWARD_URL = (id: string) => API_PREFIX + 'customers/reward/' + id;

export const CREDIT_PURCHASE_PLAN_LIST_URL = API_PREFIX + 'credits/purchase_plans/';
export const CREDIT_CURRENT_CREDIT_URL = API_PREFIX + 'credits/credit_transactions/current_credit/';
export const CREDIT_TRANSACTION_LIST_URL = (page: number) =>  API_PREFIX + 'credits/credit_transactions/?page=' + page;
export const CREDIT_CREATE_ORDER_URL = API_PREFIX + 'credits/create_order/';
export const CREDIT_ORDER_TRANSACTION_DETAIL_URL = (order_id: string, polling: string) => API_PREFIX + 'credits/order_transactions/' + order_id + '/?polling=' + polling;
