import {
    Heading,
    Text,
} from "@chakra-ui/react";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";


interface CreditPlanContactUsItemProps {
    isDisabled: boolean,
}

const CreditPlanContactUsItem = (props: CreditPlanContactUsItemProps) => {
    const {
        isDisabled,
    } = props;

    return (
        <Card
            className="creditplanitem"
            flexDirection="column"
            borderWidth="1px"
            mb="1rem">
            <CardHeader
                className="creditplanitem_header"
                justifyContent="center"
                alignSelf="center">
                <Heading 
                    size="lg"
                    fontWeight="thin"
                    textAlign="center" 
                    mb="1rem" 
                    color="gray.textPrimary">
                    Want to buy more credits?
                </Heading>
            </CardHeader>
            <CardBody
                className="creditplanitem_body"
                flexDirection="column"
                justifyContent="center"
                mt="auto">
                {/* <Button
                    className="creditplanitem_buybutton"
                    mt="auto"
                    variant="primaryBlue"
                    fontSize="lg"
                    isDisabled={isDisabled}>Contact Us</Button> */}
                <Text
                    as="small"
                    textAlign={"center"}>
                    Please email us at <a href="mailto:connect@inback.in">connect@inback.in</a> with your requirements
                </Text>
            </CardBody>
        </Card>
    );
}

export default CreditPlanContactUsItem;