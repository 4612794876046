// Chakra imports
import ParentOverlay from "@/components/Overlays/ParentOverlay";
import {
	Avatar,
	Box,
	Button,
	Flex,
	Icon,
	Input,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import React, { useRef } from "react";

import { FaFloppyDisk, FaUpload, FaCircleInfo } from "react-icons/fa6";

const Header = ({
	logo,
	logoError,
	name,
	email,
	onLogoUpload,
	handleSaveButtonOnClick
}) => {
	const parentOverlayRef = useRef();
	const logoInputRef = useRef();

	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const borderProfileColor = useColorModeValue(
		"white",
		"rgba(255, 255, 255, 0.31)"
	);
	const emailColor = useColorModeValue("gray.400", "gray.300");
	return (
		<Flex
			align="center"
			mb={{ sm: "10px", md: "0px" }}
			direction={{"base": "row"}}
			w={{ sm: "100%" }}
			justify={{"base": "space-between"}}
			textAlign={{ sm: "center", md: "start" }}
		>
			<Flex
				className="header-details-wrapper"
				flexDirection="column">
				<Flex
					className="header-details">
					
					<Flex
						className="avatar-wrapper"
						position={{"base": "relative"}}
						mr={{"base": "1rem"}}
						onMouseOver={event => {
							parentOverlayRef.current.style.display = "block";
						}}
						onMouseLeave={event => {
							parentOverlayRef.current.style.display = "none";
						}}>
						<Avatar
							className="avatar"
							src={logo}
							size="xl"
						/>
						<Box
							id="overlay-wrapper"
							display="none"
							ref={parentOverlayRef}
							cursor="pointer"
							onClick={event => {
								logoInputRef.current.click();
							}}>
							<ParentOverlay
								borderRadius="50%">
									<Icon 
										as={FaUpload}
										color="white"
										fontSize={{"base": "1.5rem"}}
										alignSelf="center"
										 />
							</ParentOverlay>
							<Input 
								ref={logoInputRef}
								id="id_pc_bannerimage_input" 
								type="file" 
								accept="image/*"
								onChange={onLogoUpload}
								hidden />
						</Box>
					</Flex>
			
					<Flex
						direction="column"
						maxWidth="100%"
						my={{ sm: "14px" }}
					>
						<Text
							fontSize={{ sm: "lg", lg: "xl" }}
							color={textColor}
							fontWeight="bold"
							ms={{ sm: "8px", md: "0px" }}
						>
							{name}
						</Text>
						<Text
							fontSize={{ sm: "sm", md: "md" }}
							color={emailColor}
							fontWeight="semibold"
						>
							{email}
						</Text>
					</Flex>
				</Flex>

				<Text
					as="small"
					mt="0.5rem"
					color="blue.dark">
					<Icon as={FaCircleInfo} /> Recommended resolution is 560px X 560px, and should be less than 1MB
				</Text>
				
				{logoError.length > 0 && 
					<Text
						as="small"
						mt="0.5rem"
						color="red.300">
						{logoError}
					</Text>}
			</Flex>
			
			<Button 
				variant="primaryBlue"
				leftIcon={<FaFloppyDisk />}
				onClick={handleSaveButtonOnClick}>Save</Button>
		</Flex>
	);
};

export default Header;
