import React from "react";
// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

import AuthNewNavbar from "@components/Navbars/AuthNewNavbar";

import { PASSWORD_RESET_TIMEOUT_DAYS } from "@/utils/globalConstants";

interface ResetPassState {
  email: string;
  emailErrMsg: string;
  pageErrMsg: string;
  isLoading: boolean;
  showSuccessMsg: boolean;
}

interface ForgotPassProps {
  resetPassState: ResetPassState;
  passwordResetMutation: any
  setResetPassState: Function;
  handleSubmit: Function;
}

function ForgotPasswordComp(props: ForgotPassProps) {
  const { resetPassState, setResetPassState, passwordResetMutation, handleSubmit } = props;
  const { email, emailErrMsg, pageErrMsg, isLoading, showSuccessMsg } =
    resetPassState;

  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position="relative" flexDirection="column">
      {/* {AuthNavbar with logo and brand} */}
      <Flex
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        p="48px"
        pt={{ sm: "100px", md: "0px" }}
        mt={{ md: "30px", lg: "30px" }}
        className="navbar-wrapper"
      >
        <AuthNewNavbar logoText="inback" />
      </Flex>

      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="center"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
        className="main-content"
      >
        {showSuccessMsg ? (
          <Flex
            direction="column"
            w={{ md: "50%", lg: "50%", sm: "100%" }}
            background="transparent"
            p="48px"
          >
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg" color="blueDark">
                Yay! Email sent successfully.
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                We've sent you a one-time link to reset your password on your registered
                email address.
              </AlertDescription>
            </Alert>
          </Flex>
        ) : (
          <Flex
            direction="column"
            w={{ md: "50%", lg: "50%", sm: "100%" }}
            background="transparent"
            p="48px"
          >
            <Heading color="blueDark" fontSize="32px" mb="10px">
              Forgot password?
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your registered email and we will send you an email with
              password reset link
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                id="email_id"
                borderRadius="15px"
                mb={emailErrMsg ? "0px" : "24px"}
                fontSize="sm"
                type="text"
                placeholder="Your email adress"
                size="lg"
                errorBorderColor="red.300"
                isInvalid={emailErrMsg !== ""}
                value={email}
                isDisabled={passwordResetMutation.isLoading}
                onChange={(e) =>
                  setResetPassState({
                    ...resetPassState,
                    email: e.target.value,
                  })
                }
              />
              {emailErrMsg && (
                <Text mb="24px" fontSize="sm" textColor="red.300">
                  *{emailErrMsg}
                </Text>
              )}

              <Button
                fontSize="12px"
                type="submit"
                bg="blueDark" //'teal.300'
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                isLoading={passwordResetMutation.isLoading}
                onClick={(e) => handleSubmit()}
              >
                SIGN IN
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="between"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                No need to reset password?
                <Link
                  as={RouterLink}
                  color="blueDark"
                  ms="5px"
                  fontWeight="bold"
                  to="/auth/signin"
                >
                  Sign In
                </Link>
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default ForgotPasswordComp;
