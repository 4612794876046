import React, { useState } from "react";

import { createPromise } from "@/utils/helpers";
import PurchaseWarningDialog from "@/components/PurchaseWarningDialog/PurchaseWarningDialog";


interface SelectedCards {
    id: string,
    name: string,
}

const usePurchaseConfirm = (loyaltyProgramStatus: number, 
                            isLoyaltyProgramCreated: boolean,
                            verifCode: string,
                            selectedCards: SelectedCards[]) => {
    
    const [open, setOpen] = useState(false);
    const [ resolver, setResolver ] = useState({ resolver: null });

    const getConfirmation = async () => {
        setOpen(true);
        const [promise, resolver] = await createPromise();
        setResolver({resolver});
        return promise;
    }

    const onClick = async (status: boolean) => {
        setOpen(false);
        resolver.resolver(status);
    }

    const ConfirmationDialog = () => {
        return <PurchaseWarningDialog 
                    isOpen={open}
                    loyaltyProgramStatus={loyaltyProgramStatus}
                    isLoyaltyProgramCreated={isLoyaltyProgramCreated}
                    selectedCards={selectedCards}
                    verifCode={verifCode}
                    onContinue={event => onClick(true)}
                    onClose={event => onClick(false)}
                    onOpen={null} />
    }

    return [getConfirmation, ConfirmationDialog];
}

export default usePurchaseConfirm;
