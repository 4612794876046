import {
    Flex,
    FormLabel,
    Input,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent
} from "@chakra-ui/react";
import { ChromePicker } from 'react-color';

import Card from "../Card/Card";

interface ColorInputProps {
    label: string,
    color: string,
    onChangeColor: Function
}

const ColorInput = (props: ColorInputProps) => {
    const {
        label="Example",
        color="blue",
        onChangeColor
    } = props;

    return (
        <Card 
            h={{"base": "fit-content"}}>
            <Flex
                flexDirection="row"
                justify="space-between">
                <FormLabel htmlFor='email'>{label}</FormLabel>
                <Popover>
                    <PopoverTrigger>
                        <Flex
                            w="2rem"
                            h="2rem"
                            borderRadius={{"base": "50%"}}
                            border="2px solid white"
                            bg={color}
                            cursor="pointer"
                            boxShadow="rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">
                        </Flex>
                    </PopoverTrigger>
                    <PopoverContent
                        w={{"base": "fit-content"}}>
                        <ChromePicker
                            color={color}
                            onChangeComplete={onChangeColor} />
                    </PopoverContent>
                </Popover>
            </Flex>
        </Card>
    );
}

export default ColorInput;