import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
	colors: {
		gray: {
			700: '#1f2733',
			textPrimary: "#718096",
			navActive: '#545151',
		},
		blue: {
			dark: '#6382D1',
		},
		bgPinkLight: '#C2BABA',
		blueDark: '#6382D1',
	},
	styles: {
		global: (props) => ({
			body: {
				bg: mode('gray.50', 'gray.800')(props),
				fontFamily: "'Roboto', sans-serif"
			},
			html: {
				fontFamily: "'Roboto', sans-serif"
			}
		})
	}
};
