import React, { useState } from "react";
import { createPromise } from "@/utils/helpers";
import ConfirmRewardCardDelete from "../components/ConfirmRewardCardDelete";


interface ResolverPromise {
    resolver: Promise<unknown> | undefined
}

const useConfirmRewardCardDelete = () => {
    
    const [open, setOpen] = useState(false);
    const [ resolver, setResolver ] = useState({ resolver: undefined });

    const getConfirmation = async () => {
        setOpen(true);
        const [promise, resolver] = await createPromise();
        setResolver({resolver: resolver});
        return promise;
    }

    const onClick = async (status: boolean) => {
        setOpen(false);
        resolver.resolver(status);
    }

    const ConfirmationDialog = () => {
        return <ConfirmRewardCardDelete
                    isOpen={open}
                    onContinue={event => onClick(true)}
                    onClose={event => onClick(false)}
                    onOpen={null} />
    }

    return [getConfirmation, ConfirmationDialog];
}

export default useConfirmRewardCardDelete;
