import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Icon,
  Button,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { FaEye, FaEyeSlash } from "react-icons/fa6";

import useChangePasswordMutation from '@/hooks/mutations/useChangePasswordMutation';

interface ChangePasswordModalProps {
    isOpen: boolean;
    onClose: () => void;
  }

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  
  const toast = useToast();

  const { mutate, isLoading } = useChangePasswordMutation({
    onSuccess: () => {
        toast({ 
            title: 'Password changed successfully', 
            position: 'top-right',
            status: 'success',
            isClosable: true,
        });
      onClose();
    },
    onError: (error) => {
        const response = error.response;
        if (response) {
            if ("current_password" in response.data) {
                setCurrentPasswordError(response.data.current_password);
            }
            if ("new_password" in response.data) {
                setNewPasswordError(response.data.new_password);
            }
            if ("confirm_password" in response.data) {
                setConfirmPasswordError(response.data.confirm_password);
            }
            if ("password" in response.data) {
                setNewPasswordError(response.data.password);
            }
            if ("detail" in response.data) {
                toast({
                    title: response.data.detail,
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } 
        if (response.status === 500) {
            toast({
                title: 'Internal server error occurred while changing password',
                description: error.message,
                position: 'top-right',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    },
  });

  const getPassIcon = (input: string) => {
    if (input === 'currentPassword') {
      return showCurrentPassword ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />;
    }
    if (input === 'newPassword') {
      return showNewPassword ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />;
    }
    return showConfirmPassword ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />;
  }

  const getAlertMessage = () => {

  }

  const setShowPasswordByInput = (input: string) => {
    if (input === 'currentPassword') {
      setShowCurrentPassword(!showCurrentPassword);
      setShowNewPassword(false);
      setShowConfirmPassword(false);
    }
    if (input === 'newPassword') {
        setShowCurrentPassword(false);
        setShowNewPassword(!showNewPassword);
        setShowConfirmPassword(false);
    }
    if (input === 'confirmPassword') {
        setShowCurrentPassword(false);
        setShowNewPassword(false);
        setShowConfirmPassword(!showConfirmPassword);
    }
  }

  const resetState = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setShowConfirmPassword(false);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setError('');
    setSuccess('');
  }

  const hasErrors = () => {
    return (
      currentPasswordError !== '' ||
      newPasswordError !== '' ||
      confirmPasswordError !== ''
    );
  }

  const isInputValid = () => {
    let isValid: boolean = true;

    if (!currentPassword) {
        isValid = false;
        setCurrentPasswordError('*This field is required');
    }
    if (!newPassword) {
        isValid = false;
        setNewPasswordError('*This field is required');
    }
    if (!confirmPassword) {
        isValid = false;
        setConfirmPasswordError('*This field is required');
    }

    if (newPassword === currentPassword) {
        isValid = false;
        setNewPasswordError('New password must be different from current password');
    } else {    
        setNewPasswordError('');
    }
    
    if (newPassword !== confirmPassword) {
        isValid = false;
        setConfirmPasswordError('Passwords do not match');
    } else {
        setConfirmPasswordError('');
    }

    if (hasErrors()) {
        isValid = false;
    }

    return isValid;
  }

  const closeModal = () => {
    resetState();
    onClose();
  }

  const handleSubmit = () => {
    if (isInputValid()) {
      mutate({
        "current_password": currentPassword,
        "new_password": newPassword,
        "confirm_password": confirmPassword
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Password</ModalHeader>
        <ModalBody>
            <FormControl mb="1rem">
                <FormLabel>Current Password</FormLabel>
                <InputGroup>
                    <Input
                        id="current_password_id"
                        // borderRadius='15px'
                        fontSize='sm'
                        type={showCurrentPassword ? "text" : "password"}
                        placeholder='Current Password'
                        size='md'
                        errorBorderColor='red.300'
                        isInvalid={currentPasswordError?.length > 0}
                        value={currentPassword}
                        onChange={e => {
                            setCurrentPassword(e.target.value);
                            setCurrentPasswordError('');
                        }}
                    />
                    <InputRightAddon 
                        onClick={event => setShowPasswordByInput("currentPassword")}>
                        { getPassIcon("currentPassword") }
                    </InputRightAddon>
                </InputGroup>
                {currentPasswordError.length > 0 && 
                    <Text color="red.300" as="small">{currentPasswordError}</Text>}
            </FormControl>

            <FormControl mb="1rem">
                <FormLabel>New Password</FormLabel>
                <InputGroup>
                    <Input
                        id="new_password_id"
                        // borderRadius='15px'
                        fontSize='sm'
                        type={showNewPassword ? "text" : "password"}
                        placeholder='New Password'
                        size='md'
                        errorBorderColor='red.300'
                        isInvalid={newPasswordError?.length > 0}
                        value={newPassword}
                        onChange={e => {
                            setNewPassword(e.target.value);
                            setNewPasswordError('');
                        }}
                    />
                    <InputRightAddon 
                        onClick={event => setShowPasswordByInput("newPassword")}>
                        { getPassIcon("newPassword") }
                    </InputRightAddon>
                </InputGroup>
                {newPasswordError.length > 0 && 
                    <Text color="red.300" as="small">{newPasswordError}</Text>}
            </FormControl>
            
            <FormControl mb="1rem">
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                    <Input
                        id="confirm_password_id"
                        // borderRadius='15px'
                        fontSize='sm'
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder='Confirm Password'
                        size='md'
                        errorBorderColor='red.300'
                        isInvalid={confirmPasswordError?.length > 0}
                        value={confirmPassword}
                        onChange={e => {
                            setConfirmPassword(e.target.value);
                            setConfirmPasswordError('');
                        }}
                    />
                    <InputRightAddon 
                        onClick={event => setShowPasswordByInput("confirmPassword")}>
                        { getPassIcon("confirmPassword") }
                    </InputRightAddon>
                </InputGroup>
                {confirmPasswordError.length > 0 && 
                    <Text color="red.300" as="small">{confirmPasswordError}</Text>}
            </FormControl>

            <Flex>
                <Button 
                    flex={1}
                    mr={"0.5rem"}
                    variant={"primaryBlue"} 
                    onClick={handleSubmit} 
                    isLoading={isLoading} 
                    disabled={isLoading}>
                    Change Password
                </Button>

                <Button flex={1} variant={"primaryRed"} onClick={() => closeModal()}>
                    Cancel
                </Button>
            </Flex>
        </ModalBody>

        <ModalFooter>
          {/* <Button variant={"primaryBlue"} onClick={handleSubmit} isLoading={isLoading} disabled={isLoading}>
            Change Password
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;