import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { REWARD_CARD_URL } from "@/utils/endpoints";
import { getTokenKey } from "@/utils/helpers";


const useFetchRewardCards = (enabled: boolean = true, extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const queryRes = useQuery({
        queryKey: ["rewardCards"], 
        queryFn: async () => {
            let url = REWARD_CARD_URL;
            if ("queryParam" in extraArgs) {
                url = url + "?" + extraArgs.queryParam;
            }
            console.log("extraArgs", extraArgs)
            const res = await axios(url, {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`
                }
            });
            return res.data;
        }, 
        enabled: enabled, 
        refetchOnWindowFocus: false,
        ...extraArgs
    });

    // navigate to signin page when 401 status is recieved in status
    if (queryRes.error && queryRes.error.request.status === 401) {
        navigate("/");
    }
    return queryRes;
}

export default useFetchRewardCards;