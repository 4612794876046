export const typographyStyles = {
    components: {
        Heading: {
            variants: {
                "pageHeader": {
                    fontSize: "2xl",
                }
            }
        }
    }
}