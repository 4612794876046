import React, { useState, useRef } from "react";

import {
    Avatar,
    Box,
    Flex,
    Icon,
    Input,
} from "@chakra-ui/react";

import { FaUpload } from "react-icons/fa6";

import ParentOverlay from "@/components/Overlays/ParentOverlay";

interface BusinessLogoWrapperProps {
    businessName: string,
    businessLogo: string,
    businessLogoError: string,
    onBusinessLogoChange: React.ChangeEventHandler,
}

const BusinessLogoWrapper = (props: BusinessLogoWrapperProps) => {

    const {
        businessName,
        businessLogo,
        businessLogoError,
        onBusinessLogoChange
    } = props;

    const [isHovering, setIsHovering] = useState<boolean>(false);
    const logoInputRef = useRef();

    return (
        <Flex
            className="businesslogo-wrapper"
            position="relative"
            flexDir="column"
            justifyContent="center"
            width="fit-content"
            onMouseOver={event => setIsHovering(true)}
            onMouseLeave={event => setIsHovering(false)} >
            <Avatar size='xl' name={businessName} src={businessLogo} />{' '}

            {isHovering &&
                <Box
                id="logo-overlay-wrapper"
                // display="none"
                // ref={parentOverlayRef}
                cursor="pointer"
                onClick={event => {
                    logoInputRef.current.click();
                }}>
                    <ParentOverlay
                    borderRadius="50%">
                        <Icon 
                        as={FaUpload}
                        color="white"
                        fontSize={{"base": "1.5rem"}}
                        alignSelf="center"
                        />
                    </ParentOverlay>
                </Box>
            }
            <Input 
                ref={logoInputRef}
                id="id_businesslogo_input" 
                type="file" 
                accept="image/*"
                onChange={onBusinessLogoChange}
                hidden />
        </Flex>
    )
}

export default BusinessLogoWrapper;