import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from "axios";
import {
    ChakraProvider,
    useToast
} from '@chakra-ui/react';

import { LOGIN_URL } from '@/utils/endpoints';
import { updateBusinsess } from '@/views/business/business.slice';
import { populateRewardCards } from '@/views/rewards/rewardProgram/rewardProgram.slice';
import { isTokenValid } from '@/utils/helpers';
import { adminRoutePrefix, pageRoutes } from '@/routes';

import SignInComp from './SignInComp';

import theme from "@/theme/theme";
import { sign } from 'crypto';


const SignIn: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const toast = useToast()

    const [signInState, setSignInState] = useState({
        email: '',
        emailErrMsg: '',
        password: '',
        passwordErrMsg: '',
        rememberMe: false,
        generalErrorMsg: "",
        isFormSubmitting: false,
    });

    // Effect to check if token is expired or not
	useEffect(() => {
		if (isTokenValid()) {
			// redirect to dashboard page
			navigate(adminRoutePrefix + pageRoutes.dashboard);
		}
	}, []);

    const isFormValid = () => {
        let valid = true;
        if (signInState.email.length == 0) {
            valid = false;
            setSignInState({...signInState, emailErrMsg: "This field may not be blank"});
        }
        if (signInState.password.length == 0) {
            valid = false;
            setSignInState({...signInState, passwordErrMsg: "This field may not be blank"});
        }
        return valid;
    }

    const handleSubmit = () => {
        if (!isFormValid()) {
            return;
        }

        setSignInState({...signInState, generalErrorMsg: "", isFormSubmitting: true});
        axios.post(LOGIN_URL, {
            email: signInState.email,
            password: signInState.password
        },{
            withCredentials: true,
        }).then(response => {
            setSignInState({...signInState, isFormSubmitting: false});
            const business = response.data["business"];
            const rewardCards = response.data["reward_cards"];
            // save business details in local storage
            localStorage.setItem("business", JSON.stringify(business));

            // save reward cards in local storage
            localStorage.setItem("rewardCards", JSON.stringify(rewardCards));

            // save authentication token and expiration time in localStorage
            const token = response.data["token"];
            const token_expiration_datetime = response.data["token_expiration_datetime"];
            localStorage.setItem("inback_api_token", JSON.stringify({"token": token, "token_expiration_datetime": token_expiration_datetime}))

            dispatch(updateBusinsess(business));
            dispatch(populateRewardCards(rewardCards));

            // redirect to next if in url
            let next = searchParams.get("next");
            if (next && next != "") {
                navigate(next);
            }

            // if not next then redirect to rewardProgram page
            navigate("/admin/reward/");

        }).catch(error => {
            setSignInState({...signInState, isFormSubmitting: false});
            let errorRes = error.response;
            switch(errorRes.status) {
                case 400:
                    let emailErrorStr = "";
                    let passErrorStr = "";
                    if (Object.keys(errorRes.data).includes("email")) {
                        errorRes.data["email"].forEach(error => emailErrorStr += error + " ");
                    }
                    if (Object.keys(errorRes.data).includes("password")) {
                        errorRes.data["password"].forEach(error => passErrorStr += error + " ");
                    }

                    setSignInState({...signInState, passwordErrMsg: passErrorStr, emailErrMsg: emailErrorStr});
                    if ("detail" in errorRes.data) {
                        // setSignInState({...signInState, generalErrorMsg: errorRes.data.detail});
                        toast({
                            title: 'Something went wrong',
                            description: errorRes.data.detail,
                            position: 'top-right',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        });
                    }
                    break;
                case 500:
                    setSignInState({...signInState, generalErrorMsg: "Internal server error"});
                    break;
            }
        })
    }

    return (
        <ChakraProvider theme={theme}>
            <SignInComp 
                signInState={signInState}
                setSignInState={setSignInState}
                handleSubmit={handleSubmit} />
        </ChakraProvider>
    );
}

export default SignIn;