import React, { ChangeEvent, useEffect, useRef } from "react";
// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "@assets/img/signInImage.png";

import { Link as RouterLink } from "react-router-dom";

import AuthNewNavbar
 from "@components/Navbars/AuthNewNavbar";
import { pageRoutes } from "@/routes";

interface SignInStateType {
  email: string,
  emailErrMsg: string,
  password: string,
  passwordErrMsg: string,
  rememberMe: boolean,
  isFormSubmitting: boolean,
  generalErrorMsg: string,
}

interface SignInProps {
  signInState: SignInStateType,
  setSignInState: Function,
  handleSubmit: Function
}

function SignInComp(props: SignInProps) {
  const { signInState, setSignInState, handleSubmit } = props;
  const { 
    email, 
    emailErrMsg, 
    password, 
    passwordErrMsg, 
    rememberMe,
    isFormSubmitting,
    generalErrorMsg
  } = signInState;

  const submitButtonRef = useRef();

  useEffect(() => {
    function handleEnter(event: KeyboardEvent) {
      if (event.key == "Enter") {
        submitButtonRef.current.click();
      }
    }

    document.addEventListener("keyup", handleEnter)

    return () => {
      return document.removeEventListener("keyup", handleEnter)
    }
  }, []);

  const isSignInButtonDisabled = () => {
    return isFormSubmitting;
  }

  const isEmailFieldDisabled = () => {
    return isFormSubmitting;
  }

  const isPasswordFieldDisabled = () => {
    return isFormSubmitting;
  }

  const handleEmailInputChange = (e: ChangeEvent) => {
    let errMsg = "";
    let value = e.target.value;
    if (value.length == 0) {
      errMsg = "This field may not be blank";
    }
    setSignInState({
      ...signInState, 
      email: value, 
      emailErrMsg: errMsg,
      generalErrorMsg: "",
    });
  }

  const handlePasswordInputChange = (e: ChangeEvent) => {
    let errMsg = "";
    let value = e.target.value;
    if (value.length == 0) {
      errMsg = "This field may not be blank";
    }
    setSignInState({
      ...signInState, 
      password: value, 
      passwordErrMsg: errMsg,
      generalErrorMsg: "",
    });
  }
  
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position='relative' flexDirection='column'>

      {/* {AuthNavbar with logo and brand} */}
      <Flex 
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        p={{ base: "48px", lg: "48px", sm: "20px", md: "20px" }}
        pt={{ base: "100px", lg: "0px", sm: "20px", md: "0px" }}
        mt={{md: "30px", lg: "30px"}}
        className="navbar-wrapper">
          <AuthNewNavbar logoText="inback" />
      </Flex>

      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}
        className="main-content">
        
        <Flex
          direction='column'
          w='100%'
          background='transparent'
          p='48px'
          pt={{md: "120px"}}
          // mt={{ md: "150px", lg: "80px" }}
          display={{ base: "none", md: "block" }}>
            <Heading color="blueDark">Simple.</Heading>
            <Heading color="blueDark">Easy to use.</Heading>
            <Box mt="8">
              <Text fontSize='2xl'>
                Run reward program & share Reward card with your customers and delight them with 
                <Text as="span" color="blueDark"> inback</Text>
              </Text>
            </Box>
        </Flex>
        
        <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'>
            {generalErrorMsg.length > 0 && 
              <Alert 
                status='error' 
                bg="red.300"
                variant='solid'
                p={{"base": "0.5rem"}}
                fontSize={{"base": "sm"}}
                borderRadius={{"base": "0.5rem"}}>
                <AlertIcon />
                {generalErrorMsg}
              </Alert>}

            <Heading color="blueDark" fontSize='32px' mb='10px'>
              Sign In
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Enter your email and password to sign in
            </Text>

            {/* {generalErrorMsg.length > 0 && 
            <Alert 
              status='error' 
              bg="red.300"
              variant='solid'
              p={{"base": "1.5rem"}}
              fontSize={{"base": "sm"}}
              borderRadius={{"base": "0.5rem"}}>
              <AlertIcon />
              {generalErrorMsg}
            </Alert>} */}

            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Email
              </FormLabel>
              <Input
                id="email_id"
                tabIndex={1}
                borderRadius='15px'
                mb={emailErrMsg ? '0px' : '24px'}
                fontSize='sm'
                type='text'
                placeholder='Your email adress'
                size='lg'
                errorBorderColor='red.300'
                name="email"
                isDisabled={isEmailFieldDisabled()}
                isInvalid={emailErrMsg !== ''}
                value={email}
                onChange={handleEmailInputChange}
              />
              {emailErrMsg && 
                <Text mb='24px' fontSize='sm' textColor='red.300'>*{emailErrMsg}</Text>}

              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                id="password_id"
                borderRadius='15px'
                tabIndex={2}
                mb={passwordErrMsg ? '0px' : '24px'}
                fontSize='sm'
                type='password'
                placeholder='Your password'
                size='lg'
                errorBorderColor='red.300'
                name="password"
                isDisabled={isPasswordFieldDisabled()}
                isInvalid={passwordErrMsg !== ''}
                value={password}
                onChange={handlePasswordInputChange}
              />
              {passwordErrMsg && 
                <Text mb='24px' fontSize='sm' textColor='red.300'>*{passwordErrMsg}</Text>}

              {/* <FormControl display='flex' alignItems='center'>
                <Switch 
                  id='remember-login' 
                  colorScheme='teal' 
                  me='10px'
                  isChecked={rememberMe}
                  onChange={e => setSignInState({...signInState, rememberMe: e.target.checked})} />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  ms='1'
                  fontWeight='normal'>
                  Remember me
                </FormLabel>
              </FormControl> */}

              <Text color={textColor} fontWeight='medium' mt="20px">
                <Link as={RouterLink} color="blueDark" ms='5px' fontWeight='bold' to="/auth/forgot-password/">
                  Forgot password?
                </Link>
              </Text>

              <Button
                ref={submitButtonRef}
                tabIndex={3}
                fontSize='12px'
                type='submit'
                bg='blueDark' //'teal.300'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='5px'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                isDisabled={isSignInButtonDisabled()}
                isLoading={isFormSubmitting}
                onClick={e => handleSubmit()}>
                SIGN IN
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='between'
              alignItems='center'
              maxW='100%'
              mt='0px'>

              <Text color={textColor} fontWeight='medium'>
                New to inback?
                <Link as={RouterLink} color="blueDark" ms='5px' fontWeight='bold' to={pageRoutes.signup}>
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
      </Flex>
    </Flex>
  );
}

export default SignInComp;
