import { MouseEventHandler } from "react";

import { 
    Badge,
    Button,
    ButtonGroup,
    Flex,
    Text,
    useColorModeValue
} from "@chakra-ui/react";

import { FaPen, FaTrashCan } from "react-icons/fa6";

import { RewardCard } from "@/utils/interfaces/rewardProgram";
import { adminRoutePrefix, pageRoutes } from "@/routes";


interface RewardCardPropType {
    rewardCard: RewardCard,
    isDeleting: boolean,
    onEditCard: MouseEventHandler,
    onDeleteCard: MouseEventHandler
}

const RewardCardItem = (props: RewardCardPropType) => {

    const {
        rewardCard,
        isDeleting
    } = props;

    const bgProfile = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)",
        "hsla(0,0%,100%,.8)"
    );

    const getCreatedAtDate = () => {
        const createdAt = new Date(rewardCard.created_at);
        return createdAt.toDateString();
    }

    return (
        <Flex
            flexDirection={{"base": "column"}}
            // boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
            bg={bgProfile}
            p={{"base": "15px"}}
            borderRadius="10px"
            borderBottom="1px"
            borderBottomColor="gray.200"
            className="rewardcard_listitem">
            {/* Coupon title */}
            <Flex
                w="100%"
                justify={{"base": "space-between"}}>
                <Text 
                    fontSize={{ sm: "lg", lg: "xl" }}
                    color="gray.700"
                    fontWeight='bold'
                    ms={{ sm: "8px", md: "0px" }}>
                    {rewardCard.name}
                </Text>
                <ButtonGroup variant="outline" spacing="6">
                    <Button 
                        leftIcon={<FaPen />} 
                        variant="primaryBlue" 
                        minWidth={{"base": "100px"}}
                        onClick={props.onEditCard}>
                        Edit
                    </Button>
                    <Button 
                        leftIcon={<FaTrashCan />} 
                        variant="primaryRed"
                        minWidth={{"base": "100px"}}
                        isLoading={isDeleting}
                        onClick={props.onDeleteCard}>
                        Delete
                    </Button>
                </ButtonGroup>
            </Flex>
            {/* Coupon title ends */}

            {/* Coupon details */}
            <Flex
                w={{"base": "100%"}}
                justify="flex-start"
                gap={{"base": "20px"}}>
                    <Text fontSize={{"base": "sm"}} color="gray.400">
                        <Text as="b">Created: </Text>
                        <Text as="i">{getCreatedAtDate()}</Text>
                    </Text>

                    <Text fontSize={{"base": "sm"}} color="gray.400">
                        <Text as="b">By: </Text>
                        <Text as="i">{rewardCard.created_by_fullname}</Text>
                    </Text>

                    <Text fontSize={{"base": "sm"}} color="gray.400">
                        <Text as="b">Type: </Text>
                        <Text as="i">{rewardCard.card_type === 0 ? "Percent" : "Amount"}</Text>
                    </Text>

                    <Text fontSize={{"base": "sm"}} color="gray.400">
                        <Text as="b">Purpose: </Text>
                        <Text as="i">{rewardCard.purpose_type === 0 ? "Loyalty" : "Direct"}</Text>
                    </Text>
            </Flex>
            {/* Coupone details ends */}
        </Flex>
    );
}

export default RewardCardItem;