import { 
    Skeleton,
    Stack
} from "@chakra-ui/react";


interface RewardCardLoadingProps {}

const RewardCardLoading = (props: RewardCardLoadingProps) => {
    return (
        <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
        </Stack>
    );
}

export default RewardCardLoading;