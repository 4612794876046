import { MouseEventHandler, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Flex, Icon, List, ListIcon, ListItem, Portal, Select, Text, Tooltip, Skeleton, Checkbox } from "@chakra-ui/react";

import Card from "@/components/Card/Card";
import CardHeader from "@/components/Card/CardHeader";
import CardBody from "@/components/Card/CardBody";
import PreviewRewardCard from "@/components/PreviewRewardCard/PreviewRewardCard";
import { RewardCard } from "@/utils/interfaces/rewardProgram";
import DirectRewardAlert from "./DirectRewardAlert";

import { NoListIcon } from "@/components/Icons/Icons";
import { FaRegIdCard, FaTrashCan, FaCircleInfo, FaRegImage } from "react-icons/fa6";

interface SelectRewardCardProps {
    uiState: any,
    selectedCards: RewardCard[],
    fetchRewardCardsData: any,
    onSelect: MouseEventHandler<any>,
    onRemove: MouseEventHandler<any>,
    onPreview: MouseEventHandler<any>,
}

const SelectRewardCard = (props: SelectRewardCardProps) => {
    const {
        uiState,
        selectedCards,
        fetchRewardCardsData,
        handleSelectRewardCard,
        handleRemoveSelectedCard,
        handleOpenPreviewModal,
    } = props;

    const [rewardCards, setRewardCards] = useState([]);
    
    const {
        isFetching,
        isSuccess
    } = fetchRewardCardsData;
    
    useEffect(() => {
        const fetchedData = fetchRewardCardsData.data;
        if (isSuccess && Object.keys(fetchedData).length > 0) {
            const rewardCards = fetchedData.results?.length > 0 ? fetchedData.results.filter(card => card.purpose_type === 1) : [];
            setRewardCards(rewardCards);
        }
    }, [isSuccess]);

    const isSelectedRewardCard = (cardId: string) => {
        const selectedIds = selectedCards.map(card => card.id);
        return selectedIds.includes(cardId);
    }
    
    const selectedCardTooltipLabel = "List of cards below that you've selected to send directly to customer"
    // const getPreviewCard = () => {
    //     return data.length 
    //                 ? <PreviewRewardCard 
    //                     rewardCard={rewardCards[0]} 
    //                     isCreateEditMode={false}
    //                     cardWidth={2.2} /> 
    //                 : <p>No reward card is present</p>
    // }

    const getToolTip = (label: string) => {
        return <Tooltip 
                hasArrow 
                label={label} 
                fontSize='sm'
                bg='gray.300' 
                color='black'
                placement="top" >
                <span>
                    <Icon 
                        as={FaCircleInfo}
                        fontSize="1rem"
                        color="blue.dark"
                        verticalAlign={{"base": "middle"}} />
                </span>
            </Tooltip>
    }

    return (
        <Skeleton
            isLoaded={!isFetching}
            borderRadius="15px">
            <Card
                // p={{"base": "1rem"}}
                h="26rem"
		        overflow="auto">

                <CardHeader>
                    <Flex direction='column'>
                        <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
                        <b>Direct coupon cards</b> {getToolTip(selectedCardTooltipLabel)}
                        </Text>
                    </Flex>
                </CardHeader>

                <CardBody 
                    className="cardbody"
                    ps='20px' 
                    pe='0px' 
                    mt="1rem" 
                    mb='31px' 
                    position='relative'>
                    {/* Selected cards */}
                    <Flex
                        w="inherit"
                        flexDirection="column">

                        {!isFetching && !rewardCards.length && 
                            <Flex 
                                flexDirection="column"
                                justify="center"
                                textAlign="center"
                                mt="6rem">
                                <Text color="gray.500" fontSize="2xl"><Icon as={NoListIcon} /></Text>
                                <Text color="gray.500" fontSize="sm">You don't have any coupons created, create one from <b>Coupon</b> section</Text>
                            </Flex>
                        }
                        
                        <Flex
                            flexDirection="column">
                            {rewardCards?.filter(card => card).map(card => {
                                return (
                                    <Flex key={card.id} flexDir="row" justifyContent="space-between" className="card-item" data-card-id={card.id}>
                                        <Checkbox 
                                            mb="1rem" 
                                            isChecked={isSelectedRewardCard(card.id)}
                                            onChange={event => {
                                                const isChecked = event.target.checked;
                                                const closestCardItemElem = event.target.closest(".card-item");
                                                const cardId = closestCardItemElem.getAttribute("data-card-id");
                                                const currentCard = rewardCards.filter(card => card.id === cardId);
                                                if (currentCard.length > 0) {
                                                    if (isChecked) {
                                                        handleSelectRewardCard({"id": currentCard[0].id, "name": currentCard[0].name});
                                                    } else {
                                                        handleRemoveSelectedCard({"id": currentCard[0].id, "name": currentCard[0].name});
                                                    }
                                                }
                                            }}>
                                            <Text fontSize="md" color="gray.textPrimary">{card.name.length < 50 ? card.name : card.name.slice(0, 30) + "..."}</Text>
                                        </Checkbox>

                                        {/* preview icon */}
                                        <Text 
                                            className="card-item-preview"
                                            data-id={card.id}
                                            color="gray.textPrimary"
                                            fontSize="sm"
                                            cursor="pointer"
                                            _hover={{
                                                fontSize: "md"
                                            }}
                                            onClick={event => {
                                                const closestCardItemPreviewElem = event.target.closest(".card-item-preview");
                                                const cardId = closestCardItemPreviewElem.getAttribute("data-id");
                                                if (cardId.length) {
                                                    onPreview(cardId, "rewardCard");
                                                }
                                            }}>
                                            <Icon 
                                                _hover={{
                                                    color: "white",
                                                    bg: "blue.dark",
                                                    fontSize: "md"
                                                }}
                                                verticalAlign="middle" as={FaRegImage} /> 
                                        </Text>
                                    </Flex>)
                            })}
                        </Flex>
                    </Flex>
                </CardBody>
                
                {!rewardCards.length && 
                    <DirectRewardAlert />}
            </Card>
        </Skeleton>
    )
}

export default SelectRewardCard;