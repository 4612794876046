import React, { useEffect, useState } from 'react';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    Flex,
    Image,
    ListItem,
    Text,
    UnorderedList,
    useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { pageRoutes, adminRoutePrefix } from '@/routes';

import usePreAmountUpdateProgramMutation from '@/hooks/mutations/usePreAmountUpdateProgramMutation';
import { OTP_TIME_INTERVALS } from '@/utils/globalConstants';

import alertGif from '../../../../assets/gif/alert.gif';
import ConfirmActionOTPInput from '@/components/ConfirmActionOTPInput/ConfirmActionOTPInput';


interface WarningUpdateAmountLoyaltyProgramProps {
    isOpen: boolean
    onConfirm: React.MouseEventHandler<React.MouseEvent, boolean>,
    onClose: Function,
    new_amount: number,
    old_amount: number,
}
const WarningUpdateAmountLoyaltyProgram = (props: WarningUpdateAmountLoyaltyProgramProps) => {
    const { isOpen, onConfirm, onClose, new_amount, old_amount } = props;
    const cancelRef = React.useRef()
    
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
    const [numCustomerImpacted, setNumCustomerImpacted] = useState<number>(0);
    const [numRewardCardsToDispatch, setNumRewardCardsToDispatch] = useState<number>(0);
    const [creditsRequired, setCreditsRequired] = useState<number>(0);
    const [forceSendLoyaltyReward, setForceSendLoyaltyReward] = useState<boolean>(false);

    const toast = useToast()
    const navigate = useNavigate();

    const preAmountMutation = usePreAmountUpdateProgramMutation({
        enable: false,
        onSuccess: (res) => {
            const data = res.data;
            setNumCustomerImpacted(data.impacted_customers.length);
            setNumRewardCardsToDispatch(data.total_loyalty_cards);
            setCreditsRequired(data.credit_required);
        },
        onError: (error: any) => {
            toast({
                title: "Error occurred while fetching impact information",
                description: error.data?.detail,
                position: 'top-right',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } 
    });

    useEffect(() => {
        if (isOpen &&   new_amount && old_amount) {
            preAmountMutation.mutate({"amount": new_amount});
        }
    }, [isOpen]);

    const getWarningText = () => {
        if (new_amount > old_amount) {
            return (
                <Flex className="alert-text" flexDir="column">
                    <Text fontSize="md" textAlign="center" mb="1rem">
                        Seems like you're increasing the amount, this may result in some customers getting their loyalty coupon late than expected.
                    </Text>
                </Flex>
            )
        }
        return (
            <Flex className="alert-text" flexDir="column">
                <Text fontSize="md" textAlign="center" mb="1rem">
                    Seems like you are decreasing the amount, this can impact your existing customers in the following manner:
                </Text>
                <UnorderedList 
                    color="gray.textPrimary"
                    fontSize="sm"
                    mb="1rem">
                    <ListItem><Text>Total number of customers who will be impacted is <b>{numCustomerImpacted}.</b></Text></ListItem>
                    <ListItem><Text>Total number of reward coupons will be dispatched is <b>{numRewardCardsToDispatch}.</b></Text></ListItem>
                    <ListItem><Text>Total <b>{creditsRequired}</b> credits will be spent for whole operation.</Text></ListItem>
                </UnorderedList>
            </Flex>
        )
    }

    const getConfirmButton = () => {
        if ((new_amount > old_amount) || numCustomerImpacted <= 0) {
            return (
                <Button 
                    variant="primaryBlue"
                    size="sm"
                    mb="0.75rem"
                    onClick={e => {
                        onConfirm(null, true);
                        onClose();
                        // setIsOpenConfirmation(true);
                        // setForceSendLoyaltyReward(true);
                    }} >
                    Yes, update the loyalty program
                </Button>
            );
        }
        return (
            <Button 
                variant="primaryBlue"
                size="sm"
                mb="0.75rem"
                isDisabled={isOpenConfirmation}
                onClick={e => {
                    onConfirm(null, true);
                    onClose();
                    // setIsOpenConfirmation(true);
                    // setForceSendLoyaltyReward(true);
                }} >
                Yes, update and send the reward coupons
            </Button>
        );
    }


    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={() => onClose()}
            isOpen={isOpen}
            isCentered >
            <AlertDialogOverlay />

            <AlertDialogContent>
            {/* <AlertDialogHeader>Discard Changes?</AlertDialogHeader> */}
            <AlertDialogCloseButton />
            <AlertDialogBody>
                <Image
                    src={alertGif}
                    alt="Warning GIF"
                    boxSize={{base: "100px"}}
                    mx="auto"
                />
                <Text fontSize="lg" textAlign="center">Updating amount of <b>Loyalty Program</b>?</Text>
                <Text fontSize="md" textAlign="center" mb="1rem"><b>Please read the following before confirming!</b></Text>

                {getWarningText()}

                <Text fontSize="md" textAlign="center">Do you still wish to update <b>Loyalty program</b>?</Text>
            </AlertDialogBody>
            
            <AlertDialogFooter
                justifyContent="space-between"
                flexDir="column">
                {getConfirmButton()}
                
                {new_amount < old_amount && numCustomerImpacted > 0 && 
                    <Button 
                        ref={cancelRef}  
                        variant="primaryRed"
                        bgColor="red.300"
                        isDisabled={isOpenConfirmation}
                        size="sm"
                        mb="0.75rem"
                        onClick={e => {
                            onConfirm(null, false);
                            onClose();
                            // setIsOpenConfirmation(true);
                            // setForceSendLoyaltyReward(false);
                        }}>
                        No, update but don't send the reward coupons
                    </Button>
                }
                <Button 
                    ref={cancelRef}  
                    variant="primaryRed"
                    size="sm"
                    mb="0.75rem"
                    onClick={e => {
                        onClose();
                        setIsOpenConfirmation(false);
                    }}>
                    Cancel, I don't want to update
                </Button>
            </AlertDialogFooter>
            
            {isOpenConfirmation && 
                <Flex
                    id="confirmactionotp"
                    flexDir="column"
                    justifyContent="flex-end"
                    m="1rem"
                    borderRadius="sm"
                    borderWidth="1px"
                    borderStyle="dotted">
                    <ConfirmActionOTPInput 
                        isOpen={isOpenConfirmation} 
                        timerSeconds={OTP_TIME_INTERVALS}
                        submitButtonText="Verify OTP and Update"
                        onSuccess={(data: any) => {
                            onConfirm(null, forceSendLoyaltyReward);
                            onClose();
                            setIsOpenConfirmation(false);
                        }}
                        onSendOTPError={(error: any) => {
                            if ("detail" in error.data) {
                                toast({
                                    title: 'Error occurred while sending OTP',
                                    description: error.data.detail,
                                    position: 'top-right',
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                            }
                        }}
                        onVerifyOTPError={(error: any) => {
                            if (error.response) {
                                let response = error.response;
                                if ("non_field_errors" in response.data) {
                                    toast({
                                        title: 'Error occurred while verifying OTP',
                                        description: response.data["non_field_errors"][0],
                                        position: 'top-right',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                }

                                if ("detail" in response.data) {
                                    toast({
                                        title: 'Error occurred while verifying OTP',
                                        description: response.data["detail"],
                                        position: 'top-right',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                }
                            }
                        }} />
                </Flex>
            }
            
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default WarningUpdateAmountLoyaltyProgram;