import { 
    Flex,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    useColorModeValue,
} from "@chakra-ui/react";

import TablesTableRow from "@components/Tables/TablesTableRow";

interface TransactionTableProps {
    captions: string[],
    tablesData: any[],
}

const TransactionTable = (props: TransactionTableProps) => {
    const {
        captions,
        tablesData
    } = props;

    const textColor = useColorModeValue("gray.700", "white");
    return (
        <Table variant='simple' color={textColor}>
            <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                {captions.map((caption, idx) => {
                    return (
                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                        {caption}
                    </Th>
                    );
                })}
                </Tr>
            </Thead>
            <Tbody>
                {tablesData.map((row) => {
                    return (
                        <TablesTableRow
                            key={`${row.id}`}
                            index={row.index}
                            id={row.id}
                            created_at={row.created_at}
                            remark={row.remark}
                            transaction_type={row.transaction_type}
                            amount={row.amount}
                            balance={row.balance}
                        />
                    );
                })}
            </Tbody>
        </Table>
    );
}

export default TransactionTable;