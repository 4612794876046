import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
// Assets
import AuthNewNavbar
 from "@components/Navbars/AuthNewNavbar";
import { isEmailValid, isPhoneValid } from "@/utils/helpers";
import { OTP_TIME_INTERVALS } from "@/utils/globalConstants";
import OTPInputDialog from "@/components/OTPInputDialog/OTPInputDialog";

import MultiStepRegistration from "./components/MultiStepRegistration";
import Step from "./components/Step";
import PersonalDetailsForm from "./components/PersonalDetailsForm";
import BusinessDetailsForm from "./components/BusinessDetailsForm";
import PasswordForm from "./components/PasswordForm";
import { pageRoutes } from "@/routes";


interface SignUpStateType {
  firstName: string,
  firstNameError: string,
  lastName: string,
  lastNameError: string,
  email: string,
  emailError: string,
  phone: string,
  phoneError: string,
  password: string,
  passwordError: string,
  confirmPassword: string,
  confirmPasswordError: string,
  businessName: string,
  businessNameError: string,
  businessLogo: string,
  businessLogoError: string,
  addressLine1: string,
  addressLine2: string,
  state: string,
  city: string,
}

interface OtpState {
  showOtpDialog: boolean,
  otp: string,
  key: string,
  isSubmitting: boolean,
  isResending: boolean,
}

interface SignUpProps {
	signUpState: SignUpStateType,
  otpState: OtpState,
  formCurrentStep: number,
  setFormCurrentStep: Function,

	handleFirstNameChange: React.ChangeEventHandler,
	handleLastNameChange: React.ChangeEventHandler,
	handleEmailChange: React.ChangeEventHandler,
	handlePhoneChange: React.ChangeEventHandler,

	handleBusinessNameChange: React.ChangeEventHandler,
	handleBusinessLogoChange: React.ChangeEventHandler,
	handleAddressLine1Change: React.ChangeEventHandler,
	handleAddressLine2Change: React.ChangeEventHandler,
	handleStateChange: React.ChangeEventHandler,
	handleCityChange: React.ChangeEventHandler,

	handlePasswordChange: React.ChangeEventHandler,
	handleConfirmPasswordChange: React.ChangeEventHandler,

	setSignUpState: Function,
  setOtpState: Function,
  sendOtpAndVerifyForm: Function,
  verifyOtp: Function,
}

const SignUpComp = (props: SignUpProps) => {
  const { signUpState, setSignUpState, otpState, setOtpState } = props;
  const { 
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    email,
    emailError,
    phone,
    phoneError,
    password,
    passwordError,
    confirmPassword,
    confirmPasswordError,
    businessName,
    businessNameError,
	  businessLogo,
	  businessLogoError,
    addressLine1,
    addressLine2,
    city,
    state
   } = signUpState;
  
  // Chakra color mode
  // const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");

  const validatePersonalDetails = (callback: CallableFunction | null = null): Boolean => {
      let isDetailsValid = true;
      let errors: any = {};
      const [phoneIsValid, phoneerror] = isPhoneValid(phone);

      // check for existing errors
      if (firstNameError || 
          lastNameError || 
          emailError ||
          phoneError) {
            return false;
          }

      // validate fields
      if (!firstName.length) {
        isDetailsValid = false;
        errors.firstNameError = "This field is required";
      }

      if (!lastName.length) {
        isDetailsValid = false;
        errors.lastNameError = "This field is required";
      }

      if (!email.length) {
        isDetailsValid = false;
        errors.emailError = "This field is required";
      } else if (!isEmailValid(email)) {
        isDetailsValid = false;
        errors.emailError = "This email is not valid";
      }

      if (!phone.length) {
        isDetailsValid = false;
        errors.phoneError = "This field is required";
      } else if (!phoneIsValid) {
        isDetailsValid = false;
        errors.phoneError = phoneerror;
      }

      // set error stater
      setSignUpState({...signUpState, ...errors});

      return isDetailsValid;
  }

  const validteBusinessDetails = () => {
      let isDetailsValid = true;
      let errors: any = {};

      if (businessLogo.length && businessLogoError.length) {
        isDetailsValid = false;
      }

      if (!businessName.length) {
        isDetailsValid = false;
        errors.businessNameError = "This field is required";
      } else if (businessNameError.length) {
        isDetailsValid = true;
      }

      setSignUpState({...signUpState, ...errors});
      return isDetailsValid;
  }

  const validatePasswordForm = () => {
      let isDetailsValid = true;
      let errors: any = {};

      if (!password) {
        isDetailsValid = false;
        errors.passwordError = "This field is required";
      } else if (passwordError.length) {
        isDetailsValid = false;
      }

      if (!confirmPassword) {
        isDetailsValid = false;
        errors.confirmPasswordError = "This field is required";
      } else if (confirmPasswordError.length) {
        isDetailsValid = false;
      }

      setSignUpState({...signUpState, ...errors});
      return isDetailsValid;
  }

  return (
    <Flex position='relative' flexDirection='column'>

      {/* {AuthNavbar with logo and brand} */}
      <Flex 
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        p={{ base: "48px", lg: "48px", sm: "20px", md: "20px" }}
        pt={{ base: "100px", lg: "0px", sm: "20px", md: "0px" }}
        mt={{md: "30px", lg: "30px"}}
        className="navbar-wrapper">
          <AuthNewNavbar logoText="inback" />
      </Flex>

      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}
        className="main-content">
        
        <Flex
          flexDirection='column'
          w='100%'
          background='transparent'
          p='48px'
          pt={{md: "120px"}}
          display={{ base: "none", md: "block" }}>
            <Heading color="blueDark">Fast.</Heading>
            <Heading color="blueDark">Intuitive.</Heading>
            <Box mt="8">
              <Text fontSize='2xl'>
                <Text as="span" color="blueDark"> inback </Text>
                helps you grow your business by allowing you to show care to your customers.
              </Text>
            </Box>
        </Flex>
        
        <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'>
            <Heading color="blueDark" fontSize='32px' mb='10px'>
              Sign Up
            </Heading>

            {/* MultiStep registration form */}
            <MultiStepRegistration
              currentStep={props.formCurrentStep}
              onNext={() => {
                props.setFormCurrentStep(props.formCurrentStep + 1);
              }}
              onPrevious={() => {
                props.setFormCurrentStep(props.formCurrentStep - 1);
              }}
              onFinalSubmit={() => {
                props.sendOtpAndVerifyForm();
              }}>
              <Step
                className="personaldetail-step"
                validateFn={validatePersonalDetails}>
                <PersonalDetailsForm
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phone={phone}
                  firstNameError={firstNameError}
                  lastNameError={lastNameError}
                  emailError={emailError}
                  phoneError={phoneError}
                  onFirstNameChange={props.handleFirstNameChange}
                  onLastNameChange={props.handleLastNameChange}
                  onEmailChange={props.handleEmailChange}
                  onPhoneChange={props.handlePhoneChange} />
              </Step>

              <Step
                className="businessdetail-step"
                validateFn={validteBusinessDetails}>
                <BusinessDetailsForm 
                  businessName={businessName}
                  businessLogo={businessLogo}
                  addressLine1={addressLine1}
                  addressLine2={addressLine2}
                  businessNameError={businessNameError}
                  businessLogoError={businessLogoError}
                  state={state}
                  city={city}
                  onBusinessNameChange={props.handleBusinessNameChange}
                  onBusinessLogoChange={props.handleBusinessLogoChange}
                  onAddressLine1Change={props.handleAddressLine1Change}
                  onAddressLine2Change={props.handleAddressLine2Change}
                  onStateChange={props.handleStateChange}
                  onCityChange={props.handleCityChange}  />
              </Step>

              <Step
                className="passworddetail-step"
                validateFn={validatePasswordForm}>
                <PasswordForm 
                  password={password}
                  passwordError={passwordError}
                  confirmPassword={confirmPassword}
                  confirmPasswordError={confirmPasswordError}
                  onPasswordChange={props.handlePasswordChange}
                  onConfirmPasswordChange={props.handleConfirmPasswordChange} />
              </Step>
            </MultiStepRegistration>

            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Already have an account?
                <Link as={RouterLink} color="blueDark" ms='5px' fontWeight='bold' to={pageRoutes.signin}>
                  Sign In
                </Link>
              </Text>
            </Flex>
          </Flex>
      </Flex>

      <OTPInputDialog 
        isOpen={otpState.showOtpDialog}
        isSubmitting={otpState.isSubmitting}
        isResending={otpState.isResending}
        timerSeconds={OTP_TIME_INTERVALS}
        otpKey={otpState.key}
        phone={signUpState.phone}
        onSubmit={() => props.verifyOtp()}
        onResend={() => props.sendOtpAndVerifyForm()}
        onStateChange={(value: string )=> props.setOtpState({...otpState, otp: value})}
        onCloseCallback={() => {
          setOtpState({
            ...otpState, 
            showOtpDialog: false,
            isSubmitting: false,
            isResending: false,
            otp: "", 
            key: "", 
          });
        }} 
      />
    </Flex>
  );
}

export default SignUpComp;
