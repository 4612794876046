import React, { useEffect, useState } from "react";

import { 
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Flex,
    Heading,
    Text,
    Input,
} from "@chakra-ui/react";

import { DEFAULT_OTP_LENGTH } from "@utils/globalConstants";

import OTPInputField from "./OTPInputField";


interface OTPInputDialogProps {
    isOpen: boolean,
    isSubmitting: boolean,
    isResending: boolean,
    timerSeconds: number,
    otpKey: string,
    phone: string,
    onSubmit: React.ChangeEventHandler,
    onResend: React.ChangeEventHandler,
    onCloseCallback: CallableFunction | null,
    onStateChange: CallableFunction,
}

const OTPInputDialog = (props: OTPInputDialogProps) => {
    const {
        isOpen,
        isSubmitting,
        timerSeconds,
        otpKey,
        phone,
        onSubmit,
        onResend,
        onCloseCallback,
        onStateChange,
    } = props;

    const [timerCounter, setTimerCounter] = useState(timerSeconds);
    const [intervalId, setIntervalId] = useState<any>(null);
    // const [otpKey, setOtpKey] = useState(props.otpKey);
    const [isResending, setIsResending] = useState(props.isResending);

    const cancelRef = React.useRef()

    useEffect(() => {
        if (isOpen) {
            if (timerCounter <= 0) {
                setTimerCounter(timerSeconds);
            }
            setIntervalCallback();
        }else {
            clearInterval(intervalId);
        }
        // setIntervalId(intervalId);
        return () => {
            intervalId ? clearInterval(intervalId) : null;
        }
    }, [otpKey]);

    useEffect(() => {
        if (timerCounter <= 0) {
            clearInterval(intervalId);
        }
    }, [timerCounter]);

    // useEffect(() => {
    //     console.log("reseting timer...")
    //     setTimerCounter(timerSeconds);
    //     // setIntervalCallback();
    // }, [otpKey]);

    const setIntervalCallback = () => {
        const intervalId  = setInterval(() => {
            setTimerCounter(timerCounter => timerCounter - 1);
            console.log("timerCounter: ", timerCounter); 
        }, 1000);
        setIntervalId(intervalId);
    }

    
    const setFocusOnFirstOtpInputField = () => {
        const firstOtpInput = document.querySelector("input.otp-input");
        firstOtpInput.focus();
    }


    const getOTPInputFields = () => {
        let inputComps = [];
        for (let i=0; i<DEFAULT_OTP_LENGTH - 1; i++) {
            inputComps.push(
                <OTPInputField 
                    isDisabled={false} 
                    onInputChange={null}
                    onSubmit={null} />
            );
        }
        return inputComps;
    }

    const isResendingButtonDisabled = () => {
        let isDisabled = false;
        if (timerCounter !== 0) {
            isDisabled = true
        }else {
            if (isSubmitting || isResending) {
                isDisabled = true;
            }
        }
        return isDisabled;
    }

    const handleOnClose = () => {
        if (onCloseCallback) {
            onCloseCallback();
        }
        return intervalId ? clearInterval(intervalId) : null;
    }

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={handleOnClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isOpen={isOpen}
            isCentered
        >
        <AlertDialogOverlay />

        <AlertDialogContent>
          {/* <AlertDialogHeader>Discard Changes?</AlertDialogHeader> */}
          <AlertDialogCloseButton />
          <AlertDialogBody
            onLoad={event => {
                setFocusOnFirstOtpInputField();
            }}>
            <Flex 
                className="dialog-header"
                flexDir="column"
                mb="1rem">
                <Heading
                    size="lg"
                    mb="0.5rem">Phone number verification</Heading>
                <Text>
                    Enter the verification code we sent on your phone number <Text as="b" color="red.500">{phone}</Text>
                </Text>
            </Flex>

            <Flex
                className="dialog-otp-input-group"
                flexDir="column"
                justify="center"
                mb="0">
                {/* {getOTPInputFields()} */}
                <Input 
                mb="0.5rem"
                    type="number" 
                    textAlign="center"
                    placeholder="Enter code here"
                    isDisabled={isSubmitting || isResending || timerCounter === 0}
                    onChange={event => {
                        const value = event.target.value;
                        // setOTP(value);
                        onStateChange(value);
                        // if (onSubmit && otp.length == 6) {
                        //     onSubmit(event);
                        // }
                    }} />

                <Button
                    bg="blue.dark"
                    color="gray.200"
                    w="100%"
                    borderRadius="5px"
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || isResending || timerCounter === 0}
                    _hover={{
                        color: "white"
                    }}
                    onClick={onSubmit}>Submit</Button>
            </Flex>
            <Flex
                justify="start"
                p="0"
                // m="0"
                mb="1rem"
                h="1rem">
                {timerCounter !== 0 && 
                    <Text as="b" color="gray.textPrimary">{timerCounter}</Text>
                }
            </Flex>

            
            <Flex
                className="dialog-otp-resend"
                flexDir="column"
                justify="center"
                mb="1rem">
                <Text textAlign="center" color="gray.textPrimary" as="span">
                    Didn't recieve code?
                </Text>
                <Button
                    bg=""
                    border=""
                    color="red.600"
                    height="fit-content"
                    _hover={{
                        bg: ""
                    }}
                    onClick={event => {
                        onResend(event);
                    }}
                    isLoading={isResending}
                    isDisabled={isResendingButtonDisabled()} >
                    Resend
                </Button>
            </Flex>
          </AlertDialogBody>
          {/* <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme='red' ml={3}>
              Yes
            </Button>
          </AlertDialogFooter> */}
        </AlertDialogContent>
      </AlertDialog>
    );
}

export default OTPInputDialog;