import { 
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Box,
    Button,
    Flex,
} from "@chakra-ui/react";

import {
    FaArrowRightLong
} from "react-icons/fa6";

import { redirect, useNavigate } from "react-router-dom";

import { adminRoutePrefix, pageRoutes } from "@/routes";

const HeaderAlert = () => {
    const navigate = useNavigate();

    return (
        <Flex 
            className="top_alert" 
            justify="center" 
            w="100%"
            mb={{"base": "1rem"}}>
            <Alert 
                status="warning" 
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                borderRadius="10px">
                <AlertIcon boxSize={{"base": "40px"}} />
                <Box>
                    <AlertTitle>Loyalty program is not configured yet!</AlertTitle>
                    <AlertDescription>
                        Your have not configured your Loyalty program yet. We are not
                        able to send Coupons to your customers. We can see that you have added Coupon(s) 
                        and if you want to configure your <b>Loyalty program</b>, you can do so by clicking the 
                        button below.
                        <Flex justify={{"base": "center"}} mt="1rem" mb="1rem">
                            <Button 
                                variant="alertPrimary" 
                                rightIcon={<FaArrowRightLong />}
                                onClick={e => {
                                    return navigate(adminRoutePrefix + pageRoutes.rewardProgram);
                                }}>
                                Configure Loyalty Program
                            </Button>
                        </Flex>
                    </AlertDescription>
                </Box>
            </Alert>
        </Flex>
    );
}

export default HeaderAlert;