import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getCookie, getTokenKey } from "@/utils/helpers";
import { REWARD_CARD_DETAIL_URL } from "@/utils/endpoints";


const useDeleteCouponMutation = (extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const mutationRes = useMutation({
        mutationFn: (data: any) => {
            return axios.delete(REWARD_CARD_DETAIL_URL(data.cardId), {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`,
                }
            })
        },
        ...extraArgs
    });

    // navigate to signin page when 401 status is recieved in status
    if (mutationRes.error && mutationRes.error.request.status === 401) {
        navigate("/");
    }
    return mutationRes;
}

export default useDeleteCouponMutation;