import { useState, useEffect, useContext } from "react";

import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { DEFAULT_COUNTRY_CODE, VERIFICATION_CODE_LENGTH } from "@/utils/globalConstants";
import { REWARD_PROGRAM_URL, CUSTOMER_PURCHASE_ENTRY_URL } from "@/utils/endpoints";
import { isPhoneValid, isAmountValid, getCookie, getTokenKey } from "@/utils/helpers";
import { GlobalContext } from "@/utils/contexts";

import { 
    useFetchLoyaltyProgram,
    useFetchPurchaseEntryList,
    useFetchRewardCards,
    useFetchMetrics,
    useVerifyRewardCards
} from "@/hooks";
import usePurchaseConfirm from "./hooks/usePurchaseConfirm";
import DashboardComp from "./DashboardComp";

const Dashboard = () => {
    const toast = useToast();
    const context = useContext(GlobalContext);

    const [purchase, setPurchase] = useState({
        phone: "",
        phoneCode: `${DEFAULT_COUNTRY_CODE}`,
        amount: 0,
        verifCode: "", // reward_card_verification_code
        direct_reward_cards: []
    });

    const [miniStatData, setMiniStatData] = useState({
        totalLoyalityCustomers: 0,
        todayLoylityCustomers: 0,
        totalCardsConsumed: 0,
        totalRetentionRevenue: 0,
    })

    const [uiState, setUiState] = useState({
        isLoading: true,

        // TODO: setting isSendDirectCard to true for the time being
        // it may change in the future, currently disabling this feature
        // by commenting it out of setting a default value to true
        isSendDirectCard: true,

        isApplyVerifCode: false,
        isPurchaseWarningDialogOpen: false,
        loyaltyProgramStatus: 1,

        // TODO: setting applyLoyaltyProgram to true for the time being
        // it may change in the future, currently disabling this feature
        // by setting a default value to true
        applyLoyaltyProgram: true,

        isLoyaltyProgramCreated: false,
        selectedCards: [],
        verifCodeSuccess: "",
        verifRewardCardData: {},
        discountedAmount: 0,

        phoneError: "",
        amountError: "",
        verifCodeError: "",
        generalError: "",
    });
    
    const [getConfirmation, PurchaseConfirmationDialog] = usePurchaseConfirm(uiState.loyaltyProgramStatus,
                                                                            uiState.isLoyaltyProgramCreated,
                                                                            purchase.verifCode,
                                                                            uiState.selectedCards);

    const fetchProgramData = useFetchLoyaltyProgram(false, {
        onSuccess: (data: any) => {
            let isLoyaltyProgramCreated = false;
            if (data.id) {
                isLoyaltyProgramCreated = true;
            }
            setUiState({...uiState, isLoyaltyProgramCreated: isLoyaltyProgramCreated, loyaltyProgramStatus: data.status});
        }
    });
    const fetchRewardCardsData = useFetchRewardCards(false);
    const fetchMetricData = useFetchMetrics(false);
    const fetchPurchaseListData = useFetchPurchaseEntryList(false, purchase.phoneCode + purchase.phone);
    
    const verifyRewardCardQuery = useVerifyRewardCards(false, purchase.amount, purchase.verifCode, {
        retry: 1,
        onError: (error: any) => {
            if (error.response) {
                let res = error.response;
                let errorObj = {};
                
                if ("verification_code" in res.data) {
                    errorObj["verifCodeError"] = res.data.verification_code;
                    // setUiState({...uiState, verifCodeError: res.data.verification_code});
                }
                if ("reward_card" in res.data) {
                    errorObj["verifRewardCardData"] = res.data["reward_card"];
                    // setUiState({...uiState, verifRewardCardData: res.data["reward_card"]});
                }

                setUiState({...uiState, ...errorObj});
            } else if (error.request) {
                throw "No response while trying to verify coupon";
            } else {
                throw "Status code 500, Internal server error";
            }
        },
        onSuccess: (data: any) => {
            let successData = {};
            if ("reward_card" in data) {
                successData["verifRewardCardData"] = data["reward_card"];
                // setUiState({...uiState, verifRewardCardData: data["reward_card"]}); 
            }
            if ("discounted_amount" in data) {
                successData["discountedAmount"] = data["discounted_amount"];
                // setUiState({...uiState, discountedAmount: data["discounted_amount"]}); 
            }
            setUiState({...uiState, ...successData, verifCodeSuccess: "Fetched successfully"});   
        }
    })

    const updateLoyaltyProgramMutation = useMutation({
        mutationFn: (data) => {
            return axios.patch(REWARD_PROGRAM_URL, data, {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`
                }
            })
        },
        onError: (error, varibles, context) => {
            if (error.response){ // error but got response
                const data = error.response.data;
                toast({
                    title: 'Error occurred while changing loyalty status',
                    description: data.detail,
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Internal server error',
                    description: "Error occurred while updating loyalty program",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                throw new Error("Error occurred while updating loyalty program");
            }
        },
        onSuccess: (data) => {
            const status = data.data.status;
            setUiState({...uiState, loyaltyProgramStatus: Number(status)});
        }
    });


    const createPurchaseEntryMutation = useMutation({

        mutationFn: (data) => {
            return axios.post(CUSTOMER_PURCHASE_ENTRY_URL, data, {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`
                }
            })
        },
        onError: (error) => {
            if (error.response){ // error but got response
                const data = error.response.data;
                let fieldErrors = {
                    phoneError: "",
                    amountError: "",
                    verifCodeError: "",
                };

                if ("phone" in data) {
                    fieldErrors.phoneError = data.phone[0];
                }

                if ("amount" in data) {
                    fieldErrors.amountError = data.amount[0];
                }

                if ("verification_code" in data) {
                    fieldErrors.verifCodeError = data.verification_code[0];
                }

                setUiState({...uiState, ...fieldErrors});

                if ("direct_reward_cards" in data) {
                    toast({
                        title: data.direct_reward_cards,
                        // description: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if ("detail" in data) {
                    toast({
                        title: "Error occurred while creating purchase history",
                        description: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                
            } else {
                toast({
                    title: 'Internal server error',
                    description: "Error occurred while updating loyalty program",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                throw new Error("Error occurred while updating loyalty program");
            }
        },
        onSuccess: (data) => {
            toast({
                title: 'Successfully created purchase for ' + purchase.phone,
                position: 'top-right',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            // reset the purchase state data
            setPurchase(
                {
                    ...purchase, 
                    phone: "",
                    amount: 0,
                    verifCode: "",
                    direct_reward_cards: []
                }
            );

            // refetch the metrics
            fetchMetricData.refetch();
            // refetch credits balance
            context.setRefetchCredits(true);
        }
    })

    // effect to deal with fetching error
    // currently we're just throwing errors for ErrorBoundary to kick in
    // todo: Need to find better way to handle fetching errors
    useEffect(() => {
        if (fetchProgramData.isError) {
            const { error } = fetchProgramData;
            if (error && "response" in error) {
                const response = error.response;
                if (response.status === 404) {
                    setUiState({...uiState, isLoyaltyProgramCreated: false, applyLoyaltyProgram: false});
                }
            }
            else {
                throw "Something went wrong while fetching Loyalty program";
            }
        }
        if (fetchRewardCardsData.isError) {
            throw "Something went wrong while fetching Reward cards";
        }
        if (fetchMetricData.isError) {
            throw "Something went wrong while fetching metrics data";
        }
        if (fetchPurchaseListData.isError) {
            throw "Something went wrong while fetching Customer journey data";
        }
    }, [fetchProgramData.isError, 
        fetchRewardCardsData.isError, 
        fetchMetricData.isError, 
        fetchPurchaseListData.isError])

    // fetch loyaltyprogram data, metrics, and reward cards 
    // on first render
    useEffect(() => {
        fetchMetricData.refetch();
        fetchProgramData.refetch();
        fetchRewardCardsData.refetch();
    }, []);


    // effect to handle fetching purchase entry data when
    // phonenumber is valid ten digits
    useEffect(() => {
        // fetch purchase data for the phone number
        const { refetch } = fetchPurchaseListData;
        const [isValid, error] = isPhoneValid(purchase.phone);
        if (isValid) {
            refetch();
        }
    }, [purchase.phone]);


    useEffect(() => {
        setUiState({...uiState, verifCodeError: "", verifCodeSuccess: "", verifRewardCardData: {}});
    }, [purchase.verifCode, purchase.phone, purchase.amount]);


    // load selectedCards from session storage if they are there
    useEffect(() => {
        if (fetchRewardCardsData.isFetched) {
            const existingSelectedCardsJSON = sessionStorage.getItem("selectedCards");
            if (existingSelectedCardsJSON && existingSelectedCardsJSON.length) {
                const existingSelectedCards = JSON.parse(existingSelectedCardsJSON);

                // if existingSelectedCards are in fetched reward cards only then
                // add then to selectedCards state
                // const existingSelectedCardsIds = existingSelectedCards.map(card => card.id);
                const fetchedRewardCardIds = fetchRewardCardsData.data.results.map(card => card.id);
                const intersection = existingSelectedCards.filter(card => fetchedRewardCardIds.includes(card.id));

                if (intersection.length) {
                    setUiState({...uiState, selectedCards: existingSelectedCards});
                }
            }
        } 
    }, [fetchRewardCardsData.isFetched]);


    //////////////////////////////////////////////////////////////
    // EVENT HANDLERS ////////////////////////////////////////////
    //////////////////////////////////////////////////////////////
    const isPurchaseValid = () => {
        if (!uiState.phoneError &&
            !uiState.amountError &&
            !uiState.verifCodeError &&
            !uiState.generalError){
                return true;
            }
        return false;
    }

    const shouldOpenPurchaseWarningDialog = () => {
        // if purchase data is valid and 
        // either loyaltyProgramStatus is active or there are cards
        // selected to be sent directly to user, then display this dialog
        const hasSelectedCards = uiState.isSendDirectCard && uiState.selectedCards.length > 0;
        if ((uiState.applyLoyaltyProgram || hasSelectedCards) && isPurchaseValid()) {
                return true;
            }
        return false;
    }

    const createPurchaseEntry = () => {
        let data = {
            "phone": purchase.phoneCode + purchase.phone,
            "amount": purchase.amount,
            "reward_card_verification_code": purchase.verifCode,
            "direct_reward_cards": uiState.isSendDirectCard ? uiState.selectedCards.map(item => item.id) : [],
        }
        createPurchaseEntryMutation.mutate(data);
    }

    const handlePhoneChange = (event: KeyboardEvent) => {
        const phone = event.target.value;
        const [isValid, error] = isPhoneValid(phone);

        if (error.length) {
            setUiState({...uiState, phoneError: error});
        }else if (isValid) {
            setUiState({...uiState, phoneError: ""});
        }
        
        setPurchase({...purchase, phone: phone});
    }

    const handleAmountChange = (event: KeyboardEvent) => {
        const amount = event.target.value;
        const [isValid, error] = isAmountValid(amount);
        setPurchase({...purchase, amount: amount});

        if (!isValid && error.length) {
            setUiState({...uiState, amountError: error});
        }else if (isValid) {
            setUiState({...uiState, amountError: ""});
        }
    }

    const handleVerifCodeChange = (event: KeyboardEvent) => {
        const verifCode = event.target.value;
        if (uiState.isApplyVerifCode && !verifCode.length) {
            setUiState({...uiState, verifCodeError: "Verification code can't be empty"});
        }else {
            setUiState({...uiState, amountError: ""});
        }
        setPurchase({...purchase, verifCode: verifCode});
    }

    const handleDirectCardCheckboxChange = (event: MouseEvent) => {
        setUiState({...uiState, isSendDirectCard: event.target.checked});
    }

    const handlePurchaseResetOnClick = (event: MouseEvent) => {
        setPurchase({...purchase, phone: "", amount: 0, verifCode: "", direct_reward_cards: []});
    }

    const handlePurchaseEntrySubmitButtonClick = async (event: MouseEvent) => {
        if (isPurchaseValid()){
            const status = await getConfirmation();
            if (status) { // status is true, createPurchaseEntry
                createPurchaseEntry();
            }
        }
    }

    const handleSelectRewardCard = (cardJSON) => {
        let selectedCards = uiState.selectedCards;

        // if card is already in selectedCards, don't add it
        for (let card of selectedCards) {
            if (card.id === cardJSON.id) {
                // card exists in selectedCards, return
                return;
            }
        }
        selectedCards.push({id: cardJSON.id, name: cardJSON.name});
        setUiState({...uiState, selectedCards: selectedCards});
        // update selected card in session storage
        sessionStorage.setItem("selectedCards", JSON.stringify(selectedCards));
    }

    const handleRemoveSelectedCard = (cardJSON) => {
        let selectedCards = uiState.selectedCards;
        let newSelectedCards: any[] = [];

        // if card is already in selectedCards, remove it
        for (let card of selectedCards) {
            if (card.id === cardJSON.id) {
                // card exists in selectedCards, don't add it to newSelectedCards
                continue;
            }
            newSelectedCards.push(card);
        }
        setUiState({...uiState, selectedCards: newSelectedCards});
        // update selected card in session storage
        sessionStorage.setItem("selectedCards", JSON.stringify(newSelectedCards));
    }

    // update applyLoyaltyProgram
    const handleProgramStatusOnChange = (event: MouseEvent) => {
        setUiState({...uiState, applyLoyaltyProgram: event.target.checked});
    }

    return (
        <DashboardComp 
            purchase={purchase} 
            uiState={uiState}

            fetchProgramData={fetchProgramData}
            fetchRewardCardsData={fetchRewardCardsData}
            fetchMetricData={fetchMetricData}
            fetchPurchaseListData={fetchPurchaseListData}
            verifyRewardCardQuery={verifyRewardCardQuery}

            handleAmountChange={handleAmountChange}
            handlePhoneChange={handlePhoneChange}
            handleVerifCodeChange={handleVerifCodeChange}
            handleDirectCardCheckboxChange={handleDirectCardCheckboxChange}
            handlePurchaseEntrySubmitButtonClick={handlePurchaseEntrySubmitButtonClick}
            handleSelectRewardCard={handleSelectRewardCard}
            handleRemoveSelectedCard={handleRemoveSelectedCard}
            handleProgramStatusOnChange={handleProgramStatusOnChange}
            handlePurchaseResetOnClick={handlePurchaseResetOnClick}
            PurchaseConfirmationDialog={PurchaseConfirmationDialog}
         />
    )
}

export default Dashboard;