import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { CUSTOMER_PURCHASE_ENTRY_LIST_URL } from "@/utils/endpoints";
import { getTokenKey } from "@/utils/helpers";


const useFetchPurchaseEntryList = (enabled: boolean = true, phone: string) => {
    const navigate = useNavigate();
    const queryRes = useQuery({queryKey: ["purchaseEntryList"], queryFn: async () => {
        const res = await axios(CUSTOMER_PURCHASE_ENTRY_LIST_URL(phone), {
            withCredentials: true,
            headers: {
                "Authorization": `Token ${getTokenKey()}`
            }
        });
        return res.data;
    }, enabled: enabled, refetchOnWindowFocus: false})

    // navigate to signin page when 401 status is recieved in status
    if (queryRes.error && queryRes.error.request.status === 401) {
        navigate("/");
    }
    return queryRes;
}

export default useFetchPurchaseEntryList;