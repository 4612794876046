import React, { useState } from "react";

import { createPromise } from "@/utils/helpers";
import WarningUpdateAmountLoyaltyProgram from "../components/WarningUpdateAmountLoyaltyProgram";


const useAmountUpdateLoyaltyProgramConfirm = (new_amount: number, 
                                              old_amount: number) => {
    
    const [open, setOpen] = useState(false);
    const [ resolver, setResolver ] = useState({ resolver: null });

    const getConfirmation = async () => {
        setOpen(true);
        const [promise, resolver] = await createPromise();
        setResolver({resolver});
        return promise;
    }

    const onClick = async (status: boolean, forceSendLoyaltyReward: boolean) => {
        setOpen(status);
        resolver.resolver([status, forceSendLoyaltyReward]);
    }

    const ConfirmationDialog = () => {
        return <WarningUpdateAmountLoyaltyProgram 
                    isOpen={open}
                    onConfirm={(event, forceSendLoyaltyReward: boolean = false) => {
                        onClick(true, forceSendLoyaltyReward);
                    }}
                    onClose={event => onClick(false)}
                    new_amount={new_amount}
                    old_amount={old_amount} />
    }

    return [getConfirmation, ConfirmationDialog];
}

export default useAmountUpdateLoyaltyProgramConfirm;
