/*
 * Creates an overlay on width and height on parent element 
*/

import { Flex } from "@chakra-ui/react";
const ParentOverlay = (props) => {
	const { children, ...rest } = props;
    return (
        <Flex
			w="100%"
			h="100%"
			bg="blackAlpha.600"
			zIndex="overlay"
			opacity="1"
			position="absolute"
			top="0"
			left="0"
			borderRadius={{ base: "inherit" }}
			justify="center"
			{...rest}
		>{children}</Flex>
    );
}

export default ParentOverlay;