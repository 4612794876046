import React from "react";

import {
    Flex,
    Grid,
    GridItem
} from "@chakra-ui/react";


interface CreditPlanListProps {
    // children: React.ReactNode[],
}

const CreditPlanList = (props: CreditPlanListProps) => {
    return (
        <Grid
            id="creditplanlist"
            gap={1}
            alignItems="stretch"
            templateColumns={{base: "repeat(1, 1fr)", lg: "repeat(4, 1fr)", md: "repeat(4, 1fr)", xl: "repeat(4, 1fr)", sm: "repeat(1, 1fr)"}}
            // autoFlow={{base: "column", lg: "column", md: "column", xl: "column", sm: "row"}} 
            >
            {props.children}
        </Grid>
    );
}

export default CreditPlanList;