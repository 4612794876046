import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { LOGOUT_URL } from "@/utils/endpoints";
import { getTokenKey, removeAccessToken } from "@/utils/helpers";
import { pageRoutes } from "@/routes";


const useLogoutMutation = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const logoutMutation = useMutation({
        mutationFn: (data) => {
            return axios.post(LOGOUT_URL, null, {
                withCredentials: true,
                headers: {
                  "Authorization": `Token ${getTokenKey()}`
                }
            })
        },
        onError: (error) => {
          toast({
              title: "Error occurred while logging out user",
              // description: data.detail,
              position: 'top-right',
              status: 'error',
              duration: 9000,
              isClosable: true,
          });
        },
        onSuccess: (data) => {
          setTimeout(
            () => {
              console.log("Logging out, bye!");
              removeAccessToken();
              navigate(pageRoutes.signin);
            }, 
            1000);
        }
    });

    return logoutMutation
}

export default useLogoutMutation;
