import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
    ChakraProvider,
    useToast
} from '@chakra-ui/react';

import { REGISTER_SEND_OTP_URL, REGISTER_VERIFY_OTP_URL } from '@/utils/endpoints';
import { isEmailValid, isPhoneValid, getCookie, isTokenValid } from '@/utils/helpers';
import { DEFAULT_COUNTRY_CODE } from '@/utils/globalConstants';
import { pageRoutes, adminRoutePrefix } from '@/routes';

import SignUpComp from './SignUpComp';
import theme from "@/theme/theme";
import { sign } from 'crypto';


const SignUp: React.FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [formCurrentStep, setFormCurrentStep] = useState(1);

    const [signUpState, setSignUpState] = useState({
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: '',
        email: '',
        emailError: '',
        phone: '',
        phoneError: '',
        password: '',
        passwordError: '',
        confirmPassword: '',
        confirmPasswordError: '',
        businessName: '',
        businessNameError: '',
        businessLogo: '',
        businessLogoFile: new FormData(),
        businessLogoError: '',
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
    });

    const [otpState, setOtpState] = useState({
        showOtpDialog: false,
        key: "",
        otp: "",
        isSubmitting: false,
        isResending: false,
    });

    // Effect to check if token is expired or not
	useEffect(() => {
		if (isTokenValid()) {
			// redirect to dashboard page
			navigate(adminRoutePrefix + pageRoutes.dashboard);
		}
	}, []);

//////////////////////////////////////////////////////////////
// helpers functions /////////////////////////////////////
//////////////////////////////////////////////////////////////
const moveToErroredStep = () => {
    let newFormStep = formCurrentStep;
    if (signUpState.firstNameError.length > 0 || 
        signUpState.lastNameError.length > 0 || 
        signUpState.emailError.length > 0 || 
        signUpState.phoneError.length > 0) {
        newFormStep = 1;
    } else if (signUpState.businessLogoError.length > 0 || 
            signUpState.businessNameError.length > 0) {
        newFormStep = 2;
    } else if (signUpState.passwordError.length > 0 ||
              signUpState.confirmPasswordError.length > 0) {
        newFormStep = 3;
    }
    setFormCurrentStep(newFormStep);
}

//////////////////////////////////////////////////////////////
// API call functions /////////////////////////////////////
//////////////////////////////////////////////////////////////
    const sendOtpAndVerifyForm = () => {
        let data = {
            first_name: signUpState.firstName,
            last_name: signUpState.lastName,
            email: signUpState.email,
            phone: DEFAULT_COUNTRY_CODE + signUpState.phone,
            logo: signUpState.businessLogoFile.get("businessLogo"),
            business_name: signUpState.businessName,
            password: signUpState.password,
            confirm_password: signUpState.confirmPassword
        }

        setOtpState({...otpState, isResending: true});
        axios.post(REGISTER_SEND_OTP_URL, data, {
            withCredentials: false,
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => {
            const key: string = response.data.key;
            setOtpState({...otpState, key: key, otp: "", isResending: false, showOtpDialog: true});
        })
        .catch(error => {
            setOtpState({...otpState, isResending: false});
            if (error.response){ // error but got response
                const data = error.response.data;
                let errors: any = {};
                errors["firstNameError"] = "first_name" in data ? data.first_name : "";
                errors["lastNameError"] = "last_name" in data ? data.last_name : "";
                errors["emailError"] = "email" in data ? data.email : "";
                errors["phoneError"] = "phone" in data ? data.phone : "";

                errors["businessNameError"] = "business_name" in data ? data.business_name : "";
                errors["businessLogo"] = "business_logo" in data ? data.business_logo : "";
                
                errors["passwordError"] = "password" in data ? data.password : "";
                errors["confirmPasswordError"] = "confirm_password" in data ? data.confirm_password : "";

                if ("detail" in data) {
                    toast({
                        title: 'Error occurred while registering',
                        description: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if ("non_field_errors" in data) {
                    toast({
                        title: 'Error occurred while registering',
                        description: data.non_field_errors[0],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                setSignUpState({...signUpState, ...errors});

                // if errors then move to the step where error is
                setTimeout(() => moveToErroredStep(), 1000);

            } else {
                toast({
                    title: 'Internal server error',
                    description: "Error occurred while trying to register business ",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        })
    }

    const verifyOtp = () => {
        let data = {
            first_name: signUpState.firstName,
            last_name: signUpState.lastName,
            email: signUpState.email,
            phone: DEFAULT_COUNTRY_CODE + signUpState.phone,
            logo: signUpState.businessLogoFile.get("business_logo"),
            business_name: signUpState.businessName,
            password: signUpState.password,
            confirm_password: signUpState.confirmPassword,
            otp: otpState.otp,
            key: otpState.key
        }

        setOtpState({...otpState, isSubmitting: true});
        axios.post(REGISTER_VERIFY_OTP_URL, data, {
            withCredentials: true,
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => {
            setOtpState({...otpState, isSubmitting: false});
            toast({
                title: 'Registered sucessfully!',
                description: "",
                position: 'top-right',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            // save authentication token and expiration time in localStorage
            const token = response.data["token"];
            const token_expiration_datetime = response.data["token_expiration_datetime"];
            localStorage.setItem("inback_api_token", JSON.stringify({"token": token, "token_expiration_datetime": token_expiration_datetime}))
            
            // redirect to dashboard
            const dashboardRoute: string =  adminRoutePrefix + pageRoutes.dashboard;
            navigate(dashboardRoute);
        })
        .catch(error => {
            setOtpState({...otpState, isSubmitting: false});
            if (error.response){ // error but got response
                const data = error.response.data;
                let errors: any = {};
                errors["firstNameError"] = "first_name" in data ? data.first_name : "";
                errors["lastNameError"] = "last_name" in data ? data.last_name : "";
                errors["emailError"] = "email" in data ? data.email : "";
                errors["phoneError"] = "phone" in data ? data.phone : "";

                errors["businessNameError"] = "business_name" in data ? data.business_name : "";
                errors["businessLogo"] = "business_logo" in data ? data.business_logo : "";
                
                errors["passwordError"] = "password" in data ? data.password : "";
                errors["confirmPasswordError"] = "confirm_password" in data ? data.confirm_password : "";

                if ("detail" in data) {
                    toast({
                        title: 'Error occurred while registering',
                        description: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if ("non_field_errors" in data) {
                    toast({
                        title: 'Error occurred while registering',
                        description: data.non_field_errors[0],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                
                setSignUpState({...signUpState, ...errors});

            } else {
                toast({
                    title: 'Internal server error',
                    description: "Error occurred while trying to register business ",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        })
    }
  //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////


    const handleFirstNameChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        let error = "";
        if (value === "") {
            error = "This field is required";
        }
        setSignUpState({...signUpState, firstName: value, firstNameError: error});
      }
    
    const handleLastNameChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        let error = "";
        if (value === "") {
            error = "This field is required";
        }
        setSignUpState({...signUpState, lastName: value, lastNameError: error});
    }

    const handleEmailChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        let error = "";
        if (value === "") {
            error = "This field is required";
        } else if (!isEmailValid(value)) {
            error = "Please enter a valid email address";
        }
        setSignUpState({...signUpState, email: value, emailError: error});
    }

    const handlePhoneChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        const [isValid, error] = isPhoneValid(value);
        
        setSignUpState({...signUpState, phone: value, phoneError: error});
    }

    const handleBusinessNameChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        let error = "";
        if (value === "") {
            error = "This field is required";
        }
        setSignUpState({...signUpState, businessName: value, businessNameError: error});
    }

    const handleBusinessLogoChange = (event: React.ChangeEvent )=> {
        event.preventDefault();
        let imageFile = event.target.files[0];
        let error = "";
        if (imageFile) {
            const localImageUrl = URL.createObjectURL(imageFile);
            // let image = new Image()
            // image.onload = function () {
            //     URL.revokeObjectURL(imageFile);
            // };
            // image.src = localImageUrl;
            if ((imageFile.size /1024 /1024) > 1.0) {
                error = "*Image file size cannot exceed 1 MB";
            }

            setSignUpState({
                ...signUpState, 
                businessLogo: localImageUrl,
                businessLogoError: error});
            signUpState.businessLogoFile.set("business_logo", imageFile)
        }
    }

    const handleAddressLine1Change = (event: React.ChangeEvent) => {
        const value = event.target.value;
        setSignUpState({...signUpState, addressLine1: value});
    }

    const handleAddressLine2Change = (event: React.ChangeEvent) => {
        const value = event.target.value;
        setSignUpState({...signUpState, addressLine2: value});
    }

    const handleStateChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        setSignUpState({...signUpState, state: value});
    }

    const handleCityChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        setSignUpState({...signUpState, city: value});
    }

    const handlePasswordChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        let error = "";
        if (value === "") {
            error = "This field is required";
        }
        setSignUpState({...signUpState, password: value, passwordError: error});
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent) => {
        const value = event.target.value;
        let error = "";
        if (value === "") {
            error = "This field is required";
        } else if (value != signUpState.password) {
            error = "Does not match password"
        }
        setSignUpState({...signUpState, confirmPassword: value, confirmPasswordError: error});
    }

    return (
        <SignUpComp 
            signUpState={signUpState}
            setSignUpState={setSignUpState}
            otpState={otpState}
            setOtpState={setOtpState}
            formCurrentStep={formCurrentStep}
            setFormCurrentStep={setFormCurrentStep}

            handleFirstNameChange={handleFirstNameChange}
            handleLastNameChange={handleLastNameChange}
            handleEmailChange={handleEmailChange}
            handlePhoneChange={handlePhoneChange}

            handleBusinessNameChange={handleBusinessNameChange}
            handleBusinessLogoChange={handleBusinessLogoChange}
            handleAddressLine1Change={handleAddressLine1Change}
            handleAddressLine2Change={handleAddressLine2Change}
            handleStateChange={handleStateChange}
            handleCityChange={handleCityChange}

            handlePasswordChange={handlePasswordChange}
            handleConfirmPasswordChange={handleConfirmPasswordChange}

            sendOtpAndVerifyForm={sendOtpAndVerifyForm}
            verifyOtp={verifyOtp} />
    );
}

export default SignUp;