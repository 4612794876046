import React, { useState } from 'react';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    Flex,
    Image,
    Text,
    useToast
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { pageRoutes, adminRoutePrefix } from '@/routes';

import useDeleteLoyaltyProgramMutation from '@/hooks/mutations/useDeleteLoyaltyProgramMutation';
import { OTP_TIME_INTERVALS } from '@/utils/globalConstants';

import alertGif from '../../../assets/gif/alert.gif';
import ConfirmActionOTPInput from '@/components/ConfirmActionOTPInput/ConfirmActionOTPInput';


interface WarningDialogDeleteLoyaltyProgramProps {
    isOpen: boolean
    onConfirm: React.MouseEventHandler,
    onClose: Function,
}
const WarningDialogDeleteLoyaltyProgram = (props: WarningDialogDeleteLoyaltyProgramProps) => {
    const { isOpen, onConfirm, onClose } = props;
    const cancelRef = React.useRef()
    
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

    const toast = useToast()
    const navigate = useNavigate();

    const mutation = useDeleteLoyaltyProgramMutation({
        onSuccess: () => {
            toast({
                title: "Loyalty Program deleted successfully!",
                description: "Now you can start a new loyalty program from scratch",
                position: 'top-right',
                status: "success",
                duration: 5000,
                isClosable: true
            });
            onClose();

            // redirect to loyalty program page
            setTimeout(() => window.location.reload(), 3000);
        },
        onError: (error: any) => {
            toast({
                title: "Error occurred while deleting loyalty program",
                description: error.data?.detail,
                position: 'top-right',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            onClose();
        } 
    })

    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={() => {
                onClose();
                setIsOpenConfirmation(false);
            }}
            isOpen={isOpen}
            isCentered >
            <AlertDialogOverlay />

            <AlertDialogContent>
            {/* <AlertDialogHeader>Discard Changes?</AlertDialogHeader> */}
            <AlertDialogCloseButton />
            <AlertDialogBody>
                <Image
                    src={alertGif}
                    alt="Warning GIF"
                    boxSize={{base: "100px"}}
                    mx="auto"
                />
                <Text fontSize="lg" textAlign="center">Deleting <b>Loyalty Program</b>?</Text>
                <Text fontSize="md" textAlign="center" mb="1rem"><b>Please read the following before confirming!</b></Text>

                <Text fontSize="md" textAlign="center" mb="1rem">
                    Once a loyalty program is deleted all your existing customers in the program will be removed, 
                    and they won't recieve coupons anymore under the current loyalty program
                </Text>
                <Text fontSize="md" textAlign="center" mb="1rem">Do you still wish to delete <b>Loyalty program</b>?</Text>
            </AlertDialogBody>
            <AlertDialogFooter
                justifyContent="space-between">
                <Button 
                    ref={cancelRef}  
                    variant="primaryBlue"
                    onClick={e => {
                        onClose();
                        setIsOpenConfirmation(false);
                    }}>
                    No, get me out of here
                </Button>
                <Button 
                    ml={3} 
                    variant="primaryRed"
                    onClick={e => {
                        onConfirm(e);
                        setIsOpenConfirmation(true);
                    }} >
                    Yes, I want to delete
                </Button>
            </AlertDialogFooter>
            
            {isOpenConfirmation && 
                <Flex
                    id="confirmactionotp"
                    flexDir="column"
                    justifyContent="flex-end"
                    m="1rem"
                    borderRadius="sm"
                    borderWidth="1px"
                    borderStyle="dotted">
                    <ConfirmActionOTPInput 
                        isOpen={isOpenConfirmation} 
                        timerSeconds={OTP_TIME_INTERVALS}
                        submitButtonText="Verify OTP and Delete"
                        onSuccess={(data: any) => {
                            mutation.mutate();
                        }}
                        onSendOTPError={(error: any) => {
                            if ("detail" in error.data) {
                                toast({
                                    title: 'Error occurred while sending OTP',
                                    description: error.data.detail,
                                    position: 'top-right',
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                            }
                        }}
                        onVerifyOTPError={(error: any) => {
                            if (error.response) {
                                let response = error.response;
                                if ("non_field_errors" in response.data) {
                                    toast({
                                        title: 'Error occurred while verifying OTP',
                                        description: response.data["non_field_errors"][0],
                                        position: 'top-right',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                }

                                if ("detail" in response.data) {
                                    toast({
                                        title: 'Error occurred while verifying OTP',
                                        description: response.data["detail"],
                                        position: 'top-right',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                }
                            }
                        }} />
                </Flex>
            }
            
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default WarningDialogDeleteLoyaltyProgram;