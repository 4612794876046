import React from "react";

import {
    Box
} from "@chakra-ui/react";


interface StepProps {
    children: React.ReactNode,
    className: string,
    validateFn: React.CallableFunction,
}

const Step = (props: StepProps) => {

    return (
        <Box className={"step " + props.className}>
            {props.children}
        </Box>
    )
}

export default Step;