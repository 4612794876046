import { useEffect, useState } from "react";

import { 
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    Link,
    Skeleton,
    Text,
} from "@chakra-ui/react";
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";

import useTimer from "@/hooks/useTimer";
import usePolling from "@/hooks/usePolling";
import { CREDIT_ORDER_TRANSACTION_DETAIL_URL } from "@/utils/endpoints";

import Card from "@/components/Card/Card";
import CardBody from "@/components/Card/CardBody";
import AuthNewNavbar from "@/components/Navbars/AuthNewNavbar";


interface CreditsPaymentOrderStatusProps {
    redirectText?: string,
    redirectPath: string,
}

const CreditsPaymentOrderStatus = (props: CreditsPaymentOrderStatusProps) => {
    const {
        redirectText,
        redirectPath,
    } = props;

    const [orderStatus, setOrderStatus] = useState("ACTIVE");
    const [paymentStatus, setPaymentStatus] = useState("PENDING");
    const [creditsAdded, setCreditsAdded] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const [params] = useSearchParams();

    const [query, pollingIntervalId] = usePolling(
                                        `${CREDIT_ORDER_TRANSACTION_DETAIL_URL(params.get("order_id") || "", "True")}`, 
                                        1500);

    const [counter, resetTimer] = useTimer(() => {
        navigate(redirectPath);
    }, 10, query ? !isLoading && !query?.isError : false);

    // useEffect to stop long polling
    useEffect(() => {
        if (query && !query.isFetching) {
            if (query.isError) {
                clearInterval(pollingIntervalId);
                // if get status_code == 308, redirect to credits page
                // this is in case when user pressed the cancel button and 
                // no payment is made for the order
                if (query.error.response.status === 308) {
                    navigate("/admin/credits");
                    return;
                }
                alert(query.error.message);
                return;
            } else if (query.isSuccess) {
                let cf_order_status = query.data.cf_order_status;
                let cf_payment_status = query.data.cf_payment_status;
                let credits_added = query.data.credits_added;
                if (cf_order_status !== 'ACTIVE') {
                    setOrderStatus(cf_order_status);
                    setPaymentStatus(cf_payment_status);
                    setCreditsAdded(credits_added);

                    // set loading to false
                    setIsLoading(false);
    
                    clearInterval(pollingIntervalId);
                }
            }
        }
    }, [query]);

    const getPaymentStatusText = () => {
        if (orderStatus === "PAID") {
            return <Text fontSize="xl" mt="3px" as="b" color="green.500">Payment Successfull</Text>
        } else {
            return <Text fontSize="xl" mt="3px" as="b" color="red.500">Payment Failed</Text>
        }
    }

    const getPaymentStatusIcon = () => {
        if (orderStatus === "PAID") {
            return <Icon as={FaCircleCheck} color="green.500" w="25px" h="25px" />
        } else {
            return <Icon as={FaCircleXmark} color="red.500" w="25px" h="25px" />
        }
    }

    const getPaymentDetailText = () => {
        if (orderStatus === "PAID") {
            return <Text textAlign="center" fontSize="lg" mt="3px" mb="1rem" color="gray.textPrimary">
                        Thank you for purchasing credits. We've added <b>{creditsAdded}</b> credits in your account.
                    </Text>
        } else {
            return <Text textAlign="center" fontSize="lg" mt="3px" mb="1rem" color="gray.textPrimary">
                        Sorry we couldn't process your payment. Please try again.
                    </Text>
        }
    }

    return (
        <Grid
            templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={6}
            justifyContent="center"
        >
            <GridItem colSpan={{ md: 1 }} />
            <GridItem colSpan={{ sm: 1, md: 1 }}>
            <Card
                id="paymentorderstatus">
                <CardBody
                    id="paymentorderstatusbody">
                    <Flex
                        w="100%"
                        flexDir="column"
                        justifyItems="center">
                        
                        {/* Logo */}
                        <AuthNewNavbar 
                            logoText="Inback"
                            w="100%"
                            justify="center"
                            mb="1rem" />

                        {/* payment successfull */}
                        <Skeleton mb="1rem" isLoaded={!isLoading}>
                            <HStack display={{ sm: "flex", lg: "flex" }} w="100%" justify="center">
                                {getPaymentStatusIcon()}
                                {getPaymentStatusText()}
                            </HStack>
                        </Skeleton>

                        <Skeleton isLoaded={!isLoading}>
                            <motion.div
                                initial={{ y: 20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                >
                                {getPaymentDetailText()}

                                <Text textAlign="center" fontSize="sm" color="gray.textPrimary">
                                    {redirectText} in {counter} seconds.<br />
                                    <Link onClick={() => navigate(redirectPath)}>
                                        Click here to redirect manually
                                    </Link>
                                </Text>
                            </motion.div>
                        </Skeleton>
                    </Flex>
                </CardBody>
            </Card>
            </GridItem>
            <GridItem colSpan={{ md: 1 }} />
        </Grid>
    )
}

export default CreditsPaymentOrderStatus;