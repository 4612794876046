import React, { useState } from "react";

import { Box } from "@chakra-ui/react";
// import { animated, useTransition } from "react-spring";

interface MultiStepFormProps {
    className: string,
    currentStep: number,
    totalSteps?: number,
    children: React.children,
}

// step component
export const Step = (props: any) => {
    return (
        <Box>{props.children}</Box>
    )
}

const MultiStepForm = (props: MultiStepFormProps) => {
    const {
        className,
        currentStep,
        totalSteps
    } = props;
    // const StepComponent = props.children[currentStep - 1];
//   const transitions = useTransition(step, {
//     from: { opacity: 0, transform: "translate3d(100%,0,0)" },
//     enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
//     leave: { opacity: 0, transform: "translate3d(-50%,0,0)" }
//   });

  return (
    <Box className={className} width={{"base": "100%"}}>
      {props.children[currentStep - 1]}
    </Box>
  );
};

export default MultiStepForm;
