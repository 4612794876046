import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { 
    Avatar,
    Alert,
    AlertIcon,
    Heading,
    Image as ImgChakra,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex,

    // for editor form
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Tooltip,
    Divider,
    Textarea,
    Box,
    Checkbox,
    useToast
} from "@chakra-ui/react";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import flatpickr from "flatpickr";

import { MouseEventHandler } from "react";
import {
    FaCircleInfo,
    FaCalendarDays,
    FaUpload,
    FaArrowLeftLong
} from "react-icons/fa6";

import axios from "axios";

import { REWARD_CARD_URL } from "@/utils/endpoints";
import { adminRoutePrefix, pageRoutes } from "@/routes";

import { 
    CURRENCY_HTML_SYMBOL, 
    PreviewCardBannerWidth, 
    PreviewCardBannerHeight,
    DEFAULT_BANNER_IMAGE,
    DEFAULT_LOGO_IMAGE
} from "@/utils/globalConstants";

import useFetchBusinessProfile from "@/hooks/useFetchBusinessProfile";
import { useRetrieveRewardCard } from "@/hooks";
import useCreateCouponMutation from "@/hooks/mutations/useCreateCouponMutation";
import useUpdateCouponMutation from "@/hooks/mutations/useUpdateCouponMutation";

import Card from "@/components/Card/Card";
import CardHeader from "@/components/Card/CardHeader";
import CardBody from "@/components/Card/CardBody";
import ColorInput from "@/components/Input/ColorInput";
import PreviewRewardCard from "@/components/PreviewRewardCard/PreviewRewardCard";
import AddEditModalFormNav from "../../AddEditModalFormNav";
import MultiStepForm, { Step } from "../../MultiStepForm";
import InfoBlock from "./InfoBlock";


const getNextDate = (dateString: string | null = null) => {
    var currentDate = new Date();
    if (dateString) {
        currentDate = new Date(dateString);
    }
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
}


const CustomToolTip = (props: any) => (
    <Tooltip hasArrow bg='gray.300' color='black' {...props}>
      {props.icon ? <Icon /> : <Icon as={props.icon} />}
    </Tooltip>
  )


interface AddOrEditRewardCardCompProps {
    isEditing: boolean
}

const AddOrEditRewardCardComp = (props: AddOrEditRewardCardCompProps) => {
    const navigate = useNavigate();
    let params = useParams();

    const toast = useToast();

    const {
        isEditing=false //states whether we're editing an existing coupon or creating a new one
    } = props;

    const [rewardCard, setRewardCard] = useState({
        id: "", // used when updating a coupon
        name: "New reward coupon",
        purposeType: "",
        verificationId: "908105",
        bannerImage: DEFAULT_BANNER_IMAGE,
        bannerImageName: '',
        bannerImageFile: new FormData(),
        isDefaultBanner: true,
        businessLogo: DEFAULT_LOGO_IMAGE,
        businessLogoFile: new FormData(),
        businessLogoName: '',
        useDefaultBusinessLogo: true,
        themeColor: "#ffffff",
        fontColor: "#000000",
        offerText: "Buy 1 get 1 free",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod eligendi autem voluptate omnis molestiae cupiditate accusamus ducimus praesentium numquam saepe error, voluptas aut in consequuntur explicabo quisquam dolores voluptates unde!",
        cardType: 1,
        amount: 500,
        percent: 25,
        tnc: '*Minimum purchase required\n*Multiple coupons cannot be merged',
        minPurchaseAmount: 1999,
        validityDurationType: 0,
        validityDays: 25,
        validityStartDate: new Date(),
        validityEndDate: getNextDate(),
    });

    const [isCreateEditMode, setIsCreateEditMode] = useState(false);
    const [hasCurrentStepError, setHasCurrentStepError] = useState(false);

    const [uiState, setUiState] = useState({
        isFormSubmitting: false,
        isCardLoading: false,
        generalError: null,
        generalSuccessMessage: null,
        fieldErrors: {
            nameError: null,
            purposeTypeError: null,
            cardTypeError: null,
            amountError: null,
            percentError: null,
            offerTextError: null,
            bannerImageError: null,
            businessLogoError: null,
            minPurchaseAmountError: null,
            validityDurationTypeError: null,
            validityDaysError: null,
            validityStartDateError: null,
            validityEndDateError: null,
            tncError: null,
        }
    });

    const {...fieldErrors} = uiState.fieldErrors;

    const steps = [
        {
            step: 1,
            name: "Name & Purpose",
            fieldErrors: ["nameError", "purposeTypeError"],
            fields: ["name", "purposeType"],
            required: true,
        }, 
        {
            step: 2,
            name: "Theme",
            fieldErrors: ["bannerImageError"]
        },
        {
            step: 3,
            name: "Discount",
            fieldErrors: ["cardTypeError", "amountError", "percentError", "offerTextError", "minPurchaseAmountError"]
        },
        {
            step: 4,
            name: "Validity",
            fieldErrors: [
                "validityDurationTypeError", 
                "validityDaysError",
                "validityStartDateError",
                "validityEndDateError"]
        },
        {
            step: 5,
            name: "T&C",
            fieldErrors: ["tncError"]
        }
    ]

    const [formNavState, setFormNavState] = useState({
        currentStep: 1,
        totalSteps: 5
    });


    //////////////////////////////////////////////////////////////////////////////
    ///////////// query and mutations /////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    const getRewardCardIdParam = () => {
        if ("rewardCardId" in params) {
            return params["rewardCardId"];
        }
        return "";
    }

    const businessProfileQuery = useFetchBusinessProfile(true, {
        onError: (error: any) => {
            throw "Something went wrong while trying to fetch business profile";
        },
        onSuccess: (data: any) => {
            if (rewardCard.useDefaultBusinessLogo) {
                setRewardCard({...rewardCard, businessLogo: data.logo});
            }
        }
    });

    const rewardCardQuery =  useRetrieveRewardCard(false, getRewardCardIdParam(), {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
            throw "Something went wrong while trying to reward card";
        },
        onSuccess: (data: any) => {
            // update rewardCard state
            setRewardCard({
                ...rewardCard,
                id: data.id, // used when updating a coupon
                name: data.name,
                purposeType: data.purpose_type,
                verificationId: "908105",
                bannerImage: data.banner_image,
                bannerImageName: '',
                bannerImageFile: new FormData(),
                isDefaultBanner: true,
                businessLogo: data.business_logo,
                businessLogoFile: new FormData(),
                businessLogoName: '',
                useDefaultBusinessLogo: data.use_default_business_logo,
                themeColor: data.theme_color,
                fontColor: data.font_color,
                cardType: data.card_type,
                amount: data.amount,
                percent: data.percent,
                offerText: data.offer_text || "Offer text",
                tnc: data.tnc,
                minPurchaseAmount: data.min_purchase_amount,
                validityDurationType: data.validity_duration_type,
                validityDays: data.validity_days,
                validityStartDate: new Date(data.validity_start_date),
                validityEndDate: new Date(data.validity_end_date), 
            });
        } 
    });

    const createCouponMutation = useCreateCouponMutation({
        onError: (error: any) => {
            const response = error.response;
            let errors: any = {};
            if (response) {
                if ("detail" in response.data) {
                    toast({
                        title: 'Error occurred!',
                        description: response.data["detail"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if ("non_field_error" in response.data) {
                    toast({
                        title: 'Error occurred!',
                        description: response.data["non_field_error"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                
                if ("name" in response.data) {
                    errors["nameError"] = response.data["name"][0];
                }
                if ("purpose_type" in response.data) {
                    errors["purposeTypeError"] = response.data["purpose_type"][0];
                }
                if ("business_logo" in response.data) {
                    errors["businessLogoError"] = response.data["business_logo"][0];
                }
                if ("card_type" in response.data) {
                    errors["cardTypeError"] = response.data["card_type"][0];
                }
                if ("amount" in response.data) {
                    errors["amountError"] = response.data["amount"][0];
                }
                if ("percent" in response.data) {
                    errors["percentError"] = response.data["percent"][0];
                }
                if ("offer_text" in response.data) {
                    errors["offerTextError"] = response.data["offer_text"][0];
                }
                if ("banner_image" in response.data) {
                    errors["bannerImageError"] = response.data["banner_image"][0];
                }
                if ("min_purchase_amount" in response.data) {
                    errors["minPurchaseAmountError"] = response.data["min_purchase_amount"][0];
                }
                if ("validity_duration_type" in response.data) {
                    errors["validityDurationTypeError"] = response.data["validity_duration_type"][0];
                }
                if ("validity_days" in response.data) {
                    errors["validityDaysError"] = response.data["validity_days"][0];
                }
                if ("validity_start_date" in response.data) {
                    errors["validityStartDateError"] = response.data["validity_start_date"][0];
                }
                if ("validity_end_date" in response.data) {
                    errors["validityEndDateError"] = response.data["validity_end_date"][0];
                }
                if ("tnc" in response.data) {
                    errors["tncError"] = response.data["tnc"][0];
                }

                if (errors) {
                    setUiState({...uiState, fieldErrors: {...uiState.fieldErrors, ...errors}});
                }

            }else {
                toast({
                    title: 'Internal Server Error occurred!',
                    description: "500: Internal server error occurred while creating coupon",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });

                // throw "500: Internal server error occurred while creating coupon";
            }
        },
        onSuccess: (data: any) => {
            toast({
                title: 'Coupon created sucessfully!',
                description: "You can use your new coupon in loyalty program or directly",
                position: 'top-right',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            // redirect to coupons list page
            setTimeout(() => navigate(adminRoutePrefix + pageRoutes.rewardCard), 1000);
        }
    });

    const updateCouponMutation = useUpdateCouponMutation(getRewardCardIdParam(), {
        onError: (error: any) => {
            const response = error.response;
            let errors: any = {};
            if (response) {
                if ("detail" in response.data) {
                    toast({
                        title: 'Error occurred!',
                        description: response.data["detail"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if ("non_field_error" in response.data) {
                    toast({
                        title: 'Error occurred!',
                        description: response.data["non_field_error"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                
                if ("name" in response.data) {
                    errors["nameError"] = response.data["name"][0];
                }
                if ("purpose_type" in response.data) {
                    errors["purposeTypeError"] = response.data["purpose_type"][0];
                }
                if ("business_logo" in response.data) {
                    errors["businessLogoError"] = response.data["business_logo"][0];
                }
                if ("card_type" in response.data) {
                    errors["cardTypeError"] = response.data["card_type"][0];
                }
                if ("amount" in response.data) {
                    errors["amountError"] = response.data["amount"][0];
                }
                if ("percent" in response.data) {
                    errors["percentError"] = response.data["percent"][0];
                }
                if ("offer_text" in response.data) {
                    errors["offerTextError"] = response.data["offer_text"][0];
                }
                if ("banner_image" in response.data) {
                    errors["bannerImageError"] = response.data["banner_image"][0];
                }
                if ("min_purchase_amount" in response.data) {
                    errors["minPurchaseAmountError"] = response.data["min_purchase_amount"][0];
                }
                if ("validity_duration_type" in response.data) {
                    errors["validityDurationTypeError"] = response.data["validity_duration_type"][0];
                }
                if ("validity_days" in response.data) {
                    errors["validityDaysError"] = response.data["validity_days"][0];
                }
                if ("validity_start_date" in response.data) {
                    errors["validityStartDateError"] = response.data["validity_start_date"][0];
                }
                if ("validity_end_date" in response.data) {
                    errors["validityEndDateError"] = response.data["validity_end_date"][0];
                }
                if ("tnc" in response.data) {
                    errors["tncError"] = response.data["tnc"][0];
                }

                if (errors) {
                    setUiState({...uiState, fieldErrors: {...uiState.fieldErrors, ...errors}});
                }

            }else {
                toast({
                    title: 'Internal Server Error occurred!',
                    description: "500: Internal server error occurred while creating coupon",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                // throw "500: Internal server error occurred while creating coupon";
            }
        },
        onSuccess: (data: any) => {
            toast({
                title: 'Coupon updated sucessfully!',
                // description: "You can use your new coupon in loyalty program or directly",
                position: 'top-right',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    });
    //////////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////
    ///////////// useEffects /////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        hasCurrentStepFieldError();
    }, [uiState.fieldErrors]);

    useEffect(() => {
        // validate purposeTypeField
        if (typeof rewardCard.purposeType != 'number' && (rewardCard.purposeType == null || rewardCard.purposeType == '')) {
            setFieldError("purposeTypeError", "*This field is required");
        }else {
            setFieldError("purposeTypeError", null);
        }
    }, [rewardCard.purposeType]);

    useEffect(() => {
        const rewardCardId = getRewardCardIdParam();
        if (rewardCardId && rewardCardId.length) {
            rewardCardQuery.refetch();
        }
    }, [getRewardCardIdParam()]);

   //////////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////
    ///////////// helper functions /////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    const setFieldError = (fieldname: string, error: string | null) => {
        if (Object.keys(uiState.fieldErrors).includes(fieldname)) {
            let fieldErrorsCopy = {...uiState.fieldErrors};
            fieldErrorsCopy = {...fieldErrorsCopy, [fieldname]: error};
            setUiState({...uiState, fieldErrors: fieldErrorsCopy});
        }
    }

    const clearAllErrors = () => {
        Object.keys(fieldErrors).forEach(errorKey => {
            fieldErrors[errorKey] = null;
        });
        setUiState({...uiState, generalError: null});
    }

    const hasCurrentStepFieldError = () => {
        for (let errorKey of Object.keys(fieldErrors)) {
            if (uiState.fieldErrors[errorKey] != null) {
                const currentStepObject = steps.filter(step => step.fieldErrors.includes(errorKey));
                if (currentStepObject.length){
                    setHasCurrentStepError(true);
                    setFormNavState({...formNavState, currentStep: currentStepObject[0].step});
                }
                return;
            }
        }

        // if (uiState.generalError != null && uiState.generalError != '') {
        //     setHasCurrentStepError(true);
        // }

        setHasCurrentStepError(false);
    }

    // getter functions
    const getRewardCardName = () => {
        let name = rewardCard.name;
        if (name.length <= 0) {
            return "New reward coupon";
        }
        return name;
    }

    /*
    * returns the step number that has first error
    * Example:
    * if we have, nameError on step 1, and bannerError on step 2
    * then this function return 1 as nameError is first error on step 1
    */
    const getFirstFeildErrorStep = () => {
        for (let errorKey of Object.keys(fieldErrors)) {
            if (fieldErrors[errorKey] != null) {
                
                for (let step of steps) {
                    if (step.fieldErrors.includes(errorKey)) {
                        return step.step;
                    }
                }
            }
        }
        return formNavState.currentStep;
    }

    const hasErrors = () => {
        for (let errorKey of Object.keys(fieldErrors)) {
            if (fieldErrors[errorKey] != null) {
                return true;
            }
        }

        if (uiState.generalError != null) {
            return true;
        }

        return false;
    }

    const isCreateEditButtonDisabled = () => {
        if (hasErrors()) {
            return true;
        }
        if (uiState.isFormSubmitting) {
            return true;
        }
        return false;
    }

    function formatUTCDate(date) {
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
      
        // UTC date does not have a timezone offset, it is always +0000
        const timezone = '+0000';
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${timezone}`;
      }
    
    const getRewardCardMutationData = () => {
        let data: any = {
            name: rewardCard.name,
            purpose_type: rewardCard.purposeType,
            business: businessProfileQuery?.data?.id || null,
            created_by: businessProfileQuery?.data?.user?.id || null,
            card_type: rewardCard.cardType,
            use_default_business_logo: rewardCard.useDefaultBusinessLogo,
            business_logo: rewardCard.businessLogoFile.get("business_logo"),
            banner_image: rewardCard.bannerImageFile.get("banner_image"),
            font_color: rewardCard.fontColor,
            theme_color: rewardCard.themeColor,
            min_purchase_amount: rewardCard.minPurchaseAmount,
            validity_duration_type: rewardCard.validityDurationType,
            validity_days: rewardCard.validityDays,
            validity_start_date: rewardCard.validityStartDate,
            validity_end_date: rewardCard.validityEndDate,
            tnc: rewardCard.tnc,
        }

        const validityStartDateString = formatUTCDate(data.validity_start_date);
        const validityEndDateString = formatUTCDate(data.validity_end_date);

        data.validity_start_date = validityStartDateString;
        data.validity_end_date = validityEndDateString;

        if (rewardCard.cardType === 0) { // percent discount
            data["percent"] = rewardCard.percent;
        } else if (rewardCard.cardType === 1) { // amount discount
            data["amount"] = rewardCard.amount;
        } else if (rewardCard.cardType === 2) { // offer text
            data["offer_text"] = rewardCard.offerText;
        }

        return data;
    }
    //////////////////////////////////////////////////////////////////////////////


   //////////////////////////////////////////////////////////////////////////////
    ///////////// handler functions /////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    const handleNameChange = (nameValue: string) => {
        if (nameValue == null || nameValue == '') {
            setFieldError("nameError", "*This field is required");
        }else {
            setFieldError("nameError", null);
        }
        setRewardCard({...rewardCard, name: nameValue});
    }

    const handlePurposeTypeChange = (purposeType: any) => {
        if (purposeType == null || purposeType == '') {
            setFieldError("purposeTypeError", "*This field is required");
        }else {
            setFieldError("purposeTypeError", null);
        }
        setRewardCard({...rewardCard, purposeType: purposeType});
    }

    const handleRewardCardTypeChange = (cardType: number) => {
        cardType = Number(cardType);
        setRewardCard({...rewardCard, cardType: cardType});
    }

    const handleDiscountAmountChange = (amount: number) => {
        if (amount == null || amount <= 0) {
            setFieldError("amountError", "*Amount cannot be zero or less");
        }
        else {
            setFieldError("amountError", null);
        }
        setRewardCard({...rewardCard, amount: amount});
    }

    const handleDiscountPercentChange = (percent: number) => {
        if (percent == null || percent <= 0) {
            setFieldError("percentError", "*Percentage of amount cannot be zero or less");
        }else {
            setFieldError("percentError", null);
        }
        setRewardCard({...rewardCard, percent: percent});
    }

    const handleDiscountOfferTextChange = (offerText: string) => {
        setRewardCard({...rewardCard, offerText: offerText});
    }

    const handleMinPurchaseAmountChange = (amount: number) => {
        if (amount == null || amount <= 0) {
            setFieldError("minPurchaseAmountError", "*Min purchase amount cannot be zero or less");
        }else {
            setFieldError("minPurchaseAmountError", null);
        }
        setRewardCard({...rewardCard, minPurchaseAmount: amount});
    }

    const handleThemeColorChange = (color) => {
        setRewardCard({...rewardCard, themeColor: color.hex});
    }

    const handleFontColorChange = (color) => {
        setRewardCard({...rewardCard, fontColor: color.hex});
    }

    const handleBannerImageChange: ChangeEventHandler = (e: ChangeEvent )=> {
        e.preventDefault();
        let imageFile = e.target.files[0];
        if (imageFile) {
            const localImageUrl = URL.createObjectURL(imageFile);
            if ((imageFile.size /1024 /1024) > 10.0) {
                setFieldError("bannerImageError", "*Image file size cannot exceed 10 MB");
            } else {
                setFieldError("bannerImageError", null);
            }
            setRewardCard({
                ...rewardCard, 
                bannerImage: localImageUrl,
                bannerImageName: imageFile.name,
            });
            rewardCard.bannerImageFile.set("banner_image", imageFile);
        }
    }

    const handleBusinessLogoChange: ChangeEventHandler = (e: ChangeEvent )=> {
        e.preventDefault();
        let imageFile = e.target.files[0];
        if (imageFile) {
            const localImageUrl = URL.createObjectURL(imageFile);
            if ((imageFile.size /1024 /1024) > 1.0) {
                setFieldError("businessLogoError", "*Image file size cannot exceed 1 MB");
            } else {
                setFieldError("businessLogoError", null);
            }
            setRewardCard({
                ...rewardCard, 
                businessLogo: localImageUrl,
                businessLogoName: imageFile.name,
            });
            rewardCard.businessLogoFile.set("business_logo", imageFile);
        }
    }

    const handleValidationTypeChange = (validationType) => {
        setRewardCard({...rewardCard, validityDurationType: Number(validationType)});
    }

    const handleValidationDaysChange = (days: null | number) => {
        if (days == null || days <= 0) {
            setFieldError("validityDaysError", "*Number of days of coupon validity cannot be 0 or less");
        }else {
            setFieldError("validityDaysError", null);
        }
        setRewardCard({...rewardCard, validityDays: days});
    }

    const handleValidityStartDateChange = (date) => {
        setRewardCard({...rewardCard, validityStartDate: date[0]});
    }

    const handleValidityEndDateChange = (date) => {
        setRewardCard({...rewardCard, validityEndDate: date[0]});
    }

    const handleTermsAndCondChange = (tnc) => {
        if (tnc == null || tnc == '') {
            setFieldError("tncError", "*This field cannot be empty");
        }else {
            setFieldError("tncError", null);
        }
        setRewardCard({...rewardCard, tnc: tnc});
    }

    const handleCreateCouponOnClick = (event: MouseEvent) => {
        createCouponMutation.mutate(getRewardCardMutationData());
    };

    const handleUpdateCouponOnClick = (event: MouseEvent) => {
        updateCouponMutation.mutate(getRewardCardMutationData());
    };

    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
            <Card colorMode="white">
                <CardHeader className="rewardprog_card_header" mb={{ base: "10px" }}>
                    <Flex
                        className="rewardprog_header_details"
                        flexDirection={{"base": "column"}}
                        // align="center"
                        // minHeight="60px"
                        w="100%">

                        {/* Link to go back to coupons list */}
                        <Link to={adminRoutePrefix + pageRoutes.rewardCard}>
                            <Text color="gray.textPrimary">
                                <b><Icon as={FaArrowLeftLong} /> Back to Coupons list</b>
                            </Text>
                        </Link>

                        <Flex
                            className="rewardprog_header_text"
                            flexDir="row"
                            justify="space-between">
                            <Text as="b" fontSize={{"base": "2xl"}}>{getRewardCardName()}</Text>
                            
                            {/* This button won't be visible on the final step of form */}
                            {isEditing && formNavState.currentStep != 5 &&
                                <Button 
                                    className="rewardprog_save_btn"
                                    variant="primaryBlue"
                                    w="20%"
                                    mb="1rem"
                                    onClick={handleUpdateCouponOnClick}>
                                    Save
                                </Button>
                            }
                        </Flex>
                    </Flex>
                </CardHeader>

                <CardBody
                    p={{ base: "10px" }} 
                    bg="gray.50"
                    flexDirection="column">
                    <Flex
                        id="rewardcard_editor">
                        
                        <Flex
                            id="rewardcard_editor_preview"
                            flex={{"base": 1}}
                            justify="center">
                            
                            {/* Preview card */}
                            <Flex
                                flexDirection="column">
                                <Text color="gray.400" fontSize="sm">Coupon preview</Text>
                                <Flex
                                    color="blue.dark"
                                    fontSize="sm"
                                    mb={{"base": "2px"}}>
                                    <FaCircleInfo style={{"alignSelf": "center", "marginRight": "1px"}} />
                                    <Text>Preview may have default values</Text>
                                </Flex>

                                <PreviewRewardCard
                                    rewardCard={rewardCard}
                                    isCreateEditMode={isCreateEditMode} />
                            </Flex>
                            {/* Preview card ends */}
                        </Flex>

                        <Flex
                            className="rewardcard_editor_form"
                            flex={{"base": 1}}
                            flexDirection={{"base": "column"}}
                            height={16*2.2 + 'rem'}
                            overflow={{"base": "hidden"}}>
                            
                            <AddEditModalFormNav 
                                steps={steps}
                                currentStep={formNavState.currentStep}
                                totalSteps={formNavState.totalSteps}
                                hasCurrentStepError={hasCurrentStepError}
                                onClickNext={e => {
                                    let currentStep = formNavState.currentStep;
                                    let totalSteps = formNavState.totalSteps;
                                    currentStep = Math.min(currentStep + 1, totalSteps);
                                    setFormNavState({...formNavState, currentStep: currentStep});
                                }}
                                onClickBack={e => {
                                    let currentStep = formNavState.currentStep;
                                    currentStep = Math.max(currentStep - 1, 0);
                                    setFormNavState({...formNavState, currentStep: currentStep});
                                }}
                                onChangeJumpTo={e => {
                                    setFormNavState({...formNavState, currentStep: e.target.value});
                                }}
                            />

                            <Flex
                                className="multistep-form-wrapper"
                                w={{"base": "100%"}}
                                p={{"base": "1rem"}}
                                overflowX="auto">

                                <MultiStepForm
                                    className="multistep-form"
                                    currentStep={formNavState.currentStep}>
                                    <Step>
                                        <Card h={{"base": "fit-content"}} mb="2rem">
                                            <FormControl mb="1rem">
                                                <FormLabel htmlFor='id_pc_name'>Name of coupon*</FormLabel>
                                                <Input 
                                                    id='id_pc_name' 
                                                    type='text'
                                                    value={rewardCard.name}
                                                    isInvalid={Boolean(fieldErrors.nameError)}
                                                    onChange={e => {
                                                        handleNameChange(e.target.value);
                                                    }} />
                                                    {fieldErrors.nameError && 
                                                        <Text
                                                            color="red.300" 
                                                            as="small">{fieldErrors.nameError}</Text>}
                                            </FormControl>
                                            
                                            
                                            <FormControl>
                                                <FormLabel htmlFor='id_pc_purposetype'>
                                                    Purpose of coupon*
                                                </FormLabel>
                                                <InfoBlock 
                                                    description="You cannot update purpose of a coupon once selected.
                                                                Coupons created with Loyalty purpose will be used for Loyalty program, and
                                                                Coupons created with Direct purpose will be used as Direct coupon on dashboard."/>
                                                <Select 
                                                    id="id_pc_purposetype"
                                                    value={rewardCard.purposeType}
                                                    isDisabled={isEditing}
                                                    isInvalid={Boolean(fieldErrors.purposeTypeError)}
                                                    onChange={e => {
                                                        handlePurposeTypeChange(e.target.value);
                                                    }}>
                                                    <option value="">Select purpose</option>
                                                    <option value="0">To use for Loyalty program</option>
                                                    <option value="1">To use as Direct coupon</option>
                                                </Select>
                                                {fieldErrors.purposeTypeError && 
                                                    <Text
                                                        color="red.300" 
                                                        as="small">{fieldErrors.purposeTypeError}</Text>}
                                            </FormControl>
                                        </Card>
                                    </Step>

                                    {/* Theme form */}
                                    <Step>
                                        <Flex
                                            flexDirection={{"base": "column"}}
                                            mb={{"base": "3rem"}}>

                                            <Text as="b" color="gray.400" mb="1rem">Theme</Text>
                                            <Flex 
                                                justify={{"base": "start"}}
                                                gap={{"base": "1rem"}}
                                                mb={{"base": "1rem"}}>
                                                <ColorInput 
                                                    label="Theme color"
                                                    color={rewardCard.themeColor}
                                                    onChangeColor={handleThemeColorChange} />

                                                <ColorInput 
                                                    label="Font color"
                                                    color={rewardCard.fontColor}
                                                    onChangeColor={handleFontColorChange} />
                                            </Flex>

                                            <Card 
                                                h={{"base": "fit-content"}} 
                                                mb="1rem"
                                                className="pc_businesslogo_form">
                                                <FormControl>
                                                    <FormLabel htmlFor='id_pc_businesslogo'>
                                                        Business Logo
                                                    </FormLabel>

                                                    <Flex
                                                        className="pc_businesslogo_info"
                                                        flexDir={{base: "row"}}
                                                        justify="start"
                                                        mb="1rem">
                                                        <Text
                                                            color="blue.dark"
                                                            fontSize={{"base": "1xl"}}>
                                                            <Icon as={FaCircleInfo} />
                                                        </Text>

                                                        <Box 
                                                            className="pc_businesslogo_info_text"
                                                            color="blue.dark" 
                                                            fontSize={{"base": "sm"}}
                                                            ml="0.5rem">
                                                            <Text>Logo looks best with resolution 560px X 560px</Text>
                                                            {/* <br/> */}
                                                            <Text>Logo should be 1MB or less</Text>
                                                        </Box>
                                                    </Flex>
                                                    
                                                    <Flex
                                                        className="pc_businesslogo_upload"
                                                        flexDir="column">
                                                        
                                                        <Checkbox
                                                            id="id_pc_businesslogo_default_checkbox"
                                                            mb="0.5rem"
                                                            color="gray.textPrimary"
                                                            isDisabled={businessProfileQuery?.data?.logo?.length === 0}
                                                            isChecked={rewardCard.useDefaultBusinessLogo}
                                                            onChange={event => {
                                                                const checked = event.target.checked;
                                                                let useDefaultBusinessLogo = rewardCard.useDefaultBusinessLogo;
                                                                let businessLogo = rewardCard.businessLogo;
                                                                
                                                                if (checked) {
                                                                    useDefaultBusinessLogo = true;
                                                                    if (businessProfileQuery?.data?.logo) {
                                                                        businessLogo = businessProfileQuery.data.logo;
                                                                    }
                                                                }else {
                                                                    useDefaultBusinessLogo = false;
                                                                    businessLogo = "";
                                                                }

                                                                setRewardCard({
                                                                    ...rewardCard, 
                                                                    useDefaultBusinessLogo: useDefaultBusinessLogo, 
                                                                    businessLogo: businessLogo,
                                                                    businessLogoName: ""
                                                                });
                                                            }}>Use default business logo</Checkbox>

                                                        {rewardCard.businessLogoName.length > 0 && 
                                                            <Text color="gray.500" fontSize={{"base": "sm"}} id="businesslogo_filename">
                                                                <Text as="b">File: </Text>
                                                                <Text as="i">
                                                                    {rewardCard.businessLogoName.length > 100 
                                                                        ? rewardCard.businessLogoName.slice(0, 50) + '...' 
                                                                        : rewardCard.businessLogoName}</Text>
                                                            </Text>}
                                                        
                                                        <Button 
                                                            leftIcon={<FaUpload />}
                                                            width="100%"
                                                            borderColor={Boolean(fieldErrors.businessLogoError) ? "red.400" : "gray.700"}
                                                            borderWidth="1px"
                                                            p={{"base": "8px"}}
                                                            isDisabled={rewardCard.useDefaultBusinessLogo}
                                                            onClick={e => {
                                                                const logoInput: HTMLInputElement | null = document.querySelector("#id_pc_businesslogo_input");
                                                                if (logoInput) logoInput.click();
                                                            }}>
                                                            Upload logo
                                                            <Input 
                                                                id="id_pc_businesslogo_input" 
                                                                type="file" 
                                                                accept="image/*"
                                                                onChange={handleBusinessLogoChange}
                                                                hidden />
                                                        </Button>
                                                        {fieldErrors.businessLogoError && 
                                                            <Text
                                                                color="red.300" 
                                                                as="small"
                                                                mb={{"base": "2rem"}}>{fieldErrors.businessLogoError}</Text>}
                                                    </Flex>
                                                    
                                                </FormControl>
                                            </Card>

                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                <FormControl>
                                                    <FormLabel htmlFor='id_pc_bannerimage'>
                                                        Banner image
                                                    </FormLabel>
                                                    <Flex
                                                        className="pc_bannerimage_info"
                                                        flexDir={{base: "row"}}
                                                        justify="start"
                                                        mb="1rem" >
                                                        <Text
                                                            color="blue.dark"
                                                            fontSize={{"base": "1xl"}}>
                                                            <Icon as={FaCircleInfo} />
                                                        </Text>

                                                        <Box 
                                                            className="pc_bannerimage_info_text"
                                                            color="blue.dark" 
                                                            fontSize={{"base": "sm"}}
                                                            ml="0.5rem">
                                                            <Text>Banner looks best with resolution {PreviewCardBannerWidth} X {PreviewCardBannerHeight}.</Text>
                                                            <Text>Image file should be 10MB or less.</Text>
                                                        </Box>
                                                    </Flex>

                                                    {rewardCard.bannerImageName.length > 0 && 
                                                        <Text color="gray.500" fontSize={{"base": "sm"}} id="bannerimage_filename">
                                                            <Text as="b">File: </Text>
                                                            <Text as="i">
                                                                {rewardCard.bannerImageName.length > 100 
                                                                    ? rewardCard.bannerImageName.slice(0, 50) + '...' 
                                                                    : rewardCard.bannerImageName}</Text>
                                                        </Text>}
                                                    
                                                    <Button 
                                                        leftIcon={<FaUpload />}
                                                        width="100%"
                                                        borderColor={Boolean(fieldErrors.bannerImageError) ? "red.400" : "gray.700"}
                                                        borderWidth="1px"
                                                        p={{"base": "8px"}}
                                                        onClick={e => {
                                                            const bannerInput: HTMLInputElement | null = document.querySelector("#id_pc_bannerimage_input");
                                                            if (bannerInput) bannerInput.click();
                                                        }}>
                                                        Upload banner image
                                                        <Input 
                                                            id="id_pc_bannerimage_input" 
                                                            type="file" 
                                                            accept="image/*"
                                                            onChange={handleBannerImageChange}
                                                            hidden />
                                                    </Button>
                                                    {fieldErrors.bannerImageError && 
                                                        <Text
                                                            color="red.300" 
                                                            as="small"
                                                            mb={{"base": "2rem"}}>{fieldErrors.bannerImageError}</Text>}
                                                </FormControl>
                                            </Card>
                                        </Flex>
                                    </Step>
                                    {/* Theme form ends */}
                                    
                                    {/* Discount form */}
                                    <Step>
                                        <Flex
                                            flexDirection={{"base": "column"}}
                                            mb={{"base": "3rem"}}>
                                            <Text as="b" color="gray.400" mb="1rem">Discount</Text>
                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                <FormControl>
                                                    <FormLabel htmlFor='id_pc_discounttype'>Type of coupon</FormLabel>
                                                    <Select 
                                                        id="id_pc_discounttype"
                                                        isInvalid={Boolean(fieldErrors.cardTypeError)}
                                                        value={rewardCard.cardType}
                                                        onChange={e => {
                                                            handleRewardCardTypeChange(e.target.value)
                                                        }}>
                                                        <option value="0">Discount percent</option>
                                                        <option value="1">Discount amount</option>
                                                        <option value="2">Offer text</option>
                                                    </Select>
                                                    {fieldErrors.cardTypeError && 
                                                        <Text
                                                            color="red.300" 
                                                            as="small">{fieldErrors.cardTypeError}</Text>}
                                                </FormControl>
                                            </Card>
                                            
                                            {/* Discount field for percent card type */}
                                            {rewardCard.cardType == 0 && 
                                                <Card h={{"base": "fit-content"}} mb="1rem">
                                                    <FormControl>
                                                        <FormLabel htmlFor='email'>
                                                            Discount <CustomToolTip label="The percent of amount to be discounted from customer's bill" />
                                                        </FormLabel>
                                                        <InputGroup>
                                                            <InputLeftAddon children="%" />
                                                            <Input 
                                                                id="id_pc_percent"
                                                                value={rewardCard.percent}
                                                                type="number"
                                                                placeholder='Discount percentage'
                                                                isInvalid={Boolean(fieldErrors.percentError)}
                                                                onChange={e => {
                                                                    handleDiscountPercentChange(e.target.value);
                                                                }} />
                                                        </InputGroup>
                                                        {fieldErrors.percentError && 
                                                            <Text
                                                                color="red.300" 
                                                                as="small">{fieldErrors.percentError}</Text>}
                                                    </FormControl>
                                                </Card>}

                                            {/* Discount field for amount card type */}
                                            {rewardCard.cardType == 1 && 
                                                <Card h={{"base": "fit-content"}} mb="1rem">
                                                    <FormControl>
                                                        <FormLabel htmlFor='id_pc_amount'>
                                                            Discount <CustomToolTip label="The amount that to be discounted from customer's bill" />
                                                        </FormLabel>
                                                        <InputGroup>
                                                            <InputLeftAddon children={CURRENCY_HTML_SYMBOL} />
                                                            <Input 
                                                                id="id_pc_amount"
                                                                value={rewardCard.amount}
                                                                type="number"
                                                                placeholder='Discount amount'
                                                                isInvalid={Boolean(fieldErrors.amountError)}
                                                                onChange={e => {
                                                                    handleDiscountAmountChange(e.target.value);
                                                                }} />
                                                        </InputGroup>
                                                        {fieldErrors.amountError && 
                                                            <Text
                                                                color="red.300" 
                                                                as="small">{fieldErrors.amountError}</Text>}
                                                    </FormControl>
                                                </Card>}

                                            {/* Discount offer text card type */}
                                            {rewardCard.cardType == 2 && 
                                                <Card h={{"base": "fit-content"}} mb="1rem">
                                                    <FormControl>
                                                        <FormLabel htmlFor='id_pc_amount'>
                                                            Offer text <CustomToolTip label="Offer text like Buy 1 get 1, Diwali sale, etc" />
                                                        </FormLabel>
                                                        <InputGroup>
                                                            {/* <InputLeftAddon children={CURRENCY_HTML_SYMBOL} /> */}
                                                            <Input 
                                                                id="id_pc_offer_text"
                                                                value={rewardCard.offerText}
                                                                type="text"
                                                                placeholder='Offer text'
                                                                isInvalid={Boolean(fieldErrors.offerTextError)}
                                                                onChange={e => {
                                                                    handleDiscountOfferTextChange(e.target.value);
                                                                }} />
                                                        </InputGroup>
                                                        {fieldErrors.offerTextError && 
                                                            <Text
                                                                color="red.300" 
                                                                as="small">{fieldErrors.offerTextError}</Text>}
                                                    </FormControl>
                                                </Card>}

                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                {/* <Checkbox
                                                    id="id_pc_discount_useMinPurchaseAmount"
                                                    mb="0.5rem"
                                                    color="gray.textPrimary"
                                                    isChecked={rewardCard.useMinPurchaseAmount}
                                                    onChange={event => {
                                                        const checked = event.target.checked;

                                                        setRewardCard({
                                                            ...rewardCard, 
                                                            useMinPurchaseAmount: checked
                                                        });
                                                    }}>
                                                        Use minimum purchase amount
                                                </Checkbox> */}

                                                <FormControl>
                                                    <FormLabel htmlFor='id_pc_minamountpurchase'>
                                                        Minimum purchase amount <CustomToolTip label='The minimum amount the customer has to spend to be eligible for the coupon' />
                                                    </FormLabel>
                                                    <InputGroup>
                                                        <InputLeftAddon children={CURRENCY_HTML_SYMBOL} />
                                                        <Input 
                                                            id="id_pc_minamountpurchase"
                                                            value={rewardCard.minPurchaseAmount}
                                                            type="number"
                                                            placeholder='Minimum amount that customer must spend'
                                                            isInvalid={Boolean(fieldErrors.minPurchaseAmountError)}
                                                            onChange={e => {
                                                                handleMinPurchaseAmountChange(e.target.value);
                                                            }} />
                                                    </InputGroup>
                                                    {fieldErrors.minPurchaseAmountError && 
                                                        <Text
                                                            color="red.300" 
                                                            as="small">{fieldErrors.minPurchaseAmountError}</Text>}
                                                </FormControl>
                                            </Card>
                                        </Flex>
                                    </Step>
                                    {/* Discount form ends */}
                                
                                    {/* Validity form */}
                                    <Step>
                                        <Flex
                                            flexDirection={{"base": "column"}}>
                                            <Text as="b" color="gray.400" mb="1rem">
                                                Validity
                                            </Text>

                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                <FormControl>
                                                    <FormLabel htmlFor='id_pc_validationType'>
                                                        Type of validation
                                                    </FormLabel>

                                                    {rewardCard.validityDurationType === 2 && 
                                                        <InfoBlock 
                                                            description="Coupon will not have a date based expiration"/>
                                                    }

                                                    <Select 
                                                        id="id_pc_validationType"
                                                        value={rewardCard.validityDurationType}
                                                        isInvalid={Boolean(fieldErrors.validityDurationTypeError)}
                                                        onChange={e => {
                                                            handleValidationTypeChange(e.target.value);
                                                        }}>
                                                        
                                                        {/* 
                                                          * Validity types depend on purposeType.
                                                          * If purposeType is Loyalty, 0, then Validation Days can be selected,
                                                          * and if purposeType is Direct coupon, 1, then Validation Dates can be selected
                                                         */}
                                                        {rewardCard.purposeType == 0 
                                                            ? <option value="0">Validation days</option>
                                                            : <>
                                                                <option value="0">Validation days</option>
                                                                <option value="1">Validation dates</option>
                                                              </>}
                                                        <option value="2">No validity</option>
                                                    </Select>
                                                    {fieldErrors.validityDurationTypeError && 
                                                        <Text
                                                            color="red.300" 
                                                            as="small">{fieldErrors.validityDurationTypeError}</Text>}
                                                </FormControl>
                                            </Card>
                                            
                                            {rewardCard.validityDurationType == 0 && 
                                                <Card h={{"base": "fit-content"}} mb="1rem">
                                                    <FormControl>
                                                        <FormLabel htmlFor='id_pc_validationDay'>
                                                            Validity days <CustomToolTip label='The number of days the coupon will be valid since the date customer get it' />
                                                        </FormLabel>

                                                        <InfoBlock 
                                                         description="Start date will be the date when customer get the coupon."/>

                                                        <Input 
                                                            id="id_pc_validationDay" 
                                                            placeholder='The number of days this card will be valid after dispatching'
                                                            type="number"
                                                            min="1"
                                                            value={rewardCard.validityDays}
                                                            isInvalid={Boolean(fieldErrors.validityDaysError)}
                                                            onChange={e => {
                                                                handleValidationDaysChange(e.target.value);
                                                            }} />
                                                        {fieldErrors.validityDaysError && 
                                                            <Text
                                                                color="red.300" 
                                                                as="small">{fieldErrors.validityDaysError}</Text>}
                                                    </FormControl>
                                                </Card>}
                                            
                                            {rewardCard.validityDurationType == 1 && 
                                            <Flex 
                                                justify={{"base": "start"}}
                                                gap={{"base": "1rem"}}
                                                mb={{"base": "1rem"}}>

                                                <Flatpickr
                                                    onChange={handleValidityStartDateChange}
                                                    value={rewardCard.validityStartDate || today}
                                                    options={{
                                                        dateFormat: "j/n/Y",
                                                        minDate: rewardCard.validityStartDate || "today"
                                                    }}
                                                    render={({ value }, ref)=>{
                                                        return (
                                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                                <FormControl>
                                                                    <FormLabel htmlFor='email'>Start date</FormLabel>
                                                                    <InputGroup>
                                                                        <InputLeftAddon children={<FaCalendarDays />} />
                                                                        <Input 
                                                                            defaultValue={ value } ref={ref}
                                                                            placeholder='Validation start date' />
                                                                    </InputGroup>
                                                                </FormControl>
                                                            </Card>
                                                        )
                                                    }} 
                                                />

                                                <Flatpickr
                                                    onChange={handleValidityEndDateChange}
                                                    value={rewardCard.validityEndDate}
                                                    options={{
                                                        dateFormat: "j/n/Y",
                                                        minDate: getNextDate(rewardCard.validityStartDate),
                                                        defaultValue: getNextDate(rewardCard.validityStartDate),
                                                    }}
                                                    render={({ value }, ref)=>{
                                                        return (
                                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                                <FormControl>
                                                                    <FormLabel htmlFor='email'>End date</FormLabel>
                                                                    <InputGroup>
                                                                        <InputLeftAddon children={<FaCalendarDays />} />
                                                                        <Input 
                                                                            defaultValue={ value } ref={ref}
                                                                            placeholder='Validation end date' />
                                                                    </InputGroup>
                                                                </FormControl>
                                                            </Card>
                                                        )
                                                    }} 
                                                />

                                            </Flex>}
                                        </Flex>
                                    </Step>
                                    {/* Validity form ends */}

                                    {/* T&C form */}
                                    <Step>
                                        <Flex
                                            flexDirection={{"base": "column"}}
                                            mb={{"base": "3rem"}}>

                                            <Text as="b" color="gray.400" mb="1rem">Terms and conditions</Text>
                                            <Card h={{"base": "fit-content"}} mb="1rem">
                                                <FormControl>
                                                    <FormLabel htmlFor='id_pc_tnc'>
                                                        Terms and conditions <CustomToolTip label='The terms and conditions that this coupon will fall under' />
                                                    </FormLabel>
                                                    <Textarea
                                                        value={rewardCard.tnc}
                                                        onChange={e => {
                                                            handleTermsAndCondChange(e.target.value);
                                                        }}
                                                        placeholder='Here is a sample placeholder'
                                                        size='sm'
                                                        isInvalid={Boolean(fieldErrors.tncError)}
                                                    />
                                                    {fieldErrors.tncError && 
                                                        <Text
                                                            color="red.300" 
                                                            as="small">{fieldErrors.tncError}</Text>}
                                                </FormControl>
                                            </Card>
                                            
                                            {isEditing 
                                                ? <Button 
                                                    mb={{"base": "3rem"}} 
                                                    p={{"base": "1.5rem"}}
                                                    isLoading={updateCouponMutation.isLoading}
                                                    // isDisabled={isCreateEditButtonDisabled()}
                                                    variant="primaryBlue"
                                                    onClick={handleUpdateCouponOnClick}>Update Coupon</Button>

                                                : <Button 
                                                    mb={{"base": "3rem"}} 
                                                    p={{"base": "1.5rem"}}
                                                    isLoading={createCouponMutation.isLoading}
                                                    // isDisabled={isCreateEditButtonDisabled()}
                                                    variant="primaryBlue"
                                                    onClick={handleCreateCouponOnClick}>Create Coupon</Button>
                                            } 
                                        </Flex>
                                    </Step>
                                    {/* T&C form ends */}

                                </MultiStepForm>
                            </Flex>

                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default AddOrEditRewardCardComp;