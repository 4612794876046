import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import { getTokenKey } from "@/utils/helpers";
import { CREDIT_CREATE_ORDER_URL } from "@/utils/endpoints";


const useCreateInAppOrderMutation = (extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const toast = useToast();
    const mutationRes = useMutation({
        mutationFn: (data) => {
            return axios.post(CREDIT_CREATE_ORDER_URL, data, {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`,
                }
            })
        },
        onError: (error: any) => {
            toast({
                title: "Error occurred while creating order",
                description: error.detail,
                position: 'top-right',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        },
        ...extraArgs
    });

    // navigate to signin page when 401 status is recieved in status
    if (mutationRes.error && mutationRes.error.request.status === 401) {
        navigate("/");
    }
    return mutationRes;
}

export default useCreateInAppOrderMutation;