import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { CREDIT_TRANSACTION_LIST_URL } from "@/utils/endpoints";
import { getTokenKey } from "@/utils/helpers";

const useFetchCreditTransactions = (page : number = 1, extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const queryRes = useQuery({
        queryKey: ["rewardCards"], 
        queryFn: async () => {
            const res = await axios(CREDIT_TRANSACTION_LIST_URL(page), {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`
                }
            });
            return res.data;
        }, 
        refetchOnWindowFocus: false,
        ...extraArgs
    });

    // navigate to signin page when 401 status is recieved in status
    const queryResError: any = queryRes.error;
    if (queryResError && queryResError.request.status === 401) {
        navigate("/");
    }
    return queryRes;
}

export default useFetchCreditTransactions;