import React from "react";

import { 
    Box, 
    Divider, 
    Flex, 
    FormControl, 
    FormLabel, 
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    Text
} from "@chakra-ui/react"

import { DEFAULT_COUNTRY_CODE } from "@/utils/globalConstants";


interface PersonalDetailsFormProps {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    firstNameError: string,
    lastNameError: string,
    emailError: string,
    phoneError: string,
    onFirstNameChange: React.ChangeEventHandler<HTMLInputElement>,
    onLastNameChange: React.ChangeEventHandler<HTMLInputElement>,
    onEmailChange: React.ChangeEventHandler<HTMLInputElement>,
    onPhoneChange: React.ChangeEventHandler<HTMLInputElement>,
}

const PersonalDetailsForm = (props: PersonalDetailsFormProps) => {

    const {
        firstName,
        lastName,
        email,
        phone,
        firstNameError,
        lastNameError,
        emailError,
        phoneError,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onPhoneChange
    } = props;

    return (
        <Flex 
            className="personalform_container"
            flexDir="column">
            <Box mb="1rem">
              <Heading size="md" mb='10px' fontWeight='normal'>
                Personal Details
              </Heading>
              <Divider /> 
            </Box>

            {/* first_name and last_name */}
            <HStack spacing="20px" mb='1rem'>
              {/* first_name */}
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  First name*
                </FormLabel>
                <Input
                  id="first_name_id"
                  borderRadius='15px'
                  mb={!firstName ? '0px' : '24px'}
                  fontSize='sm'
                  type='text'
                  placeholder='First name'
                  size='md'
                  errorBorderColor='red.300'
                  isInvalid={firstNameError?.length > 0}
                  value={firstName}
                  onChange={onFirstNameChange}
                />
                {firstNameError && 
                  <Text fontSize='xs' textColor='red.300'>*{firstNameError}</Text>}
              </FormControl>
              
              {/* last_name */}
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Last name*
                </FormLabel>
                <Input
                  id="first_name_id"
                  borderRadius='15px'
                  mb={!lastName ? '0px' : '24px'}
                  fontSize='sm'
                  type='text'
                  placeholder='Last name'
                  size='md'
                  errorBorderColor='red.300'
                  isInvalid={lastNameError?.length > 0}
                  value={lastName}
                  onChange={onLastNameChange}
                />
                {lastNameError && 
                  <Text fontSize='sm' textColor='red.300'>*{lastNameError}</Text>}
              </FormControl>
            </HStack>

            {/* email */}
            <FormControl
                mb="1rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Email*
                </FormLabel>
                <Input
                  id="email_id"
                  borderRadius='15px'
                  fontSize='sm'
                  type='email'
                  placeholder='Your email address'
                  size='md'
                  errorBorderColor='red.300'
                  isInvalid={emailError?.length > 0}
                  value={email}
                  onChange={onEmailChange}
                />
                {emailError && 
                  <Text fontSize='sm' textColor='red.300'>*{emailError}</Text>}
            </FormControl>

            {/* phone */}
            <FormControl
                mb="1rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Phone*
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon children={DEFAULT_COUNTRY_CODE} />
                  <Input
                    id="phone_id"
                    borderRadius='15px'
                    fontSize='sm'
                    type='text'
                    placeholder='Phone number'
                    size='md'
                    errorBorderColor='red.300'
                    isInvalid={phoneError?.length > 0}
                    value={phone}
                    onChange={onPhoneChange}
                  />
                </InputGroup>
                {phoneError && 
                  <Text fontSize='sm' textColor='red.300'>*{phoneError}</Text>}
            </FormControl>
        </Flex>
    )
}

export default PersonalDetailsForm;