import React from "react";
import { 
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogFooter,
    Button,
    useDisclosure,
} from "@chakra-ui/react"
import { CheckCircleIcon } from "@chakra-ui/icons"

interface DialogProgramCreateSucessProps {
    isOpen: boolean,
    onClose: Function
}

const DialogProgramCreateSucess = (props: DialogProgramCreateSucessProps) => {
    const { isOpen, onClose } = props;
    const cancelRef = React.useRef()
  
    return (
        <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader><CheckCircleIcon /> You have successfully created Loyalty Program</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Hey, you have successfully configured your <b>Loyalty program.</b>
            Now you can start adding customers to your loyalty program from dashboard, and 
            they can get Coupon cards when they hit a purchase milestone. 
            You are free to make further changes to your loyalty program as you like.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Okay
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  export default DialogProgramCreateSucess;