import { useState, useEffect } from "react";

import { 
    Flex,
    useColorModeValue,
} from "@chakra-ui/react";
import ReactPaginate from 'react-paginate';

import { tablesTableData, dashboardTableData } from "@/variables/general";
import PaginationContainer from "../Pagination/PaginationContainer";
import SkeletonTable from "../SkeletonLoading/SkeletonTable";
import TransactionTable from "./TransactionTable";
import useFetchCreditTransactions from "@/hooks/useFetchCreditTransactions";
// import { data } from "./data";

const CreditTransaction = () => {
    const captions = ["", "Date", "Remark", "Debit", "Credit", "Balance"]

    const [count, setCount] = useState(0);
    const [next, setNext] = useState<string | null>(null);
    const [previous, setPrevious] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tablesData, setTableData] = useState([])

    const query = useFetchCreditTransactions(currentPage, {
        enabled: false,
        onSuccess: (data) => {
            setCount(data.count);
            setNext(data.next);
            setPrevious(data.previous);
            setTableData(data.results);
        }
    })

    const itemsPerPage = 10;
    const pageCount = Math.ceil(count / itemsPerPage);

    useEffect(() => {
        query.refetch();
    }, [currentPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        // const newOffset = (event.selected * itemsPerPage) % data.length;
        console.log(
        `User requested page number ${event.selected}`
        );
        setCurrentPage(event.selected + 1);
    };

    const getIndexedTableData = (items: any[], pageSize: number, currentPage: number) => {
        return items.map((item, index) => {
            return {
                ...item,
                index: (currentPage - 1) * pageSize + index + 1
            }
        });
    }

    return (
        <Flex
            direction='column' w='100%'>
            {query.isFetching 
                ? <SkeletonTable /> 
                : <TransactionTable captions={captions} tablesData={getIndexedTableData(tablesData, itemsPerPage, currentPage)} />
            }
            <PaginationContainer>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pageCount}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="transaction-pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item previous"
                    previousLinkClassName="page-link"
                    nextClassName="page-item next"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                />
            </PaginationContainer>
        </Flex>
    );
}

export default CreditTransaction;