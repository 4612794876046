import React, { useState, useEffect } from "react";

import {
    Box,
    Button,
    Flex,
} from "@chakra-ui/react";

import {
    FaArrowLeftLong,
} from "react-icons/fa6";

interface MultiStepRegistrationProps {
    className: string,
    currentStep: number,
    children: React.ReactNode,
    finalSubmitElem: React.ReactNode | null,
    onCurrentStepChange: CallableFunction | null,
    onNext: React.MouseEventHandler<HTMLButtonElement> | null,
    onPrevious: React.MouseEventHandler<HTMLButtonElement> | null,
    onFinalSubmit: React.MouseEventHandler<HTMLButtonElement> | null,
}

const MultiStepRegistration = (props: MultiStepRegistrationProps) => {

    const {
        className="",
        currentStep,
        onNext,
        onPrevious,
        onFinalSubmit
    } = props;

    const getCurrentStep = () => {
        if (props.children.length) {
            return props.children[currentStep - 1]
        }
        return props.children;
    }

    const getValidateFn = () => {
        const currentStepChild = getCurrentStep();
        if (currentStepChild.props && "validateFn" in currentStepChild.props) {
            return currentStepChild.props.validateFn;
        }
        return null;
    }

    const isFinalStep = () => {
        return currentStep === (props.children.length);
    }

    const showNextButton = () => {
        return currentStep < props.children.length;
    }

    const isPrevButtonDisabled = () => {
        return (currentStep <= 1);
    }

    return (
        <Box
            className={"multistep_container " + className}
            m="0px"
            p="0px">
            {getCurrentStep()}

            <Flex
                className="multistep_btns"
                position="relative"
                flexDir="row"
                justify="space-between"
                mb="1rem">
                
                <Button 
                    bg="inherit"
                    color="gray.400"
                    fontSize="sm"
                    _hover={{
                        "bg": "inherit",
                        "color": "gray.textPrimary"
                    }}
                    isDisabled={isPrevButtonDisabled()}
                    onClick={event => {
                        const prevStep = currentStep - 1;
                        if (prevStep >= 0) {
                            if (onPrevious) {
                                onPrevious(event);
                            }
                        }
                    }}
                    leftIcon={<FaArrowLeftLong />
                    }>Back</Button>

                {showNextButton() && 
                    <Button
                        w={{"base": "50%", "sm": "50%", "md": "50%"}}
                        borderRadius="8px"
                        bg="green.500"
                        color="white"
                        _hover={{
                            "bg": "blue.dark",
                        }}
                        onClick={event => {
                            const validateFn = getValidateFn();
                            const nextStep = currentStep + 1;
                            if (nextStep <= props.children.length) {
                                if (validateFn && !validateFn()) {
                                    return;
                                }
                                if (onNext){
                                    onNext(event);
                                }
                            }
                        }}>Next</Button>
                }

                {isFinalStep() && 
                    <Button
                        w={{"base": "75%", "sm": "75%", "md": "75%"}}
                        borderRadius="8px"
                        bg="green.500"
                        color="white"
                        _hover={{
                            "bg": "blue.dark",
                        }}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        onClick={event => {
                            const validateFn = getValidateFn();
                            if (validateFn && !validateFn()) {
                                return;
                            }
                            if (onFinalSubmit) {
                                onFinalSubmit(event);
                            }
                        }}>Submit</Button>}
            </Flex>
        </Box>
    )
}

export default MultiStepRegistration