import React, { MouseEventHandler } from "react";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    List,
    ListItem,
    ListIcon,
    Text,
    OrderedList,
    UnorderedList,
    useDisclosure
  } from '@chakra-ui/react';

import { CautionIcon } from '../Icons/Icons';

interface PurchaseWarningDialogProps {
    isOpen: boolean,
    loyaltyProgramStatus: number,
    isLoyaltyProgramCreated: boolean,
    selectedCards: {id: string, name: string}[],
    verifCode: string,
    onOpen: MouseEventHandler<Event> | null,
    onClose: MouseEventHandler<HTMLButtonElement>,
    onContinue: MouseEventHandler<HTMLButtonElement>,
}

const PurchaseWarningDialog = (props: PurchaseWarningDialogProps) => {
    const { 
        isOpen,
        loyaltyProgramStatus,
        isLoyaltyProgramCreated,
        selectedCards=[],
        verifCode,
        onOpen,
        onClose,
        onContinue 
    } = props;

    const cancelRef = React.useRef()

    const isValidPurchaseEntry = () => {
        return (loyaltyProgramStatus === 1 && isLoyaltyProgramCreated) || selectedCards.length || verifCode.length;
    }

    const getAlertDialogHeader = () => {
        return isValidPurchaseEntry() 
            ? <AlertDialogHeader>
                <CautionIcon h={"40px"} w={"40px"} color={"yellow.400"} /> Please review your actions
              </AlertDialogHeader>
            : <AlertDialogHeader
                bg="red.500"
                color="white">
                <CautionIcon h={"40px"} w={"40px"} color={"yellow.400"} /> Something doesn't seem right
             </AlertDialogHeader>
    }

    const getAlertDialogBody = () => {
        if (isValidPurchaseEntry()) {
            return (
                <AlertDialogBody>
                    <Text as="b">You are taking following actions:</Text>
                    <UnorderedList 
                        color="gray.textPrimary"
                        mt="1rem"
                        mb="1rem">
                        {(isLoyaltyProgramCreated && loyaltyProgramStatus === 1) && 
                            <ListItem><Text>Adding user to <b>Loyalty program.</b></Text></ListItem>}
                        
                        {verifCode.length && 
                            <ListItem><Text>Applying coupon with verification code: <b>{verifCode}.</b></Text></ListItem>}
                        
                        {selectedCards.length > 0 && 
                            <ListItem>
                                <Text>Sending following <b>direct cards</b> to customer</Text>
                                <OrderedList>
                                    {selectedCards.map(item => <ListItem key={item.id}>{item.name}</ListItem>)}
                                </OrderedList>
                            </ListItem>}
                    </UnorderedList>

                </AlertDialogBody>
            );
        } else {
            return (
                <AlertDialogBody>
                    <Text>
                        It seems like:
                    </Text>
                    <UnorderedList 
                        color="gray.textPrimary"
                        mt="1rem"
                        mb="1rem">
                        <ListItem><Text>Your  <b>Loyalty program</b> is OFF or not created.</Text></ListItem>
                        <ListItem><Text>You have not selected any <b>Direct cards</b>.</Text></ListItem>
                        <ListItem><Text>You are not verifying any coupon right now.</Text></ListItem>
                    </UnorderedList>
                    <Text>At least one of these conditions needs to be fullfilled to continue.</Text>
                </AlertDialogBody>
            );
        }
    }

    const getAlertFooterDialog = () => {
        if (isValidPurchaseEntry()) {
            return (
                <AlertDialogFooter
                    flexDir="column"
                    justifyContent="start">
                    <Text as="b" fontSize="lg">Do you wish to continue?</Text>
                    <Button w="100%" bg="blue.dark" color="white" mb="0.5rem" borderRadius="md" onClick={onContinue}>
                        Continue!
                    </Button>
                    <Button w="100%" variant="primaryRed" borderRadius="md" ref={cancelRef} onClick={onClose}>
                        Cancel
                    </Button>
                </AlertDialogFooter>
            );
        } else {
            return (
                <AlertDialogFooter
                    flexDir="column"
                    justifyContent="start">
                    <Button w="100%" variant="primaryRed" borderRadius="md" ref={cancelRef} onClick={onClose}>
                        Cancel
                    </Button>
                </AlertDialogFooter>
            );
        }
    }
  
    return (
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent>
            {getAlertDialogHeader()}

            <AlertDialogCloseButton />

            {getAlertDialogBody()}

            {getAlertFooterDialog()}

          </AlertDialogContent>
        </AlertDialog>
    )
  }

  export default PurchaseWarningDialog;