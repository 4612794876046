import Profile from "@/views/Dashboard/Profile";
import SignIn from "@/views/auth/signin";
// import { RewardProgram } from "./views/business/rewardProgram";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "@components/Icons/Icons";

import { Icon } from "@chakra-ui/react";
import { FaHouseMedicalCircleExclamation } from "react-icons/fa6";
import { TbCurrencyCent } from "react-icons/tb";

var dashRoutes = [
  {
    path: "/dashboard/",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    // component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/reward/coupons/",
    allowTrailingPath: true,
    name: "Coupons",
    icon: <DocumentIcon color="inherit" />,
    layout: "/admin",
  },
  {
    path: "/reward/",
    name: "Loyalty Program",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    // component: <RewardProgram />,
    layout: "/admin",
  },
  {
    path: "/credits/",
    name: "Credits",
    rtlName: "لوحة القيادة",
    icon: <Icon as={TbCurrencyCent} />,
    // component: <Dashboard />,
    layout: "/admin"
  },
  // {
  //   path: "#",
  //   name: "Metrics",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color="inherit" />,
  //   component: RTLPage,
  //   layout: "#",
  // },
  {
    path: "/profile/",
    name: "Profile",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: false,
    component: Profile,
    layout: "/admin",
  },
  // {
  //   path: "#",
  //   name: "Settings",
  //   rtlName: "لوحة القيادة",
  //   icon: <SupportIcon color="inherit" />,
  //   secondaryNavbar: true,
  //   component: Profile,
  //   layout: "#",
  // },
  // {
  //   path: "#",
  //   name: "Help",
  //   rtlName: "لوحة القيادة",
  //   icon: <Icon as={FaHouseMedicalCircleExclamation} />,
  //   component: SignIn,
  //   layout: "#",
  // },
  {
    path: "#",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: null,
    layout: "",
    isLogoutButton: true,
  },
];

export const adminRoutePrefix = '/admin/'
export const authRoutePrefix = '/auth/';
export const pageRoutes = {
	// auth
	signin: authRoutePrefix + "signin/",
	signup: authRoutePrefix + "signup/",
	forgotPassword: authRoutePrefix + "forgot-password/",
	forgotPasswordConfirm: authRoutePrefix + "forgot-password/confirm/:token/:uidb64",

	// dashboard
	dashboard: `dashboard/`,

	// rewards
	rewardProgram: `reward/`,
	rewardCard: `reward/coupons/`,
	rewardCardNew: `reward/coupons/new/`,
  rewardCardDetail: `reward/coupons/:rewardCardId`,

	// business
  profile: `profile/`,
  credits: 'credits/',

  // non-admin layout routes
  paymentInAppStatus: 'payment/inapp-payment-status/',

}
export default dashRoutes;
