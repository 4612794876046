import React, { useState } from "react";

import { 
    Box, 
    Divider, 
    Flex, 
    FormControl, 
    FormLabel, 
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightAddon,
    Text
} from "@chakra-ui/react"

import { FaEye, FaEyeSlash } from "react-icons/fa6";


interface PasswordFormProps {
    password: string,
    passwordError: string,
    confirmPassword: string,
    confirmPasswordError: string,
    onPasswordChange: React.ChangeEventHandler,
    onConfirmPasswordChange: React.ChangeEventHandler,
}

const PasswordForm = (props: PasswordFormProps) => {
    const {
        password,
        passwordError,
        confirmPassword,
        confirmPasswordError,
        onPasswordChange,
        onConfirmPasswordChange
    } = props;

    const [showPass, setShowPass] = useState(false);

    const getPassIcon = () => {
        return showPass ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />;
    }

    return (
        <Flex 
            className="personalform_container"
            flexDir="column">
            <Box mb="1rem">
              <Heading size="md" mb='10px' fontWeight='normal'>
                Set password
              </Heading>
              <Divider /> 
            </Box>

            {/* password */}
            <FormControl mb="1rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Password*
                </FormLabel>

                <InputGroup>
                    <Input
                        id="password_id"
                        borderRadius='15px'
                        mb={!password ? '0px' : '24px'}
                        fontSize='sm'
                        type={showPass ? "text" : "password"}
                        placeholder='Password'
                        size='md'
                        errorBorderColor='red.300'
                        isInvalid={passwordError?.length > 0}
                        value={password}
                        onChange={onPasswordChange}
                    />
                    <InputRightAddon 
                        onClick={event => setShowPass(!showPass)}>
                        { getPassIcon() }
                    </InputRightAddon>
                </InputGroup>
                {passwordError && 
                    <Text as="small" fontSize='xs' textColor='red.300'>*{passwordError}</Text>}
            </FormControl>

            {/* confirm password */}
            <FormControl mb="1.5rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    Confirm password*
                </FormLabel>

                <InputGroup>
                    <Input
                        id="conformpassword_id"
                        borderRadius='15px'
                        // mb={!password.length ? '0px' : '24px'}
                        fontSize='sm'
                        type='password'
                        placeholder='Confirm password'
                        size='md'
                        errorBorderColor='red.300'
                        isInvalid={confirmPasswordError?.length > 0}
                        value={confirmPassword}
                        onChange={onConfirmPasswordChange}
                    />
                </InputGroup>
                {confirmPasswordError && 
                    <Text as="small" fontSize='xs' textColor='red.300'>*{confirmPasswordError}</Text>}
            </FormControl>
        </Flex>
    )
}

export default PasswordForm;