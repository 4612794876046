import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getTokenKey } from "@/utils/helpers";
import { PRE_AMOUNT_UPDATE_REWARD_PROGRAM_URL } from "@/utils/endpoints";


const usePreAmountUpdateProgramMutation = (extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const mutationRes = useMutation({
        mutationFn: (data) => {
            return axios.post(PRE_AMOUNT_UPDATE_REWARD_PROGRAM_URL, data, {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`,
                }
            })
        },
        ...extraArgs
    });

    // navigate to signin page when 401 status is recieved in status
    const mutationResError: any = mutationRes.error;
    if (mutationResError && mutationResError.request.status === 401) {
        navigate("/");
    }
    return mutationRes;
}

export default usePreAmountUpdateProgramMutation;