export const isTokenValid = () => {
    let inback_api_token_str = localStorage.getItem("inback_api_token")
    if (inback_api_token_str?.length) {

        const inback_api_token = JSON.parse(inback_api_token_str);
        if (!inback_api_token.token.length ||
            !inback_api_token.token_expiration_datetime.length) {
                return false
            }
        const token_expiration_datetime_string = inback_api_token["token_expiration_datetime"];
        const token_expiration_datetime = new Date(token_expiration_datetime_string);
        const now = new Date();

        if (token_expiration_datetime <= now) {
            return false;
        }
    }else {
        return false
    }
    return true;
}

export const removeAccessToken = () => {
    localStorage.removeItem("inback_api_token");
}

export const getTokenKey = () => {
    let inback_api_token_str = localStorage.getItem("inback_api_token")
    if (inback_api_token_str?.length) {
        const inback_api_token = JSON.parse(inback_api_token_str);
        if (inback_api_token.token.length) {
            return inback_api_token.token;
        }
    }
    return "";
}

export const noInternetAlert = () => {
    alert("Network error: Please make sure that you're connected to internet");
}

export const isSystemOnline = () => {
    if (!window.navigator.onLine) {
        noInternetAlert();
        return;
      }
}

export const getCookie = (name: string) => {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

// used in creating confirmation dialogs
export const createPromise = () => {
    let resolver;
    return [ new Promise(( resolve, reject ) => {

        resolver = resolve
    }), resolver]
}


export const isPhoneValid = (phone: string) => {
    let isValid = true;
    let error = "";

    if (!Number(phone)) {
        error = "Phone number should be all numbers";
    } else if (phone.includes("e")) {
        error = "Phone number should be all numbers";
    } else if (phone.length < 10) {
        error = "Phone number should not be less than 10 digits";
    } else if (phone.length > 10) {
        error = "Phone number should be of 10 digits";
    } else if (phone.length == 10) {
        error = "";
    } else {
        error = "";
    }

    if (error.length) {
        isValid = false;
    }

    return [isValid, error];
}

export const isAmountValid = (amount: number) => {
    let isValid = true;
    let error = "";

    if (amount <= 0){
        error = "Amount cannot be zero or less than zero";
    }

    if (error.length) {
        isValid = false;
    }

    return [isValid, error];
}

export const isEmailValid = (value: string) => {
    var input = document.createElement('input');
  
    input.type = 'email';
    input.required = true;
    input.value = value;
  
    return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getFutureDate = (n: number) => {
    // here n is the number of days
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + n);
    return currentDate;
  }