import React, { useState, useEffect } from 'react';

import {
    ChakraProvider,
    useToast,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

import ForgotPasswordConfirmComp from './ForgotPasswordConfirmComp';
import { adminRoutePrefix, pageRoutes } from '@/routes';
import { isTokenValid } from '@/utils/helpers';
import usePasswordResetConfirm from '@/hooks/mutations/usePasswordResetConfirm';

import theme from "@/theme/theme";


const ForgotPasswordConfirm: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [resetPassConfirmState, setResetPassConfirmState] = useState({
        password: '',
        passwordErrMsg: '',
        invalidPassErrMsgs: [],
        confirmPassword: '',
        confirmPasswordErrMsg: '',
        pageErrMsg: '',
        isLoading: false,
        showSuccessMsg: false
    });

    const { token, uidb64 } = useParams();

    // Effect to check if token is valid or not
	useEffect(() => {
		if (isTokenValid()) {
			// redirect to dashboard page
			navigate(adminRoutePrefix + pageRoutes.dashboard);
		}
	}, []);

    const passwordResetConfirmMutation = usePasswordResetConfirm({
        onSuccess: (data: any) => {
            setResetPassConfirmState({
                ...resetPassConfirmState,
                showSuccessMsg: true
            });
        },
        onError: (error: any) => {
            if (error.response){ // error but got response
                const data = error.response.data;
                if ("password" in data) {
                    setResetPassConfirmState({
                        ...resetPassConfirmState,
                        isLoading: false,
                        invalidPassErrMsgs: data.password
                    });
                }
                else if ("detail" in data) {
                    toast({
                        title: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Error occurred while sending reset link',
                        description: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            } else {
                toast({
                    title: 'Internal server error',
                    description: "Error occurred while updating loyalty program",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                throw new Error("Error occurred while updating loyalty program");
            }
        }
    })

    const handleSubmit = () => {
        passwordResetConfirmMutation.mutate<any>({
            "token": token,
            "uidb64": uidb64,
            "password": resetPassConfirmState.password
        })
    }

    return (
        <ForgotPasswordConfirmComp 
            resetPassConfirmState={resetPassConfirmState}
            setResetPassConfirmState={setResetPassConfirmState}
            handleSubmit={handleSubmit} />
    );
}

export default ForgotPasswordConfirm;