import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { store } from './store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // commented out StrictMode since Modal is not working in StrictMode
  // in the ChakraUi 1.8.8
  
  // <React.StrictMode >
  //   <Provider store={store}>
  //     <App />
  //   </Provider>
  // </React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
);
