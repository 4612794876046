import React, { useState, useEffect } from 'react';

import {
    ChakraProvider,
    useToast,
} from '@chakra-ui/react';
import { useNavigate, useNavigation } from 'react-router-dom';

import usePasswordReset from '@/hooks/mutations/usePasswordReset';
import ForgotPasswordComp from './ForgotPasswordComp';
import { isTokenValid } from '@/utils/helpers';
import { adminRoutePrefix, pageRoutes } from '@/routes';

import theme from "@/theme/theme";


const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [resetPassState, setResetPassState] = useState({
        email: '',
        emailErrMsg: '',
        pageErrMsg: '',
        isLoading: false,
        showSuccessMsg: false,
    });

    const passwordResetMutation = usePasswordReset({
        onSuccess: (data: any) => {
            setResetPassState({
                ...resetPassState,
                emailErrMsg: '',
                isLoading: false,
                showSuccessMsg: true
            });
        },
        onError: (error: any) => {
            if (error.response){ // error but got response
                const data = error.response.data;
                if ("email" in data) {
                    setResetPassState({
                        ...resetPassState,
                        isLoading: false,
                        emailErrMsg: data.email[0]
                    });
                }
                else if ("detail" in data) {
                    toast({
                        title: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Error occurred while sending reset link',
                        description: data.detail,
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            } else {
                toast({
                    title: 'Internal server error',
                    description: "Error occurred while updating loyalty program",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                throw new Error("Error occurred while updating loyalty program");
            }
        }
    })

    // Effect to check if token is valid or not
	useEffect(() => {
		if (isTokenValid()) {
			// redirect to dashboard page
			navigate(adminRoutePrefix + pageRoutes.dashboard);
		}
	}, []);

    const handleSubmit = () => {
        passwordResetMutation.mutate({ email: resetPassState.email });
    }

    return (
        <ChakraProvider theme={theme}>
            <ForgotPasswordComp 
                resetPassState={resetPassState}
                passwordResetMutation={passwordResetMutation}
                setResetPassState={setResetPassState}
                handleSubmit={handleSubmit} />
        </ChakraProvider>
    );
}

export default ForgotPassword;