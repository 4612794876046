import { Box, Text, Flex } from "@chakra-ui/react";
import { createIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

import MainPanel from "../Layout/MainPanel";

export const ErrorBoundaryLogo = createIcon({
    displayName: "AdobexdLogo",
    viewBox: "0 0 24 24",
    path: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M7.074 1.408c0-.778.641-1.408 1.431-1.408.942 0 1.626.883 1.38 1.776-.092.336-.042.695.139.995.4.664 1.084 1.073 1.977 1.078.881-.004 1.572-.408 1.977-1.078.181-.299.23-.658.139-.995-.248-.892.434-1.776 1.378-1.776.79 0 1.431.63 1.431 1.408 0 .675-.482 1.234-1.117 1.375-.322.071-.6.269-.77.548-.613 1.017.193 1.917.931 2.823-1.211.562-2.525.846-3.97.846-1.468 0-2.771-.277-3.975-.84.748-.92 1.555-1.803.936-2.83-.17-.279-.447-.477-.77-.548-.634-.14-1.117-.699-1.117-1.374zm16.926 18.092c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.792 0l1.414-1.414-.708-.708-1.414 1.414-1.414-1.414-.707.708 1.414 1.414-1.414 1.414.707.708 1.414-1.414 1.414 1.414.708-.708-1.414-1.414zm-7.208 0c0-3.584 2.916-6.5 6.5-6.5l.171.009c-.178-.175-.315-.398-.354-.683-.092-.677.286-1.147.766-1.333l2.23-.866c.541-.21.808-.813.594-1.346-.213-.533-.825-.795-1.367-.584l-2.294.891c-.329.127-.734.036-.926-.401-.185-.423-.396-.816-.62-1.188-1.714.991-3.62 1.501-5.7 1.501-2.113 0-3.994-.498-5.703-1.496-.217.359-.42.738-.601 1.146-.227.514-.645.552-.941.437l-2.294-.89c-.542-.21-1.154.051-1.367.584-.214.533.052 1.136.594 1.346l2.23.866c.496.192.855.694.773 1.274-.105.758-.683 1.111-1.234 1.111h-2.402c-.583 0-1.055.464-1.055 1.037s.472 1.037 1.055 1.037h2.387c.572 0 1.158.372 1.265 1.057.112.728-.228 1.229-.751 1.462l-2.42 1.078c-.531.236-.766.851-.526 1.373s.864.753 1.395.518l2.561-1.14c.308-.137.688-.106.901.259 1.042 1.795 3.142 3.608 6.133 3.941.843-.094 1.606-.315 2.305-.611-.816-1.086-1.305-2.43-1.305-3.889z"/>
        </svg>
    ),
  });

const ErrorMessage = ({error, resetErrorBoundary}) => {
    return (
        <MainPanel 
            role="alert"
            w={{
                base: '100%',
                xl: 'calc(100% - 275px)'
            }}
            display="flex"
            style={{"justify-content": "center"}}
            height="100vh">
                <Flex
                    flexDirection="column"
                    justify="center" >
                    <Text
                        textAlign="center"
                        mb="1rem">
                        <Icon 
                            as={ErrorBoundaryLogo}
                            fontSize="5rem"
                            fill="gray.500" />
                        {/* <ErrorBoundaryLogo
                            color="gray.500"
                            fontSize={{"base": "5rem"}} /> */}
                    </Text>
                    <Text
                        color="gray.500"
                        textAlign="center">Something doesn't seem right:</Text>
                        <Box 
                            color="red.400"
                            textAlign="center"
                            maxHeight={{"base": "300px"}}
                            overflowY="auto">{error}</Box>
                </Flex>
        </MainPanel>
    );
}

export default ErrorMessage;