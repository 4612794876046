import { useNavigate } from "react-router-dom";

import { 
    Flex,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    Box,
    Button,
} from "@chakra-ui/react";
import {
    FaArrowRightLong
} from "react-icons/fa6";

import ParentOverlay from "@/components/Overlays/ParentOverlay";

const DirectRewardAlert = () => {
	return (
		<ParentOverlay
            p="2rem">
			<Alert
				status="warning"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				borderRadius="10px"
				w={{ base: "30vw" }}
				h={{ base: "max-content" }}
				alignSelf="center"
			>
				<AlertIcon boxSize={{ base: "30px" }} />
				<Box>
					<AlertTitle>
						You don't have any direct coupon created yet, please create one to send 
						directly.
					</AlertTitle>
					{/* <AlertDescription>
					</AlertDescription> */}
				</Box>
			</Alert>
        </ParentOverlay>
	);
};

export default DirectRewardAlert;
