import { useState, useEffect } from "react";

import { 
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { State, City, IState, ICity }  from 'country-state-city';

import Card from "@/components/Card/Card";
import CardHeader from "@/components/Card/CardHeader";
import CardBody from "@/components/Card/CardBody";
import ChangePasswordModal from "@/components/ChangePasswordModal";
import { Business } from "@/utils/interfaces/business";
import Header from "./Header";
import { ChangeEventHandler, EventHandler, KeyboardEventHandler, MouseEventHandler } from "react";
import { CURRENCY_HTML_SYMBOL, DEFAULT_COUNTRY_CODE } from "@/utils/globalConstants";

interface BusinessProfilePropsType {
    profileFormState: Business,
    errorState: any,
    uiState: {isLoading: boolean, isUpdating: boolean, generalSuccessMsg: string},
    handleFirstNameChange: ChangeEventHandler,
    handleLastNameChange: ChangeEventHandler,
    handleLogoImageChange: ChangeEventHandler,
    handleBusinessNameChange: ChangeEventHandler,
    handleAddressLine1Change: ChangeEventHandler,
    handleAddressLine2Change: ChangeEventHandler,
    handlePinChange: ChangeEventHandler,
    handleCityChange: ChangeEventHandler,
    handleStateChange: ChangeEventHandler,
    handleSaveButtonOnClick: MouseEventHandler,
}

const BusinessProfileComp = (props: BusinessProfilePropsType) => {
    const {
        id,
        createdAt,
        updatedAt,
        name,
        logo,
        addressLine1,
        addressLine2,
        state,
        city,
        pin,
        user
    } = props.profileFormState;

    const [stateCode, setStateCode] = useState(state);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [openChangePassModal, setOpenChangePassModal] = useState(false);

    const {
        nameError,
        logoError,
        addressLine1Error,
        addressLine2Error,
        stateError,
        cityError,
        pinError,
        firstNameError,
        lastNameError,

        generalError,
    } = props.errorState;

    const {
        handleFirstNameChange,
        handleLastNameChange,
        handleLogoImageChange,
        handleBusinessNameChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handlePinChange,
        handleCityChange,
        handleStateChange,
        handleSaveButtonOnClick
    } = props;

    const {
        isLoading,
        isUpdating,
        generalSuccessMsg
    } = props.uiState;

    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );
    
    const getStates = async () => {
        let states = await State.getStatesOfCountry("IN");
        setStates(states);
    }

    const getCities = async () => {
        let cities = await City.getCitiesOfState("IN", stateCode);
        setCities(cities);
    }

    const getLogo = () => {
        return import.meta.env.VITE_BE_DOMAIN + logo;
    }

    useEffect(() => {
        getStates();
    }, []);

    useEffect(() => {
        getCities();
    }, [stateCode]);

    useEffect(() => {
        setStateCode(state);
    }, [state]);

    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
            <Card
                className="profile-header"
                mb={{"base": "1rem"}}>
                <Header 
                    name={name}
                    email={user.email}
                    logo={logo}
                    logoError={logoError}
                    onLogoUpload={handleLogoImageChange}
                    handleSaveButtonOnClick={handleSaveButtonOnClick} />
            </Card>

            <Card
                className="profile-business"
                mb={{"base": "1rem"}}>

                <CardHeader
                    className="header"
                    flexDirection="column">
                    <Heading 
                        variant="pageHeader"
                        mb="1rem">Business Details</Heading>
                    <Divider />
                </CardHeader>

                <CardBody
                    className="body"
                    flexDirection={{"base": "column"}}>
                    <Flex 
                        className="form-row"
                        w={{"base": "50%"}}>
                        
                        {/* business name field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='first-name'>Business name</FormLabel>
                            <Input 
                                id='business-name' 
                                type='text'
                                value={name}
                                placeholder="Business name"
                                isInvalid={nameError.length}
                                onChange={handleBusinessNameChange} />
                            {nameError.length > 0 && 
                                <Text
                                    as="small"
                                    mt="0.5rem"
                                    color="red.300">
                                    {nameError}
                                </Text>}
                        </FormControl>
                    </Flex>

                    {/* Address row */}
                    <Flex 
                        className="form-row"
                        w={{"base": "50%"}}>
                        
                        {/* Address Line 1 field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='first-name'>Address</FormLabel>
                            <Flex
                                className="input-wrapper"
                                mb={{"base": "0.5rem"}}>
                                <Input 
                                    id='address-line-1' 
                                    type='text'
                                    value={addressLine1}
                                    placeholder="Address Line 1"
                                    isInvalid={addressLine1Error.length}
                                    onChange={handleAddressLine1Change} />
                                {addressLine1Error.length > 0 && 
                                    <Text
                                        as="small"
                                        mt="0.5rem"
                                        color="red.300">
                                        {addressLine1Error}
                                    </Text>}
                            </Flex>
                            
                            <Flex
                                className="input-wrapper"
                                mb={{"base": "0.5rem"}}>
                                <Input 
                                    id='address-line-2' 
                                    type='text'
                                    value={addressLine2}
                                    placeholder="Address Line 2"
                                    isInvalid={addressLine2Error.length}
                                    onChange={handleAddressLine2Change} />
                                {addressLine2Error.length > 0 && 
                                    <Text
                                        as="small"
                                        mt="0.5rem"
                                        color="red.300">
                                        {addressLine2Error}
                                    </Text>}
                            </Flex>
                        </FormControl>
                    </Flex>

                    {/* Address row */}
                    <Flex 
                        className="form-row"
                        w={{"base": "50%"}}>
                        
                        {/* Pin code field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='first-name'>Pin</FormLabel>
                            <Input 
                                id='address-pin' 
                                type='text'
                                value={pin}
                                placeholder="Pin code"
                                isInvalid={pinError.length}
                                onChange={handlePinChange} />
                            {pinError.length > 0 && 
                                <Text
                                    as="small"
                                    mt="0.5rem"
                                    color="red.300">
                                    {pinError}
                                </Text>}
                        </FormControl>

                        {/* State field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='state'>State</FormLabel>
                            <Select
                                id="address-state"
                                value={state}
                                isInvalid={stateError.length}
                                onChange={event => {
                                    setStateCode(event.target.value)
                                    handleStateChange(event);
                                }}>
                                {states.length > 0 && 
                                    states.map(state => <option value={state.isoCode}>{state.name}</option>)}
                            </Select>
                            {stateError.length > 0 && 
                                <Text
                                    as="small"
                                    mt="0.5rem"
                                    color="red.300">
                                    {stateError}
                                </Text>}
                        </FormControl>

                        {/* City field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='city'>City</FormLabel>
                            <Select
                                id="address-city"
                                value={city}
                                isInvalid={cityError.length}
                                onChange={handleCityChange}>
                                {cities.length > 0 && 
                                    cities.map(city => <option value={city.name}>{city.name}</option>)}
                            </Select>
                            {cityError.length > 0 && 
                                <Text
                                    as="small"
                                    mt="0.5rem"
                                    color="red.300">
                                    {cityError}
                                </Text>}
                        </FormControl>
                    </Flex>
                </CardBody>
            </Card>

            <Card
                className="profile-personal"
                mb={{"base": "1rem"}}>

                <CardHeader
                    className="header"
                    flexDirection="column">
                    <Heading 
                        variant="pageHeader"
                        mb="1rem">Personal Details</Heading>
                    <Divider />
                </CardHeader>

                <CardBody
                    className="body"
                    flexDirection={{"base": "column"}}>
                    
                    {/* firstName and lastName row */}
                    <Flex 
                        className="form-row"
                        w={{"base": "100%"}}>
                        
                        {/* firstName field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='first-name'>First name</FormLabel>
                            <Input 
                                id='first-name' 
                                type='text'
                                value={user.firstName}
                                placeholder="First name"
                                isInvalid={firstNameError.length}
                                onChange={handleFirstNameChange} />
                            {firstNameError.length > 0 && 
                                <Text
                                    as="small"
                                    mt="0.5rem"
                                    color="red.300">
                                    {firstNameError}
                                </Text>}
                        </FormControl>

                        {/* lastName field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='last-name'>Last name</FormLabel>
                            <Input 
                                id='last-name' 
                                type='text'
                                value={user.lastName}
                                placeholder="Last name"
                                isInvalid={lastNameError.length}
                                onChange={handleLastNameChange} />
                            {lastNameError.length > 0 && 
                                <Text
                                    as="small"
                                    mt="0.5rem"
                                    color="red.300">
                                    {lastNameError}
                                </Text>}
                        </FormControl>
                    </Flex>

                    {/* email and phoneNumber row */}
                    <Flex 
                        className="form-row"
                        w={{"base": "100%"}}>
                        
                        {/* email field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='first-name'>Email</FormLabel>
                            <Input 
                                id='email' 
                                type='text'
                                placeholder="Email address"
                                value={user.email}
                                isDisabled={true} />
                        </FormControl>

                        {/* phone number field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='last-name'>Phone number</FormLabel>
                            <InputGroup>
                                <InputLeftAddon children={DEFAULT_COUNTRY_CODE} />
                                <Input 
                                    id='phone-number' 
                                    type='text'
                                    placeholder="Phone number"
                                    value={user.phone}
                                    isDisabled={true} />
                            </InputGroup>
                        </FormControl>
                    </Flex>

                    {/* Change password */}
                    <Flex 
                        className="form-row"
                        w={{"base": "100%"}}>
                        
                        {/* email field */}
                        <FormControl
                            p={{"base": "1rem"}}>
                            <FormLabel htmlFor='change-password'>Change password</FormLabel>
                            <Button
                                bg="blue.dark"
                                color="gray.300"
                                _hover={{"color": "white"}}
                                onClick={event => {
                                    event.preventDefault();
                                    setOpenChangePassModal(true);
                                }}>
                                Click to change password
                            </Button>
                        </FormControl>
                    </Flex>
                </CardBody>
            </Card>

            <ChangePasswordModal 
                isOpen={openChangePassModal}
                onClose={() => {
                    setOpenChangePassModal(false);
                }} />
        </Flex>
    );
}

export default BusinessProfileComp;