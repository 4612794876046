import React, { useState } from "react";

import { createPromise } from "@/utils/helpers";
import WarningDialogDeleteLoyaltyProgram from "../WarningDialogDeleteLoyaltyProgram";


interface SelectedCards {
    id: string,
    name: string,
}

const useDeleteLoyaltyProgramConfirm = () => {
    
    const [open, setOpen] = useState(false);
    const [ resolver, setResolver ] = useState({ resolver: null });

    const getConfirmation = async () => {
        setOpen(true);
        const [promise, resolver] = await createPromise();
        setResolver({resolver});
        return promise;
    }

    const onClick = async (status: boolean) => {
        setOpen(status);
        resolver.resolver(status);
    }

    const ConfirmationDialog = () => {
        return <WarningDialogDeleteLoyaltyProgram 
                    isOpen={open}
                    onConfirm={event => onClick(true)}
                    onClose={event => onClick(false)} />
    }

    return [getConfirmation, ConfirmationDialog];
}

export default useDeleteLoyaltyProgramConfirm;
