import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorMessage from "../ErrorMessage/ErrorMessage";


interface CustomErrorBoundaryProps {
    children: React.ReactNode | null
}
const CustomErrorBoundary = (props: CustomErrorBoundaryProps) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorMessage}>
            {props.children}
        </ErrorBoundary>
    )
}

export default CustomErrorBoundary;