import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { 
    Flex,
    FormControl,
    Input,
    InputGroup,
    Text,
    TableContainer,
    Button,
    Icon
} from "@chakra-ui/react";

import { FaCircleCheck } from "react-icons/fa6"

import { CURRENCY_HTML_SYMBOL } from "@/utils/globalConstants";

const ApplyCouponSection = (props) => {
    const {
        amount,
        verifCode,
        verifCodeError,
        verifCodeSuccess,
        verifRewardCardData,
        discountedAmount,

        verifyRewardCardQuery,

        handleVerifCodeChange,
        handleOpenPreviewModal,
    } = props;
    
    const navigate = useNavigate();

    const getVerifCodeSuccessMessage = () => {
        if (verifCodeError.length == 0 &&
            verifCodeSuccess.length > 0) {

            return (
                <Flex
                    justify={{"base": "space-between"}}>
                    <Text as="small" color="gray.500">
                        <Icon as={FaCircleCheck} color="green.500" verticalAlign="middle" /> Coupon applicable
                    </Text>

                    {Object.keys(verifRewardCardData).length > 0 && 
                        <Text 
                            as="small" 
                            color="gray.500"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={event => {
                                handleOpenPreviewModal(verifRewardCardData.id);
                            }}>
                            Preview coupon
                        </Text>
                    }
                </Flex>
            );
        }
    }

    const getVerifCodeErrorMessage = () => {
        if (verifCodeError.length > 0 &&
            verifCodeSuccess.length === 0) {

            return (
                <Flex
                    justify={{"base": "space-between"}}>
                    <Text
                        as="small"
                        mt="0.1rem"
                        color="red.300">
                        *{verifCodeError}
                    </Text>

                    {Object.keys(verifRewardCardData).length > 0 && 
                        <Text 
                            as="small" 
                            color="gray.500"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={event => {
                                handleOpenPreviewModal(verifRewardCardData.id);
                            }}>
                            Preview coupon
                        </Text>
                    }
                </Flex>
            );
        }
    }

    const getCouponDiscountText = () => {
        if (verifRewardCardData) {
            const rewardJson = JSON.parse(verifRewardCardData.reward_json);
            const cardType = rewardJson.card_type;
            if (cardType === 0) { // cardType is percent
                const percent = rewardJson.percent;
                const discount = Math.ceil(amount * (percent/100));
                return <Text>- {CURRENCY_HTML_SYMBOL} {discount} ({percent}% Off)</Text>
            } else { // cardType is amount
                const cardAmount = rewardJson.amount;
                return <Text>- {CURRENCY_HTML_SYMBOL} {cardAmount}</Text>
            }
        }
        return null;
    }

    return (
        <Flex
            flexDirection="column"
            mb="1rem">
                <Flex
                    className="verification_field_container"
                    flexDirection="column">
                    <Flex 
                        className="verification_field"
                        flexDirection="row">
                        <Input
                            flex="2"
                            borderTopRightRadius="0px"
                            borderBottomRightRadius="0px"
                            placeholder="Verification code"
                            type="text"
                            isDisabled={verifyRewardCardQuery.isFetching}
                            value={verifCode}
                            isInvalid={ verifCodeError.length > 0 }
                            onChange={handleVerifCodeChange}
                        />
                        <Button
                            flex="1"
                            color="gray.500"
                            borderRadius="md"
                            borderTopLeftRadius="0px"
                            borderBottomLeftRadius="0px"
                            isLoading={verifyRewardCardQuery.isFetching}
                            onClick={event => verifyRewardCardQuery.refetch()}
                            >Verify coupon</Button>
                    </Flex>
                    {getVerifCodeErrorMessage()}
                    {getVerifCodeSuccessMessage()}
                </Flex>
                
                {verifCodeSuccess.length > 0 && verifRewardCardData && 
                    <TableContainer
                        color="gray.500"
                        p="0.5rem"
                        fontSize="sm">
                        <table
                            style={{width: "100%"}}>
                            <tr>
                                <td><Text>Subtotal</Text></td>
                                <td><Text>&nbsp;&nbsp;{CURRENCY_HTML_SYMBOL} {amount}</Text></td>
                            </tr>
                            <tr>
                                <td><Text>Coupon discount</Text></td>
                                <td>{getCouponDiscountText()}</td>
                            </tr>
                            <tr>
                                <td><Text fontSize="lg" fontWeight="bold">Total</Text></td>
                                <td><Text fontSize="lg" fontWeight="bold">&nbsp;&nbsp;{CURRENCY_HTML_SYMBOL} {discountedAmount}</Text></td>
                            </tr>
                        </table>
                        <hr></hr>
                    </TableContainer>
                }
        </Flex>
    );
}

export default ApplyCouponSection;