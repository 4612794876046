import { useState, useEffect } from "react";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const usePolling = (
    url: string,
    pollingInterval: number | undefined,
  ): any => {
    const [delay, setDelay] = useState(pollingInterval);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const [query, setQuery] = useState<any>();
    // const [result, setResult] = useState(null);
    // const [error, setError] = useState<PollingError>(null);

    const queryRes = useQuery({queryKey: ["polling"], queryFn: async () => {
            const res = await axios(url);
            return res.data;
        }, 
        enabled: false, 
        refetchOnWindowFocus: false,
        retry: 1
    });
  
    useEffect(() => {
        const intervalId = setInterval(async () => {
            queryRes.refetch();
            setQuery(queryRes);
        }, delay);
        setIntervalId(intervalId);
    }, []);
  
    return [queryRes, intervalId];
}

export default usePolling;