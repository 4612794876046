import React from 'react';

import {
    Flex,
    Heading,
    Icon,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Text,
    Spinner
} from "@chakra-ui/react";
import { FaCircleCheck, FaListUl } from "react-icons/fa6";

import useFetchCurrentCredit from '@/hooks/useFetchCurrentCredit';
import Card from '@/components/Card/Card';
import CardBody from '@/components/Card/CardBody';
import CardHeader from '@/components/Card/CardHeader';
import CreditPlan from '@/components/CreditPlan/CreditPlan';
import CreditTransaction from '@/components/CreditTransaction/CreditTransaction';


interface CreditsCompProps {
    handleCreditBuyButtonOnClick: Function,
}

const CreditsComp = (props: CreditsCompProps) => {
    const {
        handleCreditBuyButtonOnClick
    } = props;

    const query = useFetchCurrentCredit(true)
    
    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			<Card colorMode="white">
				<CardHeader
                    className="credits_cardheader">
                    <Heading variant="pageHeader" mb="20px">
						My Credits
					</Heading>
				</CardHeader>

				<CardBody
					flexDirection="column">
					<Flex
                        className="total_credits_text"
                        fontSize="xl"
                        mb="1.5rem">
                        <Text
                            as="span"
                            color="gray.primaryText"
                            fontWeight="hairline"
                            me="0.5rem">Total available credits: </Text>
                        {query.isLoading ? <Spinner alignSelf="center" size="sm" color='blue.dark' /> : <Text as="b">{query?.data?.balance}</Text>}
                    </Flex>

                    {/* Buy credits/Transactions tab */}
                    <Tabs isFitted variant='enclosed'>
                        <TabList mb='1em'>
                            <Tab><Icon me="0.5rem" as={FaCircleCheck} />Buy more credits</Tab>
                            <Tab><Icon me="0.5rem" as={FaListUl} />Transactions</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <CreditPlan />
                            </TabPanel>
                            <TabPanel>
                                <CreditTransaction />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
				</CardBody>

			</Card>
		</Flex>
    )
}

export default CreditsComp;