import React, { useState } from "react";
// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Switch,
  Text,
  useColorModeValue,
  UnorderedList
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash, FaCheckCircle } from "react-icons/fa";

import { Link as RouterLink } from "react-router-dom";

import AuthNewNavbar from "@components/Navbars/AuthNewNavbar";

import { PASSWORD_RESET_TIMEOUT_DAYS } from "@/utils/globalConstants";


interface ResetPassConfirmState {
  password: string;
  passwordErrMsg: string;
  invalidPassErrMsgs: string[];
  confirmPassword: string;
  confirmPasswordErrMsg: string;
  pageErrMsg: string;
  isLoading: boolean;
  showSuccessMsg: boolean;
}

interface ForgotPassProps {
  resetPassConfirmState: ResetPassConfirmState;
  setResetPassConfirmState: Function;
  handleSubmit: Function;
}

function ForgotPasswordConfirmComp(props: ForgotPassProps) {
  const [showPass, setShowPass] = useState(false);
  const { resetPassConfirmState, setResetPassConfirmState, handleSubmit } =
    props;
  const {
    password,
    passwordErrMsg,
    invalidPassErrMsgs,
    confirmPassword,
    confirmPasswordErrMsg,
    pageErrMsg,
    isLoading,
    showSuccessMsg,
  } = resetPassConfirmState;

  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");

  return (
    <Flex position="relative" flexDirection="column">
      {/* {AuthNavbar with logo and brand} */}
      <Flex
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        p="48px"
        pt={{ sm: "100px", md: "0px" }}
        mt={{ md: "30px", lg: "30px" }}
        className="navbar-wrapper"
      >
        <AuthNewNavbar logoText="inback" />
      </Flex>

      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="center"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
        className="main-content"
      >
        {showSuccessMsg ? (
          <Flex
            direction="column"
            w={{ md: "50%", lg: "50%", sm: "100%" }}
            background="transparent"
            p="48px"
          >
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg" color="blueDark">
                Your password has been reset.
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Your password has been reset and you can login with the new password.
                Click <Link as={RouterLink} color="blueDark" ms="5px" fontWeight="bold" to="/auth/signin">here</Link> to sign in.
              </AlertDescription>
            </Alert>
          </Flex>
        ) : (
          <Flex
            direction="column"
            w={{ md: "50%", lg: "50%", sm: "100%" }}
            background="transparent"
            p="48px"
          >
            <Heading color="blueDark" fontSize="32px" mb="36px">
              Reset password
            </Heading>

            <Alert status='info' mb="10px" overflow="visible">
              <AlertIcon />
              <Text
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="14px">
                Password must be minimum 8 characters long, should not be common,
                must contain atleast one uppercase letter, one lowercase letter, and 
                one numeric character
              </Text>
            </Alert>

            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <InputGroup size="md" mb="5px">
                <Input
                  id="password_id"
                  borderRadius="15px"
                  fontSize="sm"
                  type={showPass ? "text" : "password"}
                  placeholder="New password"
                  size="lg"
                  errorBorderColor="red.300"
                  isInvalid={passwordErrMsg !== ""}
                  value={password}
                  onChange={(e) =>
                    setResetPassConfirmState({
                      ...resetPassConfirmState,
                      password: e.target.value,
                    })
                  }
                />
                <InputRightElement>
                  <Button 
                    h="1.75rem" 
                    size="sm" 
                    variant='ghost'
                    mt="10px" 
                    onClick={e => setShowPass(!showPass)}>
                    {showPass 
                        ? <Icon as={FaEyeSlash} /> 
                        : <Icon as={FaEye} />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Flex
                mb="20px"
                direction="column">
                
                {invalidPassErrMsgs.length > 0 && 
                  <Alert status='error'>
                    <AlertIcon />
                    <Text
                      ms="4px"
                      color={textColor}
                      fontWeight="bold"
                      fontSize="14px">
                        Password failed following validity checks:
                        <UnorderedList ml="10px">
                          {invalidPassErrMsgs.map(msg => <ListItem>{msg}</ListItem>)}
                        </UnorderedList>
                    </Text>
                  </Alert>}
              </Flex>

              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Confirm Password
              </FormLabel>
              <InputGroup size="md" mb="20px" flexDir="column">
                <Input
                  id="confirm_pass_id"
                  borderRadius="15px"
                  fontSize="sm"
                  type="password"
                  placeholder="Confirm new password"
                  size="lg"
                  errorBorderColor="red.300"
                  isInvalid={confirmPasswordErrMsg !== ""}
                  value={confirmPassword}
                  onChange={(e) =>
                    setResetPassConfirmState({
                      ...resetPassConfirmState,
                      confirmPasswordErrMsg: "",
                      confirmPassword: e.target.value,
                    })
                  }
                />
                {confirmPasswordErrMsg !== "" && (
                  <Text color="red.400" as="small">*{confirmPasswordErrMsg}</Text>
                )}
              </InputGroup>

              <Button
                fontSize="12px"
                type="submit"
                bg="blueDark" //'teal.300'
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={(e) => {
                  if (password !== confirmPassword) {
                    setResetPassConfirmState({
                      ...resetPassConfirmState,
                      confirmPasswordErrMsg: "Password does not match",
                    });
                    return;
                  }
                  handleSubmit();
                }}
              >
                SUBMIT
              </Button>
            </FormControl>
            
            <Flex
              flexDirection="column"
              justifyContent="between"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                No need to reset password?
                <Link
                  as={RouterLink}
                  color="blueDark"
                  ms="5px"
                  fontWeight="bold"
                  to="/auth/signin"
                >
                  Sign In
                </Link>
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default ForgotPasswordConfirmComp;
