import "./paginationContainer.css";

const PaginationContainer = (props: any) => {

    return (
        <div className="pagination-container">
            {props.children}
        </div>
    )
}

export default PaginationContainer;