import React from "react";

import { 
    Box, 
    Divider, 
    Flex, 
    FormControl, 
    FormLabel, 
    Heading,
    Input,
    Text
} from "@chakra-ui/react"

import BusinessLogoWrapper from "./BusinessLogoWrapper";


interface BusinessDetailsFormProps {
    businessName: string,
    businessLogo: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    businessNameError: string,
    businessLogoError: string,
    onBusinessNameChange: React.ChangeEventHandler<HTMLInputElement>,
    onBusinessLogoChange: React.ChangeEventHandler<HTMLInputElement>,
    onAddressLine1Change: React.ChangeEventHandler<HTMLInputElement>,
    onAddressLine2Change: React.ChangeEventHandler<HTMLInputElement>,
    onCityChange: React.ChangeEventHandler<HTMLInputElement>,
    onStateChange: React.ChangeEventHandler<HTMLInputElement>,
}

const BusinessDetailsForm = (props: BusinessDetailsFormProps) => {

    const {
        businessName,
        businessLogo,
        businessNameError,
        businessLogoError,
        onBusinessNameChange,
        onBusinessLogoChange,
    } = props;

    // const [isHovering, setIsHovering] = useState(false);
    // const logoInputRef = useRef();

    return (
        <Flex 
            className="personalform_container"
            flexDir="column">
            <Box mb="1rem">
              <Heading size="md" mb='10px' fontWeight='normal'>
                Business Details
              </Heading>
              <Divider /> 
            </Box>

            {/* businessLogo */}
            <FormControl
                mb="1.5rem">
                
                <Flex
                  className="businesslogo"
                  justify="center">
                  <BusinessLogoWrapper 
                    businessName={businessName}
                    businessLogo={businessLogo}
                    businessLogoError={businessLogoError}
                    onBusinessLogoChange={onBusinessLogoChange} />
                </Flex>

                <FormLabel ms='4px' fontSize='sm' fontWeight='normal' textAlign="center">
                  Business Logo
                </FormLabel>
                {businessLogoError?.length > 0 && 
                  <Text 
                    mb='0.5rem' 
                    fontSize='sm' 
                    textColor='red.300'
                    textAlign="center">*{businessLogoError}</Text>}
            </FormControl>

            {/* businessName */}
            <FormControl
                mb="2rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Business name*
                </FormLabel>
                <Input
                  id="businessname_id"
                  borderRadius='15px'
                  fontSize='sm'
                  type='email'
                  placeholder='Business name'
                  size='md'
                  errorBorderColor='red.300'
                  isInvalid={businessNameError?.length > 0}
                  value={businessName}
                  onChange={onBusinessNameChange}
                />
                {businessNameError?.length > 0 && 
                  <Text fontSize='sm' textColor='red.300'>*{businessNameError}</Text>}
            </FormControl>
            
            {/* Address */}
            {/* TODO: Currently it doesn't feel like we need to add address in a
              * registration form, it'll just make registration process long. 
              * Will find a way for them to add address
              */}
            {/* <Box mb="1rem">
              <Heading size="sm" mb='10px' fontWeight='normal' color="gray.textPrimary">
                <b>Address (Optional)</b>
              </Heading>
              <Divider /> 
            </Box>
            <FormControl
                mb="1rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Address line 1*
                </FormLabel>
                <Input
                  id="addressline1_id"
                  borderRadius='15px'
                  fontSize='sm'
                  type='email'
                  placeholder='Address line 1'
                  size='md'
                  errorBorderColor='red.300'
                  value={addressLine1}
                  onChange={onAddressLine1Change}
                />
            </FormControl>

            <FormControl
                mb="1rem">
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Address line 2*
                </FormLabel>
                <Input
                  id="addressline2_id"
                  borderRadius='15px'
                  fontSize='sm'
                  type='email'
                  placeholder='Address line 2'
                  size='md'
                  errorBorderColor='red.300'
                  value={addressLine2}
                  onChange={onAddressLine2Change}
                />
            </FormControl>

            <HStack spacing="20px" mb='1rem'>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  State*
                </FormLabel>
                <Input
                  id="state_id"
                  borderRadius='15px'
                  fontSize='sm'
                  type='text'
                  placeholder='State'
                  size='md'
                  errorBorderColor='red.300'
                  value={state}
                  onChange={onStateChange}
                />
              </FormControl>
              
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  city*
                </FormLabel>
                <Input
                  id="city_id"
                  borderRadius='15px'
                  fontSize='sm'
                  type='text'
                  placeholder='City'
                  size='md'
                  errorBorderColor='red.300'
                  value={city}
                  onChange={onCityChange}
                />
              </FormControl>
            </HStack> */}

        </Flex>
    )
}

export default BusinessDetailsForm;