import { 
    Flex,
    Select,
    Button,
    Text,
} from "@chakra-ui/react";
import { ChangeEventHandler, MouseEventHandler, ReactEventHandler } from "react";
import {
    FaArrowLeftLong,
    FaArrowRightLong
} from "react-icons/fa6";


interface AddEditModalFormNavProps {
    steps: {step: string, name: string, fieldErrors: string[]}[],
    totalSteps: number,
    currentStep: number,
    hasCurrentStepError: boolean,
    onClickNext: MouseEventHandler,
    onClickBack: MouseEventHandler,
    onChangeJumpTo: ChangeEventHandler,
}

const AddEditModalFormNav = (props: AddEditModalFormNavProps) => {
    const {
        steps,
        totalSteps,
        currentStep,
        hasCurrentStepError,
        onClickNext,
        onClickBack,
        onChangeJumpTo
    } = props;

    const boxShadow = "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, \
                        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"

    const isBackDisabled = () => {
        if (hasCurrentStepError) return true;
        return currentStep == 1 ? true : false;
    }

    const isNextDisabled = () => {
        if (hasCurrentStepError) return true;
        return currentStep == totalSteps ? true : false;
    }

    const isJumpToSelectDisabled = () => {
        if (hasCurrentStepError) return true;
        return false;
    }

    const getNextButtonBgColor = () => {
        if (hasCurrentStepError) return "red.400";
        return "blue.dark";
    }

    return (
        <Flex
            className="multistep-form-nav"
            flexDirection={{"base": "row"}}
            justify={{"base": "space-between"}}
            gap={{"base": "1rem"}}
            bg="white"
            p={{"base": "1rem"}}
            mb={{"base": "1rem"}}
            borderRadius={{"base": "0.5rem"}}
            boxShadow={{"base": boxShadow}}>
            <Text 
                color="gray.500">
                <Text as="sup"><b>{currentStep}</b></Text>/<Text as="sub">{totalSteps}</Text>
            </Text>

            <Select 
                value={currentStep}
                onChange={onChangeJumpTo}
                isDisabled={isJumpToSelectDisabled()}>
                {steps.map(step => <option value={step.step}>{step.name}</option>)}
            </Select>

            <Button 
                leftIcon={<FaArrowLeftLong />}
                isDisabled={isBackDisabled()}
                color="gray.600"
                w={{"base": "20rem"}}
                borderColor="black"
                borderWidth={{"base": "1px"}}
                onClick={onClickBack}>Back</Button>
            <Button 
                rightIcon={<FaArrowRightLong />}
                isDisabled={isNextDisabled()}
                color="gray.200"
                bg={getNextButtonBgColor()}
                _hover={{
                    color: "white"
                }}
                w={{"base": "20rem"}}
                onClick={onClickNext}>Next</Button>
        </Flex>
    );
}

export default AddEditModalFormNav;