import {
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

interface TablesTableRowProps {
  amount: number;
  balance: number;
  created_at: string;
  id: string;
  index: number;
  remark: string;
  transaction_type: string;
}

function TablesTableRow(props: TablesTableRowProps) {
  const { created_at, remark, amount, balance, transaction_type, id, index } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  // function to convert created_at to Asia/Kolkata time zone
  const getLocalDateTimeString = () => {
    let dateObj = new Date(created_at);
    const localTimezone = 'Asia/Kolkata';
    const localDate = dateObj.toLocaleString('en-US', { timeZone: localTimezone });
    const formattedDateString = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(new Date(localDate));
    return formattedDateString;
  }

  return (
    <Tr key={id}>
      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {index}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {getLocalDateTimeString()}
            </Text>
            {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {email}
            </Text> */}
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color="gray.textPrimary" fontWeight="bold">
            {remark}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {id}
          </Text>
        </Flex>
      </Td>
      <Td>
        {transaction_type === "DEBIT" && 
          <Badge
            bg="red.400"
            color="white"
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {transaction_type === "DEBIT" ? "-" + amount : ""}
          </Badge>
        }
      </Td>
      <Td>
      {transaction_type === "CREDIT" && 
          <Badge
            bg="green.400"
            color="white"
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {transaction_type === "CREDIT" ? "+" + amount : ""}
          </Badge>
        }
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover">
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            {balance}
          </Text>
        </Button>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
