import CreditsComp from "./CreditsComp"

const Credits = () => {

    const handleCreditBuyButtonOnClick = (planName: string, revenue: number, 
                                        credits: number) => {
    }
    return (
        <CreditsComp 
            handleCreditBuyButtonOnClick={handleCreditBuyButtonOnClick} />
    )
}

export default Credits;