import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import { noInternetAlert, getCookie, getTokenKey } from "@/utils/helpers";
import { BUSINESS_PROFILE_URL } from "@/utils/endpoints";
import { Business } from "@/utils/interfaces/business";
import BusinessProfileComp from "./BusinessProfileComp";
import { updateBusinsess } from "../business.slice";

const BusinessProfile = (props) => {
    const business = useSelector(state => state.business);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const profileInitState: Business = {
        id: business.id,
        createdAt: business.createdAt,
        updatedAt: business.updatedAt,
        name: business.name,
        logo: business.logo,
        logoFile: null,
        addressLine1: business.addressLine1,
        addressLine2: business.addressLine2,
        state: business.state,
        city: business.city,
        pin: business.pin,
        user: {
            firstName: business.user.firstName,
            lastName: business.user.lastName,
            email: business.user.email,
            phone: business.user.phone,
        }
    }

    // UI states
    const [profileFormState, setProfileFormState] = useState({...profileInitState});
    const [errorState, setErrorState] = useState({
        nameError: "",
        logoError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        stateError: "",
        cityError: "",
        pinError: "",
        firstNameError: "",
        lastNameError: "",

        generalErrorMsg: "",
    });
    const [uiState, setUiState] = useState({
        isLoading: false,
        isUpdating: false,
        generalSuccessMsg: ""
    })

    //////////////// helper functions ///////////////////////////////////////////////////
    const hasErrors = () => {
        Object.values(errorState).forEach(error => {
            if (error.length) {
                return true;
            }
        });
        
        return false;
    }
    ///////////////////////////////////////////////////////////////////////////////


    //////////////// useEffects ///////////////////////////////////////////////////
    useEffect(() => {
        if (!business.id) {
            fetchProfile();
        }
    }, []);

    useEffect(() => {
        setProfileFormState(profileInitState);
    }, [business])
    ///////////////////////////////////////////////////////////////////////////////


    //////////////// api handlers ///////////////////////////////////////////////////
    const fetchProfile = () => {
        setUiState({...uiState, isLoading: true});
        
        axios
          .get(BUSINESS_PROFILE_URL, {
            withCredentials: true,
            headers: {
                "Authorization": `Token ${getTokenKey()}`
            }
          })
          .then(response => {
            setUiState({...uiState, isLoading: false});
            
            // update reward program state
            dispatch(updateBusinsess(response.data));
          })
          .catch(error => {
            setUiState({...uiState, isLoading: false});
    
            if (error.response) {
              switch (error.response.status) {
                  case 404:
                    // signin page
                    return navigate("/");
                    break;
                  case 401:
                    // signin page
                    return navigate("/");
                  default:
                    if ("detail" in error.response.data) {
                      const detail: string = error.response.data.detail;
                      setErrorState({...errorState, generalErrorMsg: detail});
                    }
              }
            } else {
              if (!window.navigator.onLine) {
                noInternetAlert();
                return;
              }
    
              // 500 error or unreachable server error
              throw "Code 500: Internal server error occurred, please try again, or report error";
            }
        });
    }


    const updateProfile = () => {
        // updating UI loading state
        setUiState({...uiState, isUpdating: true});

        axios.put(BUSINESS_PROFILE_URL, {
            name: profileFormState.name,
            logo: profileFormState.logoFile,
            address_line1: profileFormState.addressLine1,
            address_line2: profileFormState.addressLine2,
            state: profileFormState.state,
            city: profileFormState.city,
            pin: profileFormState.pin,
            user: {
                first_name: profileFormState.user.firstName,
                last_name: profileFormState.user.lastName,
                email: profileFormState.user.email,
                phone: profileFormState.user.phoneNumber,
            }
        }, {
            withCredentials: true,
            headers: {
                "Authorization": `Token ${getTokenKey()}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            // updating UI loading state
            setUiState({...uiState, isUpdating: false});
            
            // updating reward program state
            let data = response.data;
            data.logo = import.meta.env.VITE_BE_DOMAIN + data.logo;
            dispatch(updateBusinsess(data));
            
            toast({
                title: 'Business profile updated successfully!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

        }).catch(errorRes => {
            // updating UI loading state
            setUiState({...uiState, isUpdating: false});

            const response = errorRes.response;
            
            if (response) {
            const detail = response.data?.detail;
            switch (response.status) {
                case 400:
                    if ("detail" in response.data) {
                        setErrorState({...errorState, generalErrorMsg: detail});
                    }else if ("non_field_errors" in response.data) {
                        setErrorState({...errorState, generalErrorMsg: response.data?.non_field_errors[0]});
                    }else {
                        let error: any = {};
                        error["firstNameError"] = response.data.first_name ? response.data.first_name[0] : "";
                        error["lastNameError"] = response.data.last_name ? response.data.last_name[0] : "";
                        error["nameError"] = response.data.name ? response.data.name[0] : "";
                        error["logoError"] = response.data.logo ? response.data.logo[0] : "";
                        error["addressLine1Error"] = response.data.address_line1 ? response.data.address_line1[0] : "";
                        error["addressLine2Error"] = response.data.address_line2 ? response.data.address_line2[0] : "";
                        error["stateError"] = response.data.state ? response.data.state[0] : "";
                        error["cityError"] = response.data.city ? response.data.city[0] : "";
                        error["pinError"] = response.data.pin ? response.data.pin[0] : "";
                        
                        setErrorState({...errorState, ...error});
                    }
                    break;
                case 401:
                    // redirect to login
                    return navigate("/");
                    break;
                default:
                // other errors
                if ("detail" in response.data) {
                    setErrorState({...errorState, generalErrorMsg: detail});
                }
        
            }
            } else {
                if (!window.navigator.onLine) {
                    noInternetAlert();
                    return;
                }

                // 500 error or unreachable server error
                throw "Code 500: Internal server error occurred, please try again, or report error";
            }
        })
    }
    ///////////////////////////////////////////////////////////////////////////////


    //////////////// change handlers ///////////////////////////////////////////////////
    const handleFirstNameChange = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, firstNameError: "*This field is required"});
        } else {
            setErrorState({...errorState, firstNameError: ""});
        }

        setProfileFormState({
            ...profileFormState,
            user: {...profileFormState.user, firstName: value}
        });
    }

    const handleLastNameChange = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, lastNameError: "*This field is required"});
        } else {
            setErrorState({...errorState, lastNameError: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            user: {...profileFormState.user, lastName: value}
        });
    }

    const handleLogoImageChange = (event: ChangeEvent )=> {
        event.preventDefault();
        let imageFile = event.target?.files[0];
        if (imageFile) {
            const localImageUrl = URL.createObjectURL(imageFile);
            if ((imageFile.size /1024 /1024) > 10.0) {
                setErrorState({...errorState, logoError: "*Image file size cannot exceed 10 MB"});
            } else {
                setErrorState({...errorState, logoError: ""});
            }
            
            setProfileFormState({
                ...profileFormState,
                logo: localImageUrl,
                logoFile: imageFile
            });
        }
    }

    const handleBusinessNameChange = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, nameError: "*This field is required"});
        } else {
            setErrorState({...errorState, nameError: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            name: value
        });
    }

    const handleAddressLine1Change = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, addressLine1Error: "*This field is required"});
        } else {
            setErrorState({...errorState, addressLine1Error: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            addressLine1: value
        });
    }

    const handleAddressLine2Change = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, addressLine2Error: "*This field is required"});
        } else {
            setErrorState({...errorState, addressLine2Error: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            addressLine2: value
        });
    }

    const handlePinChange = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, pinError: "*This field is required"});
        } else {
            setErrorState({...errorState, pinError: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            pin: value
        });
    }

    const handleStateChange = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, stateError: "*This field is required"});
        } else {
            setErrorState({...errorState, stateError: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            state: value
        });
    }

    const handleCityChange = (event: ChangeEvent) => {
        const value = event.target.value;
        if (!value.length) {
            setErrorState({...errorState, cityError: "*This field is required"});
        } else {
            setErrorState({...errorState, cityError: ""});
        }
        
        setProfileFormState({
            ...profileFormState,
            city: value
        });
    }

    const handleSaveButtonOnClick = (event: MouseEvent) => {
        updateProfile();
    }
    ///////////////////////////////////////////////////////////////////////////////

    return (
        <BusinessProfileComp 
            profileFormState={profileFormState}
            errorState={errorState}
            uiState={uiState}
            handleFirstNameChange={handleFirstNameChange}
            handleLastNameChange={handleLastNameChange}
            handleLogoImageChange={handleLogoImageChange}
            handleBusinessNameChange={handleBusinessNameChange}
            handleAddressLine1Change={handleAddressLine1Change}
            handleAddressLine2Change={handleAddressLine2Change}
            handlePinChange={handlePinChange}
            handleCityChange={handleCityChange}
            handleStateChange={handleStateChange}
            handleSaveButtonOnClick={handleSaveButtonOnClick} />
    );
}

export default BusinessProfile;