import { useState } from "react";

import { useToast } from "@chakra-ui/react";

import { useFetchRewardCards, useFetchLoyaltyProgram } from "@/hooks";
import RewardCardComp from "./RewardCardComp";

const RewardCard = () => {
    const toast = useToast();

    const [rewardProgramId, setRewardProgramId] = useState(null);

    const rewardProgramQuery = useFetchLoyaltyProgram(true, {
        retry: 1,
        onError: (error: any) => {
            if (error.response) {
                let response = error.response;
                if ("non_field_error" in response.data) {
                    toast({
                        title: 'Error occurred while fetching reward program',
                        description: response.data["non_field_error"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }else {
                throw "Error occurred while trying to fetch coupons";
            }
        },
        onSuccess: (data: any) => {
            setRewardProgramId(data.id);
        }
    });

    const rewardCardQuery = useFetchRewardCards(true, {
        retry: 1,
        onError: (error: any) => {
            if (error.response) {
                let response = error.response;
                if ("non_field_error" in response.data) {
                    toast({
                        title: 'Error occurred while fetching coupons',
                        description: response.data["non_field_error"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }else {
                throw "Error occurred while trying to fetch coupons";
            }
        }
    });

    return (
        <RewardCardComp
            isLoading={rewardCardQuery.isLoading || rewardProgramQuery.isLoading}
            rewardProgramId={rewardProgramId}
            rewardCards={rewardCardQuery.data?.results} />
    )
}

export default RewardCard;