import { useEffect, useState } from "react";

import { 
    Button,
    Flex,
    Heading,
    Text,
} from "@chakra-ui/react";
import OtpInput from 'react-otp-input';

import { DEFAULT_OTP_LENGTH } from "@utils/globalConstants";

import useTimer from "@/hooks/useTimer";
import useSendGenericOTPMutation from "@/hooks/mutations/useSendGenericOTPMutation";
import useVerifyGenericOTPMutation from "@/hooks/mutations/useVerifyGenericOTPMutation";


interface OTPInputProps {
    isOpen: boolean,
    timerSeconds: number,
    submitButtonText: string,
    onSuccess: Function,
    onSendOTPError: Function,
    onVerifyOTPError: Function,
}

const ConfirmActionOTPInput = (props: OTPInputProps) => {
    const {
        isOpen,
        submitButtonText="Submit",
        timerSeconds,
        onSuccess,
        onSendOTPError,
        onVerifyOTPError,
    } = props;

    const [, forceRender] = useState("");
    const [startTimer, setStartTimer] = useState(false);
    const [otp, setOtp] = useState("");
    const [key, setKey] = useState("");

    const [counter, resetTimer] = useTimer(
        () => {},
        timerSeconds,
        startTimer
    )

    const resetOtpAndKey = () => {
        setOtp("");
        setKey("");
    }
    
    const sendQuery = useSendGenericOTPMutation({
        onSuccess: (response: any) => {
            const data = response.data;
            setKey(data.key);
            setStartTimer(true);

            // @ts-ignore
            resetTimer();
        },
        onError: (error: any) => {
            onSendOTPError(error);
        }
    });

    const verifyQuery = useVerifyGenericOTPMutation({
        onSuccess: (data: any) => {
            onSuccess(data);
        },
        onError: (error: any) => {
            onVerifyOTPError(error);
        }
    });

    useEffect(() => {
        if (isOpen) {
            sendQuery.mutate({"mode": "phone"});
        } else {
            // reset OTP and key
            resetOtpAndKey();
        }
    }, [isOpen]);

    // set startTimer to false
    useEffect(() => {
        if (counter <= 0) {
            setStartTimer(false);
            resetOtpAndKey();
        }
    }, [counter]);

    const isSubmitButtonDisabled = () => {
        return sendQuery.isLoading || verifyQuery.isLoading
                ? true 
                : counter <= 0 || otp.length !== DEFAULT_OTP_LENGTH;
    }

    const isResendButtonDisabled = () => {
        return sendQuery.isLoading || verifyQuery.isLoading ? true : counter > 0;
    }

    const isOtpInputFieldDisabled = () => {
        return sendQuery.isLoading || verifyQuery.isLoading ? true : counter <= 0;
    }

    return (
        <Flex
            className="input-container"
            flexDir="column"
            px="1rem"
            py="0.5rem">
            <Flex 
                className="input-header"
                flexDir="column"
                mb="1rem">
                <Heading
                    size="sm"
                    mb="0.5rem"
                    textAlign="center">Please confirm this action</Heading>
                <Text textAlign="center">
                    Enter the verification code we sent on your registered phone number
                </Text>
            </Flex>

            <Flex
                className="dialog-otp-input-group"
                flexDir="column"
                justify="center"
                mb="0">
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={DEFAULT_OTP_LENGTH}
                    renderSeparator={<span>-</span>}
                    containerStyle={{
                        justifyContent: "center",
                        gap: "1rem",
                        marginBottom: "0.5rem",
                    }}
                    inputStyle={{    
                        "border": "1px solid lightgray",
                        borderRadius: "5px",
                        "width": "2rem",
                        "height": "2.5rem"}}
                    renderInput={(props) => <input {...props} {...{disabled: isOtpInputFieldDisabled()}} />}
                />

                <Button
                    bg="blue.dark"
                    color="gray.200"
                    w="100%"
                    borderRadius="5px"
                    isLoading={verifyQuery.isLoading}
                    isDisabled={isSubmitButtonDisabled()}
                    _hover={{
                        color: "white"
                    }}
                    onClick={event => {
                        verifyQuery.mutate({"otp": otp, "key": key});
                    }}>{submitButtonText}</Button>
            </Flex>
            <Flex
                justify="start"
                p="0"
                // m="0"
                mb="1rem"
                h="1rem">
                {counter !== 0 && 
                    <Text as="b" color="gray.textPrimary">{counter}</Text>
                }
            </Flex>

            
            <Flex
                className="dialog-otp-resend"
                flexDir="column"
                justify="center"
                mb="1rem">
                <Text textAlign="center" color="gray.textPrimary" as="span">
                    Didn't recieve code?
                </Text>
                <Button
                    bg=""
                    border=""
                    color="red.600"
                    height="fit-content"
                    _hover={{
                        bg: ""
                    }}
                    onClick={event => {
                        sendQuery.mutate({"mode": "phone"});
                    }}
                    isLoading={sendQuery.isLoading}
                    isDisabled={isResendButtonDisabled()}>
                    Resend
                </Button>
            </Flex>
          </Flex>
    );
}

export default ConfirmActionOTPInput;