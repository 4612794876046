import React, { ChangeEvent, ChangeEventHandler, MouseEventHandler, useState } from "react";
// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    Skeleton,
    useColorModeValue,
  } from "@chakra-ui/react";
  // assets
  import peopleImage from "@assets/img/people-image.png";
  import logoChakra from "@assets/svg/logo-white.svg";
  import BarChart from "@components/Charts/BarChart";
  import LineChart from "@components/Charts/LineChart";
  // Custom icons
  import {
	RupeeIcon,
	CustomersIcon,
	CouponsIcon,
	ConsumedCouponsIcon
  } from "@components/Icons/Icons";
  import { FaBell, FaStar } from "react-icons/fa6";

  import MiniStatistics from "./components/MiniStatistics";
  import CustomerJourneyOverview from "./components/CustomerJourneyOverview";
  import PurchaseEntry from "./components/PurchaseEntry";
  import SelectRewardCard from "./components/SelectRewardCards";
  import PreviewRewardModal from "@/components/PreviewRewardModal/PreviewRewardModal";
  import AlertNoCouponDialog from "./components/AlertNoCouponDialog";

  import { Purchase, MiniStatisticData, UIState } from "./intefaces";
  import { CURRENCY_HTML_SYMBOL } from "@/utils/globalConstants";
  
  
  interface DashboardCompProps {
    purchase: Purchase,
    miniStatData: MiniStatisticData,
    uiState: UIState,
    fetchProgramData: any,
    fetchRewardCardsData: any,
    fetchMetricData: any,
    fetchPurchaseListData: any,
    verifyRewardCardQuery: any,

    handleAmountChange: ChangeEventHandler,
    handlePhoneChange: ChangeEventHandler,
    handleVerifCodeChange: ChangeEventHandler,
    handleDirectCardCheckboxChange: MouseEventHandler,
    handlePurchaseEntrySubmitButtonClick: MouseEventHandler<any>,
    handleSelectRewardCard: MouseEventHandler<any>,
    handleRemoveSelectedCard: MouseEventHandler<any>,
    handleProgramStatusOnChange: MouseEventHandler<any>,
    handlePurchaseResetOnClick: MouseEventHandler,

    PurchaseConfirmationDialog: React.FC,
  }

  export default function DashboardComp(props: DashboardCompProps) {
    const {
      purchase,
      uiState,
      fetchProgramData,
      fetchRewardCardsData,
      fetchMetricData,
      fetchPurchaseListData,
      verifyRewardCardQuery,

      handleAmountChange,
      handlePhoneChange,
      handleVerifCodeChange,
      handleDirectCardCheckboxChange,
      handlePurchaseEntrySubmitButtonClick,
      handleSelectRewardCard,
      handleRemoveSelectedCard,
      handleProgramStatusOnChange,
      handlePurchaseResetOnClick,

      PurchaseConfirmationDialog
    } = props;

    const {
      isFetching,
      isError,
      data,
      error
    } = fetchMetricData;

	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
	const [customerRewardId, setCustomerRewardId] = useState("");
	const [prevRewardCardId, setPrevRewardCardId] = useState("");

	const handleOpenPreviewModal = (rewardId: string, type: string = "customerReward") => {
		if (type === "customerReward"){
			setCustomerRewardId(rewardId);
		} else if (type === "rewardCard") {
			setPrevRewardCardId(rewardId);
		}
		
		if (!isPreviewModalOpen) {
			setIsPreviewModalOpen(true);
		}
	}

	const handleClosePreviewModal = (event) => {
		setCustomerRewardId("");
		setIsPreviewModalOpen(false);
	}

	const getPurchaseTimelineRowData = () => {
		let rowList = [];
		const fetchData = fetchPurchaseListData?.data;
		const data = fetchData && fetchData.length > 0 ? fetchData : [];
		for (let item of data) {
			let row = {};

			row["title"] = CURRENCY_HTML_SYMBOL + (item.discounted_amount ? item.discounted_amount : item.amount);
			row["key"] = item.id;
			row["date"] = item.created_at;
			row["logo"] = item.dispatched_rewards.length > 0 ? FaStar : FaBell;
			row["color"] = item.dispatched_rewards.length > 0 ? "blue.dark" : "orange";
			// row["index"] = "";
			rowList.push(row);
		}
		return rowList;
	}

    const iconBoxInside = useColorModeValue("white", "white");
  
    return (
      <Flex 
	  	flexDirection='column' 
		pt={{ base: "120px", md: "75px" }}
		position="relative">

        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>

          <Skeleton 
            isLoaded={!isFetching}
            borderRadius="15px">
            <MiniStatistics
              title={"Retention revenue"}
              amount={`${CURRENCY_HTML_SYMBOL} ${data?.total_retention}`}
              // percentage={55}
              icon={<RupeeIcon h={"15px"} w={"24px"} color={iconBoxInside} />}
            />
          </Skeleton>

          <Skeleton 
            isLoaded={!isFetching}
            borderRadius="15px">
            <MiniStatistics
              title={"Loyalty customers"}
              amount={data?.total_customers}
              // percentage={5}
              icon={<CustomersIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
          </Skeleton>

          <Skeleton 
            isLoaded={!isFetching}
            borderRadius="15px">
            <MiniStatistics
              title={"Total coupons"}
              amount={data?.total_coupons}
              // percentage={-14}
              icon={<CouponsIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
          </Skeleton>

          <Skeleton 
            isLoaded={!isFetching}
            borderRadius="15px">
            <MiniStatistics
              title={"Coupons consumed"}
              amount={data?.total_consumed_coupons}
              // percentage={""}
              icon={<ConsumedCouponsIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
          </Skeleton>

        </SimpleGrid>

        <Grid
			className="dash_purchase_grid"
			templateColumns={{ md: "1fr", lg: "1fr 1fr 1fr" }}
			templateRows={{ md: "1fr auto", lg: "1fr" }}
			my='26px'
			gap='15px'>

			<PurchaseEntry 
				uiState={uiState}
				fetchProgramData={fetchProgramData}
				purchaseState={purchase}
        verifyRewardCardQuery={verifyRewardCardQuery}
				handleAmountChange={handleAmountChange}
				handlePhoneChange={handlePhoneChange}
				handleVerifCodeChange={handleVerifCodeChange}
				handleDirectCardCheckboxChange={handleDirectCardCheckboxChange}
				handlePurchaseEntrySubmitButtonClick={handlePurchaseEntrySubmitButtonClick}
				handleOpenPreviewModal={handleOpenPreviewModal}
				handleProgramStatusOnChange={handleProgramStatusOnChange}
				handlePurchaseResetOnClick={handlePurchaseResetOnClick} />
			
			<SelectRewardCard 
				uiState={uiState}
				selectedCards={uiState.selectedCards}
				fetchRewardCardsData={fetchRewardCardsData}
				handleSelectRewardCard={handleSelectRewardCard}
				handleRemoveSelectedCard={handleRemoveSelectedCard}
				handleOpenPreviewModal={handleOpenPreviewModal} />

			<CustomerJourneyOverview
				isFetching={fetchPurchaseListData.isFetching}
				title={"Loyalty program journey"}
				data={getPurchaseTimelineRowData()}
				phone={purchase.phone}
				/>
        </Grid>

		<PreviewRewardModal 
			isOpen={isPreviewModalOpen}
			customerRewardId={customerRewardId}
			prevRewardCardId={prevRewardCardId}
			onOpen={handleOpenPreviewModal}
			onClose={handleClosePreviewModal} />

		<PurchaseConfirmationDialog />

		{fetchRewardCardsData?.data?.results?.length === 0 &&
			<AlertNoCouponDialog />}
      </Flex>
    );
  }
  