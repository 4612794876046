import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getCookie,getTokenKey } from "@/utils/helpers";
import { REWARD_CARD_DETAIL_URL } from "@/utils/endpoints";


const useUpdateCouponMutation = (cardId: string, extraArgs: {} = {}) => {
    const navigate = useNavigate();
    const mutationRes = useMutation({
        mutationFn: (data) => {
            return axios.put(REWARD_CARD_DETAIL_URL(cardId), data, {
                withCredentials: true,
                headers: {
                    "Authorization": `Token ${getTokenKey()}`,
                    "Content-Type": "multipart/form-data"
                }
            })
        },
        ...extraArgs
    });

    // navigate to signin page when 401 status is recieved in status
    if (mutationRes.error && mutationRes.error.request.status === 401) {
        navigate("/");
    }
    return mutationRes;
}

export default useUpdateCouponMutation;