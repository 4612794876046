import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { Business } from '@/utils/interfaces/business'


const initialState: Business  = {
    id: "",
    createdAt: "",
    updatedAt: "",
    name: "",
    logo: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    pin: "",
    user: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    }
}

export const businessSlice = createSlice({
    name: "business",
    initialState,
    reducers: {
        updateBusinsess: (state, action: PayloadAction<Business>) => {
            state.id = action.payload.id;
            state.createdAt = action.payload.created_at;
            state.updatedAt = action.payload.updated_at;
            state.name = action.payload.name;
            state.logo = action.payload.logo;
            state.addressLine1 = action.payload.address_line1;
            state.addressLine2 = action.payload.address_line2;
            state.state = action.payload.state;
            state.city = action.payload.city;
            state.pin = action.payload.pin;

            if ("user" in action.payload) {
                state.user.firstName = action.payload.user.first_name;
                state.user.lastName = action.payload.user.last_name;
                state.user.email = action.payload.user.email;
                state.user.phone = action.payload.user.phone;
            }
        }
    }
})

export const { updateBusinsess } = businessSlice.actions;

export default businessSlice.reducer;

