import { 
    Flex,
    Text,
    Icon,
    Box 
} from "@chakra-ui/react"

import { FaCircleInfo } from "react-icons/fa6"

const InfoBlock = ({ description }: {description: string}) => {
    return (
        <Flex
            className="pc_businesslogo_info"
            flexDir={{base: "row"}}
            justify="start"
            mb="1rem">
            <Text
                color="blue.dark"
                fontSize={{"base": "1xl"}}>
                <Icon as={FaCircleInfo} />
            </Text>

            <Box 
                className="pc_businesslogo_info_text"
                color="blue.dark" 
                fontSize={{"base": "sm"}}
                ml="0.5rem">
                <Text>{description}</Text>
            </Box>
        </Flex>
    )
}

export default InfoBlock;