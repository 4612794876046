export const DEFAULT_COUNTRY_CODE: string = '+91';
export const PASSWORD_RESET_TIMEOUT_DAYS = 3; // default set by Django
export const DEFAULT_OTP_LENGTH = 6;
export const VERIFICATION_CODE_LENGTH = 6;
export const OTP_TIME_INTERVALS = 90;

export const CURRENCY = 'INR';
export const CURRENCY_HTML_SYMBOL = "₹";

// the Aspect ratio is 16:3
export const PreviewCardBannerWidth = '1440px';
export const PreviewCardBannerHeight = '850px';

export const DEFAULT_BANNER_IMAGE = import.meta.env.VITE_BE_DOMAIN + '/media/reward_cards/bg_image/default.png'
export const DEFAULT_LOGO_IMAGE = import.meta.env.VITE_BE_DOMAIN + '/media/business_logos/default.png'

// mapped after backend rewards models 
export const REWARD_PROGRAM_STATUS_CHOICES = {
      0: "paused",
      1: "active"
}