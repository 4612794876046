import { useEffect } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Spinner,
    Text,
    Icon,
    Box
} from '@chakra-ui/react';

import { ErrorBoundaryLogo } from "@/components/ErrorMessage/ErrorMessage";
import PreviewRewardCard from '../PreviewRewardCard/PreviewRewardCard';
import { useRetrieveCustomerReward, useRetrieveRewardCard } from "@/hooks";


const PreviewRewardModal = (props) => {
    const {
        isOpen,
        customerRewardId,
        prevRewardCardId,
        onClose,
        onOpen,
        customerRewardQuery
    } = props;

    const fetchCustomerRewardQuery = useRetrieveCustomerReward(false, customerRewardId);
    const fetchRewardCardQuery = useRetrieveRewardCard(false, prevRewardCardId);

    const { isLoading, isError, isSuccess, error, data, refetch, fetchStatus } = customerRewardId.length ? fetchCustomerRewardQuery : fetchRewardCardQuery;

    useEffect(() => {
        if (isOpen && (customerRewardId.length > 0 || prevRewardCardId.length > 0)) {
            refetch();
        }
    }, [isOpen]);
    
    const getErrorMessage = () => {
        if (error?.response) {
            let res = error?.response;
            if ("detail" in res.data) {
                
            }
        } else if (error?.request) {
            throw "No response while trying to verify coupon";
        } else {
            throw "Status code 500, Internal server error";
        }
    }

    const getLoadingSpinner = () => {
        return (
            <Flex
                flexDirection="column"
                w="100%"
                justify="center"
                textAlign="center"
                color="gray.500">
                <Spinner size="sm" alignSelf="center" />
                <Text as="small">Loading reward</Text>
            </Flex>
        )
    }

    const getErrorMsg = (errorMsg: string) => {
        <Flex
            flexDirection="column"
            justify="center" >
            <Text
                textAlign="center"
                mb="1rem">
                <Icon 
                    as={ErrorBoundaryLogo}
                    fontSize="md"
                    fill="gray.500" />
                {/* <ErrorBoundaryLogo
                    color="gray.500"
                    fontSize={{"base": "5rem"}} /> */}
            </Text>
            <Text
                color="gray.500"
                textAlign="center">Something doesn't seem right:</Text>
                <Box 
                    color="red.400"
                    textAlign="center"
                    maxHeight={{"base": "300px"}}
                    overflowY="auto">{error}</Box>
        </Flex>
    }
    
    const getRwardJSX = () => {
        if (isLoading) {
            return getLoadingSpinner();
        }

        if (isError) {
            let errorMsg = "";
            if (error.response) {
                let res = error.response;
                if ("detail" in res.data) {
                    errorMsg = res.data["detail"]
                }
            } else if (error.request) {
                errorMsg = "Error: Server didn't return a response"
            } else {
                errorMsg = "Status code 500, Internal server error";
            }
            return getErrorMsg(errorMsg);
        }

        if (isSuccess && Object.keys(data).length > 0) {
            let rewardJSON = {};

            if (customerRewardId.length){
                rewardJSON = JSON.parse(data.reward_json);
            } 
            if (prevRewardCardId.length) {
                rewardJSON = data;
            }
            return (
                <PreviewRewardCard 
                    rewardCard={rewardJSON}
                    isCreateEditMode={false} />
            )
        }
    }

    return (
        <Modal
            onClose={onClose}
            // finalFocusRef={btnRef}
            isOpen={isOpen}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent
                w="fit-content">
                <ModalHeader>Reward preview</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex
                        className='modalcontainer'>
                            { getRwardJSX() }
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default PreviewRewardModal;