import {
    Grid,
    GridItem, 
    Skeleton 
} from "@chakra-ui/react";

export const CreditPlanSkeletonLoading = () => {
    return (
        <Grid
            id="creditplanskeletonloading"
            gap={1}
            alignItems="stretch"
            templateColumns={{base: "repeat(1, 1fr)", lg: "repeat(4, 1fr)", md: "repeat(4, 1fr)", xl: "repeat(4, 1fr)", sm: "repeat(1, 1fr)"}}>
            {/* render it four times */}
            {[1, 2, 3, 4]
                .map((val) => {
                    return (
                        <GridItem>
                            <Skeleton 
                                borderRadius="8px"
                                height={{"base": "200px"}}/>
                        </GridItem>
                    )
                })}
        </Grid>
    )
}

export default CreditPlanSkeletonLoading;