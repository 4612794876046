import {
    Button,
    Flex,
    Heading,
    Text
} from "@chakra-ui/react";

import { CreditPlanItem as CreditPlanItemInterface } from "@/utils/interfaces/creditPlan";
import { CURRENCY_HTML_SYMBOL } from "@/utils/globalConstants";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";


interface CreditPlanItemProps extends CreditPlanItemInterface {
    isBuyButtonLoading: boolean,
    isDisabled: boolean
}

const CreditPlanItem = (props: CreditPlanItemProps) => {
    const {
        planName,
        revenue,
        credits,
        amountPerCredit,
        isBuyButtonLoading,
        isDisabled,
        onBuyButtonClick
    } = props;

    let headingTextColor = "black";
    let creditsTextColor = "blue.dark";
    let revenueTextColor = "black";

    if (isDisabled) {
        headingTextColor = "gray.textPrimary";
        creditsTextColor = "gray.textPrimary";
        revenueTextColor = "gray.textPrimary";
    }

    return (
        <Card
            key={planName}
            className="creditplanitem"
            flexDirection="column"
            borderWidth="1px"
            mb="1rem">
            <CardHeader
                className="creditplanitem_header"
                justifyContent="center">
                <Heading 
                    size="md" 
                    fontWeight="normal"
                    textAlign="center" 
                    mb="1rem" 
                    color={headingTextColor}>
                        {planName}
                </Heading>
            </CardHeader>
            <CardBody
                className="creditplanitem_body"
                flexDirection="column"
                justifyContent="center">
                <Heading 
                    className="creditplanitem_credits"
                    size="md" 
                    textAlign="center"
                    color={creditsTextColor}
                    mb="2rem">* {credits} credits</Heading>
                <Text
                    fontWeight="thin"
                    fontSize="larger"
                    textAlign="center"
                    color={revenueTextColor}>{CURRENCY_HTML_SYMBOL} {revenue}</Text>
                <Text
                    as="small"
                    fontWeight="thin"
                    textAlign="center"
                    mb="0.5rem"
                    color={revenueTextColor}>({CURRENCY_HTML_SYMBOL}{amountPerCredit} per credit)</Text>
                <Button
                    className="creditplanitem_buybutton"
                    variant="primaryBlue"
                    fontSize="lg"
                    isLoading={isBuyButtonLoading}
                    isDisabled={isDisabled}
                    onClick={event => onBuyButtonClick(revenue, credits, planName)}>Buy</Button>
            </CardBody>
        </Card>
    );
}

export default CreditPlanItem;