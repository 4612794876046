// Chakra imports
import { Flex, Text, useColorModeValue, Icon, Skeleton, Stack } from "@chakra-ui/react";
// Custom components
import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import TimelineRow from "@components/Tables/TimelineRow";
import React from "react";

import { isPhoneValid } from "@/utils/helpers";
import { NoListIcon } from "@/components/Icons/Icons";
import { FaMobileScreen, FaNotEqual } from "react-icons/fa6";

const CustomerJourneyOverview = ({ isFetching, title, data, phone }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [isPhoneNumValid, errors] = isPhoneValid(phone);

  const getSkeletonLoader = () => {
	return (
		<Stack w="100%" spacing="2rem">
			<Skeleton height="20px" />
			<Skeleton height="20px" />
			<Skeleton height="20px" />
			<Skeleton height="20px" />
			<Skeleton height="20px" />
		</Stack>
	)
  }

  return (
    <Card 
		maxH='100%'
		h="26rem"
		overflow="auto">
      <CardHeader>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          {/* <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            <Text fontWeight='bold' as='span' color='teal.300'>
              {`${amount}%`}
            </Text>{" "}
            this month.
          </Text> */}
        </Flex>
      </CardHeader>
      <CardBody 
	  	className="cardbody"
	  	ps='20px' 
		pe='0px' 
		mt="1rem" 
		mb='31px' 
		position='relative'>
		{isFetching && getSkeletonLoader()}
	
        {!isFetching && !isPhoneNumValid && 
          <Flex 
		  	flexDirection="column"
			justify="center"
			textAlign="center"
			mt="6rem">
			<Text color="gray.500" fontSize="2xl"><Icon as={FaMobileScreen} /></Text>
            <Text color="gray.500" fontSize="sm">Enter a valid phone number to see journey</Text>
          </Flex>}

		  {!isFetching && isPhoneNumValid && data.length == 0 &&
			<Flex 
				flexDirection="column"
				justify="center"
				textAlign="center"
				mt="6rem">
				<Text color="gray.500" fontSize="2xl"><Icon as={NoListIcon} /></Text>
				<Text color="gray.500" fontSize="sm">The customer ({phone}) doesn't have any journey yet</Text>
			</Flex>}

		{!isFetching && isPhoneNumValid && data.length > 0 &&
			<Flex direction='column'>
			{data.map((row, index, arr) => {
				return (
				<TimelineRow
					key={row.key}
					logo={row.logo}
					title={row.title}
					date={row.date}
					color={row.color}
					index={index}
					arrLength={arr.length}
				/>
				);
			})}
			</Flex>}
      </CardBody>
    </Card>
  );
};

export default CustomerJourneyOverview;
