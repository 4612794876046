import { 
    Flex,
    Text,
    Heading,
    Avatar
} from "@chakra-ui/react";

import { getFutureDate } from "@/utils/helpers";
import { RewardCard } from "@/utils/interfaces/rewardProgram";


interface PreviewRewardCardProps {
    rewardCard: RewardCard,
    isCreateEditMode: boolean,
    cardWidth: number,
}

const PreviewRewardCard = (props: PreviewRewardCardProps) => {
    const { rewardCard, isCreateEditMode, cardWidth=2.2 } = props;
    const verificationId = "908105";
    const {
        bannerImage='https://images.unsplash.com/photo-1610832958506-aa56368176cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80',
        businessLogo='https://bit.ly/code-beast',
        themeColor,
        fontColor,
        minPurchaseAmount=1999,
        offerText,
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod eligendi autem voluptate omnis molestiae cupiditate accusamus ducimus praesentium numquam saepe error, voluptas aut in consequuntur explicabo quisquam dolores voluptates unde!",
        cardType=1,
        amount,
        percent,
        tnc,
        validityDurationType=0,
        validityDays=25,
        validityStartDate,
        validityEndDate,
    } = rewardCard;

    const previewCardBoxShadow = "rgba(0, 0, 0, 0.25) 0px 54px 55px, \
                                rgba(0, 0, 0, 0.12) 0px -12px 30px, \
                                rgba(0, 0, 0, 0.12) 0px 4px 6px, \
                                rgba(0, 0, 0, 0.17) 0px 12px 13px, \
                                rgba(0, 0, 0, 0.09) 0px -3px 5px;"
    const previewCardWidth = 9 * cardWidth;
    const previewCardHeight = 16 * cardWidth;

    const previewCardBannerHeight = previewCardHeight / 3;

    const MAX_HEADING_CHAR_LIMIT = 30;
    const MAX_SUBHEAD_CHAR_LIMIT = 30;
    const MAX_DESC_CHAR_LIMIT = 100;

    const setAvatarTopAndLeft = () => {
        const banner = document.querySelector("#preview_card_banner");
        const avatar = document.querySelector("#preview_card_avatar");
        if (banner && avatar) {
            const bannerW = banner.offsetWidth;
            const bannerH = banner.offsetHeight;
            const avatarW = avatar.offsetWidth;
            const avatarH = avatar.offsetHeight;

            const avatarCenterTop = bannerH - avatarH;
            const avatarCenterLeft = bannerW/2 - avatarW/2;
            avatar.style.left = '5px';
            avatar.style.top = avatarCenterTop + 'px';
            return [avatarCenterTop, avatarCenterLeft];
        }
        return [0, 0]
    }

    const getValidityText = () => {
        if (validityDurationType == 0) { // validity type is "Validity days"
            // get date from today to n days in the future
            const currentDate = new Date();
            const endDate = getFutureDate(validityDays);
            return (
                <Text textAlign="center" fontSize={{"base": "sm"}}>
                    Valid from <b>{currentDate.toLocaleDateString("en-in")}</b> to <b>{endDate.toLocaleDateString("en-in")}</b>
                </Text>
            )
        }else if (validityDurationType == 1) {
            // validity type is "Validity dates"
            const startDate = new Date(validityStartDate);
            const endDate = new Date(validityEndDate);
            return (
                <Text textAlign="center" fontSize={{"base": "sm"}}>
                    Valid from <b>{startDate.toLocaleDateString("en-in")}</b> to <b>{endDate.toLocaleDateString("en-in")}</b>
                </Text>
            )
        }else if (validityDurationType == 2) {
            // validity type is "No validity"
            // send null
            return null;
        }
        
    }

    const getDiscountText = () => {
        if (cardType == 0) {
            return <Text fontSize={{"base": "7xl"}}>{percent}<small>%</small> <Text as="span" fontSize="1rem">Off</Text></Text>;
        } else if (cardType == 1) {
            return <Text fontSize={{"base": "7xl"}}><small>&#8377;</small>{amount} <Text as="span" fontSize="1rem">Off</Text></Text>;
        } else if (cardType == 2) {
            return <Text >{offerText}</Text>;
        }
    }
    
    return (
        <Flex
            id="preview_card"
            flexDirection="column"
            w={{"base": `${previewCardWidth}rem`}}
            minHeight={{"base": `${previewCardHeight}rem`}}
            borderRadius={{"base": "10px"}}
            boxShadow={{"base": previewCardBoxShadow}}
            bgColor={themeColor}
            position="relative"
            >
                <Flex
                    id="preview_card_banner"
                    height={{"base": `${previewCardBannerHeight}rem`}}
                    borderRadius={{"base": "10px"}}
                    bgColor="white"
                    bgImage={bannerImage}
                    bgRepeat="no-repeat"
                    bgSize="contain"
                    justify={{"base": "center"}}>
                        <Avatar 
                            id="preview_card_avatar"
                            size='xl' 
                            name='Christian Nwamba' 
                            src={businessLogo} 
                            // position={{"base": "absolute"}}
                            borderWidth={{"base": "2px"}}
                            borderColor={{"base": "white"}} />

                </Flex>

                <Flex
                    id="preview_card_details"
                    p={{"base": "1.5rem"}}
                    flexDirection="column"
                    color={fontColor}>
                        <Flex
                            id="pc_coupon_ver_id"
                            flexDirection="column"
                            justify={{"base": "center"}}
                            textAlign="center">
                            <Text as="span">COUPON VERIFICATION ID</Text>
                            <Text 
                                as="span"
                                letterSpacing={{"base": "1rem"}}>{verificationId}</Text>
                        </Flex>
                        <Heading 
                            id="pc_details_heading"
                            as="h1"
                            textAlign="center">
                            {getDiscountText()}
                        </Heading>
                        <Text 
                            id="pc_details_min_detail" 
                            // as="b"
                            fontSize={{"base": "md"}}
                            textAlign="center">
                            Redeemable on &#8377;{minPurchaseAmount
                            } & above
                        </Text>
                        
                        {getValidityText()}

                        <Flex 
                            id="pc_details_tnc"
                            flexDirection={{"base": "column"}}
                            borderWidth={{"base": "1px"}}
                            borderStyle="dotted"
                            borderColor={{"base": fontColor}}
                            borderRadius={{"base": "10px"}}
                            mt={{"base": "1rem"}}
                            p={{"base": ".5rem"}}
                            fontSize={{"base": "xs"}}>
                                <Text as="b">TERMS & CONDITIONS</Text>
                                <div>
                                    <small>{tnc}</small>
                                </div>
                        </Flex>

                </Flex>
            
        </Flex>
    );
}

export default PreviewRewardCard;