import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { 
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { populateRewardCards } from "./views/rewards/rewardProgram/rewardProgram.slice";
import { updateBusinsess } from "./views/business/business.slice";

import AuthLayout from "./layouts/Auth";
import AppLayout from "./layouts/Admin";
// import RTLLayout from "./layouts/RTL";
// import DemoLayout from "./layouts/DemoLayout";
import { pageRoutes, adminRoutePrefix } from "./routes";
import CustomErrorBoundary from "./components/CustomErrorBoundary/CustomErrorBoundary";

import SignIn from "@/views/auth/signin";
import SignUp from "@/views/auth/signup";
import ForgotPassword from "./views/auth/forgotPassword/ForgotPassword";
import ForgotPasswordConfirm from "./views/auth/forgotPassword/ForgotPasswordConfirm";

import { RewardProgram } from "@/views/rewards/rewardProgram";
import { RewardCard } from "./views/rewards/rewardCards";
import AddOrEditRewardCardComp from "./views/rewards/rewardCards/components/AddOrEditRewardCard";
import BusinessProfile from "./views/business/profile/BusinessProfile";
import Credits from "./views/business/credits/Credits";
import CreditsPaymentOrderStatus from "./views/business/payment/CreditsPaymentOrderStatus";
import Dashboard from "@/views/business/dashboard/Dashboard";

import ErrorMessage from "./components/ErrorMessage/ErrorMessage";

import theme from "@/theme/theme";

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient()
  /*
  * Loads the localStorage data into redux store on every reload
  */
  useEffect(() => {
    const businessStr = localStorage.getItem("business");
    const rewardCardsStr = localStorage.getItem("rewardCards");
    if (businessStr?.length > 0) {
      const businessObj = JSON.parse(businessStr);
      dispatch(updateBusinsess(businessObj));
    } 
    if (rewardCardsStr?.length > 0) {
      const rewardCardsArray = JSON.parse(rewardCardsStr);
      if (rewardCardsArray.length > 0) {
        dispatch(populateRewardCards(rewardCardsArray));
      }
    }
  }, []);

  const router = createBrowserRouter([
    {
      children: [
        {
          path: "/",
          element: <SignIn />,
        },
        {
          path: pageRoutes.signin,
          element: <SignIn />,
        },
        {
          path: pageRoutes.signup,
          element: <SignUp />,
        },
        {
          path: pageRoutes.forgotPassword,
          element: <QueryClientProvider client={queryClient}><ForgotPassword /></QueryClientProvider> 
        },
        {
          path: pageRoutes.forgotPasswordConfirm,
          element: <QueryClientProvider client={queryClient}><ForgotPasswordConfirm /></QueryClientProvider>
        },
        {
          path: adminRoutePrefix + "*", // makes /admin/*
          element: <AppLayout />,
          children: [
            {
              path: pageRoutes.dashboard,
              element: <Dashboard />
            }, 
            {
              path: pageRoutes.rewardProgram,
              element: <RewardProgram />
            },
            {
              path: pageRoutes.rewardCard,
              element: <RewardCard />,
              children: []
            },
            {
              path: pageRoutes.rewardCardNew, 
              element: <CustomErrorBoundary>
                        <AddOrEditRewardCardComp isEditing={false} />
                      </CustomErrorBoundary>
            },
            {
              path: pageRoutes.rewardCardDetail, 
              element: <CustomErrorBoundary>
                        <AddOrEditRewardCardComp isEditing={true} />
                      </CustomErrorBoundary>
            },
            {
              path: pageRoutes.profile,
              element: <BusinessProfile />
            },
            {
              path: pageRoutes.credits,
              element: <Credits />
            },
          ]
        },
        {
          path: pageRoutes.paymentInAppStatus,
          element: <QueryClientProvider client={queryClient}>
                    <CreditsPaymentOrderStatus
                      redirectText="Redirecting you back to the credits page"
                      redirectPath={adminRoutePrefix + pageRoutes.credits} />
                  </QueryClientProvider>,
        }
      ],
    },
  ]);

  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
