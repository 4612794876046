import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
    Button,
    Flex,
    Heading,
    Icon,
    Text,
    Skeleton,
    useToast
} from "@chakra-ui/react";

import {
    FaCirclePlus
} from "react-icons/fa6";

import { NoListIcon } from "@/components/Icons/Icons";
import Card from "@/components/Card/Card";
import CardHeader from "@/components/Card/CardHeader";
import CardBody from "@/components/Card/CardBody";

import { RewardCard } from "@/utils/interfaces/rewardProgram";
import { adminRoutePrefix, pageRoutes } from "@/routes";
import useDeleteCouponMutation from "@/hooks/mutations/useDeleteCouponMutation";

import useConfirmRewardCardDelete from "./hooks/useConfirmRewardCardDelete";
import RewardCardItem from "./RewardCardItem";
import HeaderAlert from "./HeaderAlert";
import RewardCardLoading from "./components/RewardCardLoading";


interface RewardCardCompPropType {
    isLoading: boolean,
    rewardProgramId: string | null,
    rewardCards: RewardCard[],
}
const RewardCardComp = (props: RewardCardCompPropType) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [getConfirmation, ConfirmationRewardCardDelete] = useConfirmRewardCardDelete();

    const {
        isLoading,
        rewardProgramId,
        rewardCards
    } = props;

    const rewardCardDeleteMutation = useDeleteCouponMutation({
        onSuccess: (data: any) => {
            toast({
                title: 'Deleted coupon successfully',
                // description: response.data["non_field_error"],
                position: 'top-right',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            
            setTimeout(() => window.location.reload(), 1000);
        },
        onError: (error: any) => {
            if (error.response) {
                let response = error.response;
                if ("non_field_error" in response.data) {
                    toast({
                        title: 'Error occurred while deleting coupons',
                        description: response.data["non_field_error"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if ("detail" in response.data) {
                    toast({
                        title: 'Error occurred while deleting coupons',
                        description: response.data["detail"],
                        position: 'top-right',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }else {
                toast({
                    title: 'Internal Server Error occurred!',
                    description: "500: Internal server error occurred while deleting coupon",
                    position: 'top-right',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    });

    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
            <Card colorMode="white">
                <CardHeader className="rewardprog_card_header" mb={{ base: "30px" }}>
                    <Flex
                        className="rewardprog_header_details"
                        flexDirection={{"base": "column"}}
                        align="center"
                        minHeight="60px"
                        w="100%"
                    >
                        {/* Showing Header alert when there's no Program but user has
                            Reward card(s) */}
                        {!rewardProgramId 
                            && rewardCards?.length > 0 
                            && <HeaderAlert />}

                        <Flex justify="space-between" flexDirection="row" w="100%">
                            <Heading variant="pageHeader" mb="20px">Coupons</Heading>
                            
                            <Button
                                leftIcon={<FaCirclePlus />}
                                variant="primaryBlue"
                                w={{ 
                                    base: "fit-content",
                                    lg: "fit-content",
                                    md: "fit-content",
                                    sm: "fit-content"
                                }}
                                onClick={e => navigate("new/")}>
                                Add Coupon
                            </Button>
                        </Flex>
                    </Flex>
                </CardHeader>
                
                {isLoading 
                    ? <RewardCardLoading /> 
                    :
                    <CardBody
                        p={{ base: "30px" }}
                        flexDirection="column"
                        gap={{ base: "30px" }}
                        >
                        {rewardCards.map(card => {
                            return (
                                <RewardCardItem
                                    rewardCard={card}
                                    isDeleting={rewardCardDeleteMutation.isLoading}
                                    onEditCard={event => {
                                        navigate(adminRoutePrefix + pageRoutes.rewardCard + card.id)
                                    }}
                                    onDeleteCard={async event => {
                                        console.log("Deleting ...")
                                        const status = await getConfirmation();
                                        if (status) { // status is true, delete coupon
                                            rewardCardDeleteMutation.mutate({cardId: card.id})
                                        }
                                        return;
                                    }}/>
                            );
                        })}

                        {!rewardCards.length && 
                            <Flex 
                                flexDirection="column"
                                justify="center"
                                textAlign="center">
                                <Text color="gray.500" fontSize={{base: "5xl", lg: "5xl", md: "5xl", sm: "2xl"}}><Icon as={NoListIcon} /></Text>
                                <Text color="gray.500" fontSize={{base: "lg", lg: "lg", "md": "lg", sm: "sm"}}>You have not created any coupons yet, create one right now!</Text>
                            </Flex>
                        }

                        <ConfirmationRewardCardDelete />

                    </CardBody>
                }
            </Card>
        </Flex>
    );
}

export default RewardCardComp;