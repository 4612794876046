import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { RewardCard, RewardProgram, SpecificRewardCardInterface } from '@/utils/interfaces/rewardProgram'


const initialState: RewardProgram  = {
    id: "",
    amount: null,
    rewardCard: "",
    status: -1,
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    specificRewards: [],
    rewardCards: []
}

export const rewardProgramSlice = createSlice({
    name: "rewardProgram",
    initialState,
    reducers: {
        updateRewardProgram: (state, action) => {
            for (let key in action.payload) {
                state[key] = action.payload[key];
            }
        },
        addSpecificReward: (state, action: PayloadAction<SpecificRewardCardInterface>) => {
            let payload = action.payload;
            state.specificRewards?.push(payload);
        },
        updateSpecificReward: (state, action: PayloadAction<SpecificRewardCardInterface>) => {
            const { id } = action.payload;
            state.specificRewards = state.specificRewards?.map(reward => {
                if (reward.id === id) {
                    reward = {...reward, ...action.payload}
                }
                return reward;
            })
        },
        removeSpecificReward: (state, action) => {
            const { id } = action.payload;
            state.specificRewards = state.specificRewards?.filter(
                reward => reward.id != id
            );
        },

        populateRewardCards: (state, action: PayloadAction<RewardCard[]>) => {
            state.rewardCards = action.payload;
        }
    }
})

export const {
    updateRewardProgram,
    addSpecificReward,
    updateSpecificReward,
    removeSpecificReward,
    populateRewardCards
} = rewardProgramSlice.actions;

export default rewardProgramSlice.reducer;

