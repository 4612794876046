import React from 'react';

import {
    HStack,
    Text
} from '@chakra-ui/react';

import {
    CreativeTimLogo,
    InbackLogo
} from '@components/Icons/Icons';

interface AuthNewNavbarProps {
    logoText: string;
    rest: any,
};

const AuthNewNavbar = (props: any) => {
    const { logoText, ...rest } = props;
    return (
        <HStack display={{ sm: "flex", lg: "flex" }} {...rest}>
            <InbackLogo color="#6881BF" w="32px" h="32px" />
            <Text fontSize="2xl" mt="3px" as="b" color="blueDark">
                {logoText}
            </Text>
        </HStack>
    );
}

export default AuthNewNavbar