import {
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Divider,
	Flex,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Button,
	ButtonGroup,
	Box,
	Text,
	Badge,
	Select,
	Switch,
	useColorModeValue,
} from "@chakra-ui/react";

import Card from "@/components/Card/Card";
import CardBody from "@/components/Card/CardBody";
import CardHeader from "@/components/Card/CardHeader";

import { FaFloppyDisk, FaTrash } from "react-icons/fa6";

import {
	RewardProgram,
} from "@/utils/interfaces/rewardProgram";
import { RewardProgramUiStateInterface } from "./interfaces";
import { REWARD_PROGRAM_STATUS_CHOICES } from "@/utils/globalConstants";

import SpecificRewardItem from "./SpecificRewardItem";
import AlertNoRewardCard from "./AlertNoRewardCard";
import DialogProgramCreateSucess from "./DialogProgramCreateSuccess";
import { MouseEventHandler } from "react";

interface RewardProgramCompProps extends RewardProgram {
	handleUpdateLoyaltyProgram: Function,
	handleCreateLoyaltyProgram: MouseEventHandler,
	handleSaveLoyaltyProgram: Function,
	handleCloseDialogProgramSuccessCreated: Function,
	handleAmountChange: React.ChangeEventHandler<HTMLInputElement>,
    handleRewardCardChange: React.ChangeEventHandler<HTMLSelectElement>,
	handleStatusChange: Function,
	handleDeleteLoyaltyButtonClick: MouseEventHandler,
	hasChanges: Function,
	WarningDialogDeleteLoyaltyProgram: any,
	WarningDialogAmountUpdateLoyaltyProgram: any,
	rewardProgram: any,
	rewardCards: any,
	uiState: RewardProgramUiStateInterface,
}

const RewardProgramComp = (props: RewardProgramCompProps) => {
	const {
		specificRewards = [],
		rewardCards = [],
		WarningDialogDeleteLoyaltyProgram,
		WarningDialogAmountUpdateLoyaltyProgram,
		handleUpdateLoyaltyProgram,
		handleCreateLoyaltyProgram,
    	handleCloseDialogProgramSuccessCreated,
		handleAmountChange,
		handleRewardCardChange,
		handleStatusChange,
		handleDeleteLoyaltyButtonClick,
		hasChanges,
		uiState,
	} = props;

	const {
		id,
		amount,
		rewardCard,
		status,
    	createdAt,
		createdByFullName
	} = props.rewardProgram;

	const { ...fieldErrors } = uiState.fieldErrors;

	// const textColor = useColorModeValue("gray.700", "white");
	// const bgButton = useColorModeValue(
	// 	"linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
	// 	"gray.800"
	// );

	const isValidToCreate = () => {
		let isValid: boolean = true;
	
		// check validity of amount
		if (amount && amount <= 0) {
		  isValid = false;
		} else if (amount?.toString().length == 0) {
		  isValid = false;
		} else {
		  isValid = true;
		}
	
		// check validity of rewardCard
		if (rewardCard == null || rewardCard.length === 0) {
		  isValid = false;
		} else {
		  isValid = true;
		}
		
		return isValid;
	  }

	const isCreateButtonDisabled = () => {
		return !isValidToCreate() || uiState.isUpdatingOrCreating || uiState.isLoading;
	};

	const isCreateButtonLoading = () => {
		return uiState.isUpdatingOrCreating;
	};

	const getStatus = (status: number) => {
		return REWARD_PROGRAM_STATUS_CHOICES[status];
	};

	const isLoyaltyProgramOff = (status: number) => {
		return status === 0;
	}

	const isAmountInputDisabled = () => {
		return isLoyaltyProgramOff(status);
	}

	const isRewardCardSelectDisabled = () => {
		return isLoyaltyProgramOff(status);
	}

	const getCreatedAtDate = () => {
		const date = new Date(createdAt);
		return date.toDateString();
	}

	const getCreatedBy = () => {
		return createdByFullName;
	}

	const getSpecificRewardErrorById = (id: string) => {
		const specificRewardError = uiState.specificRewardErrors.filter(
			(error) => error.id === id
		);
		if (specificRewardError.length > 0) {
			return specificRewardError[0];
		}
		return { id: "", amountError: "", rewardCardError: "" };
	};

	const getActiveOrPauseBadge = () => {
		return getStatus(status) === "active" ? (
			<Badge fontSize="md" colorScheme="green" mr="10px">
				ON
			</Badge>
		) : (
			<Badge fontSize="md" colorScheme="yellow" mr="10px">
				OFF
			</Badge>
		);
	};

	const getInfoBlock = () => {
		if (id?.length === 0) {
			return (
				<Alert status="info" borderRadius="10px">
					<AlertIcon />
					<Box>
						<AlertTitle>
							Loyalty program is not configured yet!
						</AlertTitle>
						<AlertDescription>
							Your have not configured your Loyalty program yet.
							We are not able to send Coupons to your customers,
							and also not able to generate metrics for your
							Coupons.
						</AlertDescription>
					</Box>
				</Alert>
			);
		}

		return getStatus(status) === "active" ? (
			<Alert status="success" borderRadius="10px">
				<AlertIcon />
				<Box>
					<AlertTitle>
						Loyalty program is configured and ON!
					</AlertTitle>
					<AlertDescription>
						Your Loyalty program is ON and customers whose
						purchase detail is added will get coupon when they hit a
						purchase milestone, and as per your configured program
					</AlertDescription>
				</Box>
			</Alert>
		) : (
			<Alert status="warning" borderRadius="10px">
				<AlertIcon />
				<Box>
					<AlertTitle>Loyalty program is OFF!</AlertTitle>
					<AlertDescription>
						Your Loyalty program is OFF right now, we are not
						able to send Coupons to your customer even if they hit a
						purchase milestone. Please turn ON your Loyalty program
						to reward your customers with Coupons and enjoy higher
						customer retention.
					</AlertDescription>
				</Box>
			</Alert>
		);
	};

	// not being used currently as Specific rewards feature is abandoned
	const getSpecificRewards = () => {
		return (
			<Flex flexDirection={{ base: "column" }}>
				{specificRewards.map((reward, index) => {
					const specificRewardError = getSpecificRewardErrorById(
						reward.id
					);
					return (
						<SpecificRewardItem
							keyVal={index}
							id={reward.id}
							rewardProgramId={reward.rewardProgramId}
							amount={reward.amount}
							rewardCardId={reward.rewardCardId}
							rewardCards={rewardCards}
							amountError={specificRewardError.amountError || ""}
							rewardCardError={
								specificRewardError.rewardCardError || ""
							}
							onRemoveSpecificRewards={(e) => {
								handleRemoveSpecificRewards(reward.id);
							}}
							onAmountChange={(e) => {
								handleUpdateSpecificReward({
									id: reward.id,
									amount: Number(e.target.value),
								});
							}}
							onRewardCardChange={(e) => {
								handleUpdateSpecificReward({
									id: reward.id,
									rewardCardId: e.target.value,
								});
							}}
						/>
					);
				})}
			</Flex>
		);
	};

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			<Card colorMode="white">
				<CardHeader
					class="rewardprog_card_header"
					mb={{ base: "30px" }}
				>
					<Flex
						className="rewardprog_header_details"
						justify="space-between"
						align="center"
						minHeight="60px"
						w="100%"
					>
						<Flex
							justify="flex-start"
							flexDirection="column"
							w="100%"
						>
							<Heading variant="pageHeader" mb="20px">
								Configure Loyalty Program
							</Heading>

							{/* Active/Pause badges */}
							{id?.length > 0 && (
								<Flex
									className="sub_heading_details"
									justify="flex-start"
									align="center"
								>
									<Switch 
										colorScheme='green' 
										size='lg'
										me="0.5rem"
										isChecked={getStatus(status) === "active"}
										onChange={event => {
											// const status = getStatus(status);
											if (status === 1) {
												handleStatusChange(0); // off the program
												return;
											}
											handleStatusChange(1); // on the program
										}} />
									{getActiveOrPauseBadge()}
									<Divider orientation="vertical" />
									<Text as="small" mr={{"base": "1rem"}}>
										Created: <Text as="i"><b>{getCreatedAtDate()}</b></Text>
									</Text>
                  					<Text as="small">
										Created By: <Text as="i"><b>{getCreatedBy()}</b></Text>
									</Text>
								</Flex>
							)}
						</Flex>
						
						{/* button group */}
						<Flex justify="">
							<ButtonGroup variant="outline" spacing="6">
								{/* If Loyalty program exists show Active/Pause and Save buttons */}
								{/* {id?.length > 0 &&
									getActiveOrPauseButton()} */}
								{id?.length > 0 && (
									<Button 
										variant="primaryBlue"
										leftIcon={<FaFloppyDisk />}
										isDisabled={!hasChanges()}
										onClick={event => {
											handleUpdateLoyaltyProgram();
										}}>Save</Button>
								)}

								{/* TODO: Need to figure out this feature more */}
								{/* Delete loyalty program button */}
								{/* {id?.length > 0 && (
									<Button 
										variant="primaryRed"
										onClick={event => {
											handleDeleteLoyaltyButtonClick(event);
										}}><FaTrash /></Button>
								)} */}

								{/* If Loyalty program doesn't exist, show Create button */}
								{id?.length === 0 && (
									<Button
										variant="primaryBlue"
										isDisabled={isCreateButtonDisabled()}
										isLoading={isCreateButtonLoading()}
										onClick={handleCreateLoyaltyProgram}

									>
										<span>Create loyalty program</span>
									</Button>
								)}
							</ButtonGroup>
						</Flex>
					</Flex>
				</CardHeader>

				<CardBody
					p={{ base: "30px" }}
					flexDirection="column"
					gap={{ base: "30px" }}
				>
					<Flex
						className="reward_body_info"
						justify="center"
						w="100%"
					>
						{getInfoBlock()}
					</Flex>

					{/* Milestone section */}
					<Flex
						className="reward_body_milestone"
						justify={{base: "space-between", lg: "space-between", md: "space-between", sm: "center"}}
						flexDir={{base: "row", lg: "row", md: "row", sm: "column"}}
						paddingX={{ base: "100px", lg: "100px", sm: "0" }}
					>
						<Flex
							justify={{ base: "center" }}
							flexDirection="column"
							mb="1rem"
						>
							<FormLabel>AFTER Every</FormLabel>
							<InputGroup>
								<InputLeftElement
									pointerEvents="none"
									color="gray.300"
									fontSize="1.2em"
									children="₹"
								/>
								<Input
									placeholder="Enter amount"
									type="number"
									isDisabled={isAmountInputDisabled()}
									value={amount}
									isInvalid={
										fieldErrors.amountError.length > 0
									}
									onChange={handleAmountChange}
								/>
							</InputGroup>
							{fieldErrors.amountError.length > 0 && (
								<Text color="red.300" as="small">
									{fieldErrors.amountError}
								</Text>
							)}
						</Flex>

						<Flex
							justify={{ base: "center" }}
							flexDirection="column"
							mb="1rem"
						>
							<FormLabel>Your CUSTOMER will get</FormLabel>
							<Select
								placeholder="Select coupon"
								isInvalid={
									fieldErrors.rewardCardError.length > 0
								}
								onChange={handleRewardCardChange}
								isDisabled={isRewardCardSelectDisabled()}
							>
								{rewardCards.map((card) => {
									let isSelected = false;
									if (
										rewardCard?.length > 0 &&
										rewardCard === card.id
									) {
										isSelected = true;
									}
									return (
										<option
											value={card.id}
											selected={isSelected}
										>
											{card.name}
										</option>
									);
								})}
							</Select>
							{fieldErrors.rewardCardError.length > 0 && (
								<Text color="red.300" as="small">
									{fieldErrors.rewardCardError}
								</Text>
							)}
						</Flex>
					</Flex>
					{/* Milestone section ends */}

					{/* Specific reward section */}
					{/* Commencted as not being used currently */}
					{/* <Flex
						className="reward_body_specific"
						justify="center"
						flexDirection={{ base: "column" }}
						paddingX={{ base: "100px" }}
						mt={{ base: "10px" }}
					>
						<Text fontSize="large" mb="10px">
							Exclusive rewards for specific amounts
						</Text>

						{getSpecificRewards()}

						<Button
							leftIcon={<FaCirclePlus />}
							variant="primaryBlue"
							w={{ base: "fit-content" }}
							onClick={handleAddSpecificRewards}
						>
							Add reward
						</Button>
					</Flex> */}

					{/* Specific reward section ends */}
				</CardBody>
        
				{rewardCards.length > 0 || 
				<AlertNoRewardCard />}

				<DialogProgramCreateSucess 
				isOpen={uiState.isDialogProgramCreatedSuccessOpen}
				onClose={handleCloseDialogProgramSuccessCreated}
				/>

				<WarningDialogDeleteLoyaltyProgram />
				<WarningDialogAmountUpdateLoyaltyProgram />
			</Card>
		</Flex>
	);
};

export default RewardProgramComp;
