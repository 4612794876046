import { useState, useEffect, useCallback } from "react";


const useTimer = (onTimerFinishes: () => void, timerCounter: number, start: boolean) => {
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();;
    const [counter, setCounter] = useState(timerCounter);

    // const tick = useCallback(
    //     () => timerCounterState > 0 ? setTimerCounterState(timerCounterState - delay): undefined,
    //     [callback]
    // );

    // Function to reset the timer
    const resetTimer = () => {
        setCounter(timerCounter);
    };

    useEffect(() => {
        if (start) {
            const intervalId = setInterval(() => setCounter(c => c - 1), 1000);
            setIntervalId(intervalId);

            return () => clearInterval(intervalId);
        }
    }, [start]);

    useEffect(() => {
        if (counter <= 0) {
            onTimerFinishes();
            
            clearInterval(intervalId);
        }
    }, [counter])

    return [counter, resetTimer];
}

export default useTimer;