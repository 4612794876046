import { ErrorBoundary } from "react-error-boundary";
import ErrorMessage from "@/components/ErrorMessage/ErrorMessage.js";

// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import Configurator from '../components/Configurator/Configurator.js';
import Footer from '../components/Footer/Footer.js';

// react-query imports
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// Layout components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import Sidebar from '../components/Sidebar/index.js';
import React, { useState, useEffect } from 'react';
import { Route, Routes, Outlet, useNavigate } from 'react-router-dom';
import routes from '@/routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from '@/theme/theme';
// Custom components
import MainPanel from '../components/Layout/MainPanel.js';
import PanelContainer from '../components/Layout/PanelContainer.js';
import PanelContent from '../components/Layout/PanelContent.js';

import { pageRoutes } from "@/routes.js";
import { isTokenValid } from "@/utils/helpers.js";
import { GlobalContext } from "@/utils/contexts.js";

interface AdminProps {};

export default function Dashboard(props: AdminProps) {
	const [refetchCredits, setRefetchCredits] = useState(false);
	// Create a client
	const queryClient = new QueryClient()

	const navigate = useNavigate();

	const { ...rest } = props;
	// states and functions
	const [ sidebarVariant, setSidebarVariant ] = useState('transparent');
	const [ fixed, setFixed ] = useState(false);

	// Effect to check if token is expired or not
	useEffect(() => {
		if (!isTokenValid()) {
			// redirect to signing page
			navigate(pageRoutes.signin);
		}
	}, []);

	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else if (window.location.pathname === (routes[i].layout + routes[i].path)) {
				return routes[i].name;
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	// This changes navbar state(fixed or not)
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === 'account') {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/admin') {
				return <Route path={prop.layout + prop.path} element={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = 'ltr';
	// Chakra Color Mode
	return (
		<ChakraProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				<GlobalContext.Provider value={{ "refetchCredits": refetchCredits, "setRefetchCredits": setRefetchCredits }}>

					<Sidebar
						routes={routes}
						logoText={'inback'}
						display='none'
						sidebarVariant={sidebarVariant}
						{...rest}
						/>
					<ErrorBoundary
						FallbackComponent={ErrorMessage}>
							<MainPanel
								w={{
									base: '100%',
									xl: 'calc(100% - 275px)'
								}}>
								<Portal>
									<AdminNavbar
										onOpen={onOpen}
										logoText={'Inback'}
										brandText={getActiveRoute(routes)}
										secondary={getActiveNavbar(routes)}
										fixed={fixed}
										{...rest}
										/>
								</Portal>
								{getRoute() ? (
									<PanelContent>
										<PanelContainer>
											<Outlet />
										</PanelContainer>
									</PanelContent>
								) : null}
								<Footer />
								{/* <Portal>
									<FixedPlugin secondary={getActiveNavbar(routes)} fixed={fixed} onOpen={onOpen} />
								</Portal> */}
								{/* <Configurator
									secondary={getActiveNavbar(routes)}
									isOpen={isOpen}
									onClose={onClose}
									isChecked={fixed}
									onSwitch={(value) => {
										setFixed(value);
									}}
									onOpaque={() => setSidebarVariant('opaque')}
									onTransparent={() => setSidebarVariant('transparent')}
									/> */}
							</MainPanel>
					</ErrorBoundary>
				</GlobalContext.Provider>
			</QueryClientProvider>
		</ChakraProvider>
	);
}
