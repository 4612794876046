import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { BUSINESS_METRICS_URL } from "@/utils/endpoints";
import { getTokenKey } from "@/utils/helpers";


const useFetchMetrics = (enabled: boolean = true) => {
    const navigate = useNavigate()
    const queryRes = useQuery({queryKey: ["businessMetrics"], queryFn: async () => {
        const res = await axios(BUSINESS_METRICS_URL, {
            withCredentials: true,
            headers: {
                "Authorization": `Token ${getTokenKey()}`
            }
        });
        return res.data;
    }, enabled: enabled, refetchOnWindowFocus: false})

    // navigate to signin page when 401 status is recieved in status
    if (queryRes.error && queryRes.error.request.status === 401) {
        navigate("/");
    }

    return queryRes;
}

export default useFetchMetrics;