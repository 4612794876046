import { useState, useContext} from 'react';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { redirect, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import {
  SpecificRewardCardInterface,
  RewardProgram as RewardProgramType,
} from "@/utils/interfaces/rewardProgram";
import { RewardProgramUiStateInterface } from './interfaces';

import { useFetchLoyaltyProgram, useFetchRewardCards } from '@/hooks';
import useCreateLoyaltyProgramMutation from '@/hooks/mutations/useCreateLoyaltyProgramMutation';
import useUpdateLoyaltyProgramMutation from '@/hooks/mutations/useUpdateLoyaltyProgramMutation';
import { GlobalContext } from "@/utils/contexts";
import useDeleteLoyaltyProgramConfirm from './hooks/useDeleteLoyaltyProgramConfirm';
import useAmountUpdateLoyaltyProgramConfirm from './hooks/useAmountUpdateLoyaltyProgramConfirm';
import {
  addSpecificReward,
  updateSpecificReward,
  removeSpecificReward,
} from "./rewardProgram.slice";
import RewardProgramComp from "./RewardProgramComp";


const RewardProgram = () => {

  const state = useSelector((state) => state.rewardProgram);
  const businessState = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const context = useContext(GlobalContext);

  const [rewardProgram, setRewardProgram] = useState({
    id: "",
    amount: 0,
    rewardCard: "",
    status: 1,
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    createdByFullName: "",
    specific_rewards: [],
    forceSendLoyaltyReward: false,
  });
  const [currentRewardProgram, setCurrentRewardProgram] = useState({});

  const [rewardCards, setRewardCards] = useState([]);

  const uiStateObject: RewardProgramUiStateInterface = {
    isLoading: false,
    isUpdatingOrCreating: false,
    isActivatingOrPausing: false,
    isDialogProgramCreatedSuccessOpen: false,
    generalErrorMsg: "",
    generalSuccessMsg: "",
    fieldErrors: {
      amountError: "",
      rewardCardError: ""
    },
    specificRewardErrors: [],
  }
  const [uiState, setUiState] = useState(uiStateObject);

  const getCurrentAmount = (): number => {
    if ("amount" in currentRewardProgram) {
      return currentRewardProgram.amount;
    }
    return 0;
  }
  const [getConfirmation, WarningDialogDeleteLoyaltyProgram] = useDeleteLoyaltyProgramConfirm();

  const [getAmountUpdateConfirmation, WarningDialogAmountUpdateLoyaltyProgram] = useAmountUpdateLoyaltyProgramConfirm(
    rewardProgram.amount, 
    getCurrentAmount()
  );


  //////////////////////////////////////////////////////////////
  // Query and Mutations ///////////////////////////////////////
  //////////////////////////////////////////////////////////////

  const loyaltyProgramQuery = useFetchLoyaltyProgram(true, {
    retry: 1,
    onSuccess: (data: any) => {
      setRewardProgram({
        ...rewardProgram,
        id: data.id,
        amount: data.amount,
        rewardCard: data.reward_card,
        status: data.status,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by,
        createdByFullName: data.created_by_fullname,
      });

      setCurrentRewardProgram(data);
    },
    onError: (error: any) => {
      throw "Something went wrong while fetching loyalty program.";
    }
  });

  const rewardCardQuery = useFetchRewardCards(true, {
    queryParam: "purpose_type=0",
    retry: 1,
    onSuccess: (data: any) => {
      setRewardCards(data.results);
    },
    onError: (error: any) => {
      throw "Something went wrong while fetching loyalty program.";
    }
  });

  const createRewardProgramMutation = useCreateLoyaltyProgramMutation({
    onError: (error: any) => {
      const response = error.response;
      if (response) {
        let errors: any = {};

        if ("detail" in response.data) {
          toast({
              title: 'Error occurred while creating loyalty program',
              description: response.data.detail,
              position: 'top-right',
              status: 'error',
              duration: 9000,
              isClosable: true,
          });
        }
        if ("non_field_errors" in response.data) {
          toast({
              title: 'Error occurred while creating loyalty program',
              description: response.data.non_field_errors,
              position: 'top-right',
              status: 'error',
              duration: 9000,
              isClosable: true,
          });
        }

        if ("amount" in response.data) {
          errors["amountError"] = response.data.amount[0];
        }
        if ("reward_card" in response.data) {
          errors["rewardCardError"] = response.data.reward_card[0];
        }

        setUiState({
          ...uiState,
          fieldErrors: {...uiState.fieldErrors, ...errors}
        });
      }else {
        toast({
            title: 'Internal server error!',
            description: 'Internal server error occurred while trying to create loyalty program',
            position: 'top-right',
            status: 'error',
            duration: 9000,
            isClosable: true,
        });
      }
    },
    onSuccess: (response: any) => {
      toast({
          title: 'Created loyalty program successfully',
          description: 'You can add customers to the loyalty program now',
          position: 'top-right',
          status: 'success',
          duration: 9000,
          isClosable: true,
      });

      const data = response.data;
      setRewardProgram({
        ...rewardProgram,
        id: data.id,
        amount: data.amount,
        rewardCard: data.reward_card,
        status: data.status,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by,
        createdByFullName: data.created_by_fullname,
      });
    }
  });

  const updateRewardProgramMutation = useUpdateLoyaltyProgramMutation({
    onError: (error: any) => {
      const response = error.response;
      if (response) {
        let errors: any = {};

        if ("detail" in response.data) {
          toast({
              title: 'Error occurred while updating loyalty program',
              description: response.data.detail,
              position: 'top-right',
              status: 'error',
              duration: 9000,
              isClosable: true,
          });
        }
        if ("non_field_errors" in response.data) {
          toast({
              title: 'Error occurred while updating loyalty program',
              description: response.data.non_field_errors,
              position: 'top-right',
              status: 'error',
              duration: 9000,
              isClosable: true,
          });
        }

        if ("amount" in response.data) {
          errors["amountError"] = response.data.amount[0];
        }
        if ("reward_card" in response.data) {
          errors["rewardCardError"] = response.data.reward_card[0];
        }

        setUiState({
          ...uiState,
          fieldErrors: {...uiState.fieldErrors, ...errors}
        });

        setRewardProgram({...rewardProgram, amount: currentRewardProgram.amount, rewardCard: currentRewardProgram.reward_card});
      }else {
        toast({
            title: 'Internal server error!',
            description: 'Internal server error occurred while trying to update loyalty program',
            position: 'top-right',
            status: 'error',
            duration: 9000,
            isClosable: true,
        });
      }
    },
    onSuccess: (resData: any) => {
      const data = resData.data;
      let toastSuccessMessage = "Updated loyalty program successfully";
      let toastStatus = 'success';

      if (data.status !== rewardProgram.status) {
        if (data.status === 0) {
          toastSuccessMessage = "Turned OFF loyalty program successfully";
          toastStatus = 'warning';
        } else {
          toastSuccessMessage = "Turned ON loyalty program successfully";
        }
      }
      
      toast({
          title: toastSuccessMessage,
          // description: 'New customers will be a', 
          position: 'top-right',
          status: toastStatus,
          duration: 9000,
          isClosable: true,
      });

      setRewardProgram({
        ...rewardProgram,
        id: data.id,
        amount: data.amount,
        rewardCard: data.reward_card,
        status: data.status,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by,
        createdByFullName: data.created_by_fullname,
      });

      setCurrentRewardProgram(data);

      // refetch credits balance
      context.setRefetchCredits(true);
    }
  });
  //////////////////////////////////////////////////////////////

  
  //////////////////////////////////////////////////////////////
  // useEffect calls //////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////
  // helpers ////////////////////////////////////////////
  //////////////////////////////////////////////////////////////
  const getFormatedSpecificRewards = (specificRewards) => {
    return specificRewards.map((reward, index) => {
      return {
        id: reward.id,
        rewardProgramId: reward.reward_program,
        amount: reward.amount,
        rewardCardId: reward.reward_card,
        keyVal: index
      }
    })
  } 

  const setFieldError = (fieldName: string, value: string) => {
    setUiState({
      ...uiState,
      fieldErrors: {...uiState.fieldErrors, [fieldName]: value}
    });
  }

  const addNewSpecificRewardError = (id: string) => {
    const specificRewardErrors = uiState.specificRewardErrors;
    specificRewardErrors.push({
      id: id,
      "amountError": "",
      "rewardCardError": ""
    });
    setUiState({...uiState, specificRewardErrors: specificRewardErrors});
  }

  const setSpecificRewardErrorById = (id: string, amountError: string, rewardCardError: string) => {
    let specificRewardError = uiState.specificRewardErrors.filter(error => error.id === id);
    let restOfSpecificRewardErrors = uiState.specificRewardErrors.filter(error => error.id != id);
    if (specificRewardError.length === 0) {
      return;
    }

    specificRewardError = {...specificRewardError[0]};
    if (amountError != null) specificRewardError["amountError"] = amountError;
    if (rewardCardError != null) specificRewardError["rewardCardError"] = rewardCardError;

    restOfSpecificRewardErrors.push(specificRewardError);

    setUiState({...uiState, specificRewardErrors: restOfSpecificRewardErrors});

  }

  const isSpecificRewardValid = (id: string) => {
    let specificReward = state.specificRewards.filter(error => error.id === id);
    const amount = specificReward[0].amount;
    const rewardCard = specificReward[0].rewardCardId;
    let isValid = true;
    let error = {"id": id};

    if (amount == null || amount.length === 0) {
      isValid = false;
      error["amountError"] = "*This field may not be empty";
    } else if (amount === 0) {
      isValid = false;
      error["amountError"] = "*This field may not be 0 or less than 0";
    } else {
      isValid = true;
      error["amountError"] = "";
    }

    if (rewardCard == null || rewardCard.length === 0) {
      isValid = false;
      error["rewardCardError"] = "*This field may not be empty";
    } else {
      isValid = true;
      error["rewardCardError"] = "";
    }

    setSpecificRewardErrorById(id, error["amountError"], error["rewardCardError"]);

    return isValid;
  }

  const hasAmountChange = () => {
    if (currentRewardProgram.amount !== rewardProgram.amount) {
      return true;
    }
    return false;
  }

  const hasChanges = () => {
    // returns true if there are any changes to be saved
    if (hasAmountChange()) {
      return true;
    } else if (rewardProgram.rewardCard !== currentRewardProgram.reward_card) {
      return true;
    }
    return false;
  }

  //////////////////////////////////////////////////////////////
  // EVENT HANDLERS ////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  const handleAddSpecificRewards = () => {
    if (state.specificRewards.length > 0) {
      // checks the validity of last added specific reward
      // if last one is valid then only allow to add another one
      const lastIndex = state.specificRewards.length - 1;
      const lastSpecificReward = state.specificRewards[lastIndex];
      if (!isSpecificRewardValid(lastSpecificReward.id)) {
        return;
      }
    }

    const id = uuidv4();
    dispatch(
      addSpecificReward({
        id: id,
        rewardProgramId: "",
        amount: null,
        rewardCardId: "",
      })
    );

    addNewSpecificRewardError(id);
  };

  const handleUpdateSpecificReward = (
    specificReward: SpecificRewardCardInterface
  ) => {
    dispatch(updateSpecificReward(specificReward));
  };

  const handleRemoveSpecificRewards = (id: string) => {
    dispatch(removeSpecificReward({ id: id }));
  };

  const handleCloseDialogProgramSuccessCreated = () => {
    setUiState({...uiState, isDialogProgramCreatedSuccessOpen: false});
  }

  const handleCreateLoyaltyProgram = (event: MouseEvent) => {
    const data = {
      amount: rewardProgram.amount,
      reward_card: rewardProgram.rewardCard,
      status: rewardProgram.status
    }
    createRewardProgramMutation.mutate(data);
  }

  const handleUpdateLoyaltyProgram = async () => {
    const data = {
      amount: rewardProgram.amount,
      reward_card: rewardProgram.rewardCard,
      status: rewardProgram.status,
    }
    if (hasAmountChange()) {
      const [status, forceSendLoyaltyReward] = await getAmountUpdateConfirmation();
      data.force_send_loyalty_reward = forceSendLoyaltyReward;
      if (!status) {
        return;
      }
    }
    updateRewardProgramMutation.mutate(data);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const amount = value.length > 0 ? Number(value) : 0;

    let error: any = {};

    if (amount && amount <= 0) {
      error["amountError"] = "*This field may not be 0 or less";
    } else if (amount?.toString().length == 0) {
      error["amountError"] = "*This field may not be blank";
    } else {
      error["amountError"] = "";
    }

    setRewardProgram({...rewardProgram, amount: amount});
    setUiState({...uiState, fieldErrors: {...uiState.fieldErrors, ...error}});
  }

  const handleRewardCardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const rewardCard = event.target.value;

    let error: any = {};
    if (!rewardCard || rewardCard.length === 0) {
      error["rewardCardError"] = "*This field may not be blank";
    } else {
      error["rewardCardError"] = "";
    }

    setRewardProgram({...rewardProgram, rewardCard: rewardCard});
    setUiState({...uiState, fieldErrors: {...uiState.fieldErrors, ...error}});
  }

  const handleStatusChange = (status: number) => {
    const data = {
      amount: rewardProgram.amount,
      reward_card: rewardProgram.rewardCard,
      status: status,
    }
    updateRewardProgramMutation.mutate(data);
  }

  const handleDeleteLoyaltyButtonClick = async (event: MouseEvent) => {
    const status = await getConfirmation();
    return;
}

  return (
    <RewardProgramComp
      handleUpdateLoyaltyProgram={handleUpdateLoyaltyProgram}
      handleCreateLoyaltyProgram={handleCreateLoyaltyProgram}
      handleCloseDialogProgramSuccessCreated={handleCloseDialogProgramSuccessCreated}
      handleAmountChange={handleAmountChange}
      handleRewardCardChange={handleRewardCardChange}
      handleStatusChange={handleStatusChange}
      handleDeleteLoyaltyButtonClick={handleDeleteLoyaltyButtonClick}
      hasChanges={hasChanges}
      WarningDialogDeleteLoyaltyProgram={WarningDialogDeleteLoyaltyProgram}
      WarningDialogAmountUpdateLoyaltyProgram={WarningDialogAmountUpdateLoyaltyProgram}
      rewardProgram={rewardProgram}
      rewardCards={rewardCards}
      uiState={uiState}
      // {...state}
    />
  );
};

export default RewardProgram;
